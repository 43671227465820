import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../../_redux/LoaderSlice";
import { updateSnackbar } from "../../../_redux/SnackbarSlice";
import GetJobId from "../../../_utilities/GetJobId";
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import CustomerComponent from "../CustomerComponent";



const CustomerView = () => {
  const [showTable, setShowTable] = useState(false);
  let getJobCode = GetJobId();
  const initialSnackbar = { type: "", message: "" };

  const dispatch = useDispatch();
  const [getDetails, setGetDetails] = useState(initialDetails);

  const actionBtns = [
    {
      variant: "contained",
      label: "Edit",
      type: "back",
      style: {
        backgroundColor: "#ebc700",
        color: "black",
        borderRadius: "25px",
        width: "6rem",
        textTransform: "capitalize",
      },
      class: "buttonHover",
      navigateTo: `/admin/layout/customer/edit/${getJobCode}`,
    },
  ];
  
  const getUserId = JSON.parse(decrypt("GetUserInfo"))
  useEffect(() => {
    if (getUserId !== null) {
      getData(getUserId[0].id);
    }
  }, []);


  const onGetValidData = (data) => {
    console.log(data);
  };
  const getData = async (id) => {
    setShowTable(false);
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/${id}`);
    const jsonData = await res.json();
    if (jsonData.code === 200) {
      dispatch(updateLoader(false));
      const myData = jsonData.customer;
      let country = JSON.parse(myData[0].country);
      myData[0]["country"] = country.map(x => x.id.toString());
      myData[0]["client_id"] = myData[0].id;
      let lisofDomains = [];//myData.map(x => x.employeeportal_domains !== "null");
      myData.forEach(list => {
          if(list.employeeportal_domains && list.employeeportal_domains !== "null"){
            lisofDomains.push(list.employeeportal_domains)
          }})
      myData[0].employeeportal_domains = lisofDomains;
      setGetDetails(myData[0]);
      let portal = JSON.parse(myData[0].portal);
      myData[0]["portal"] = portal.map(x => x.id.toString());
      setGetDetails(myData[0]);
      setShowTable(true);
      dispatch(updateSnackbar({ type: "success", message: "" }));
    } else {
      setShowTable(true);
      dispatch(updateLoader(false));
      dispatch(updateSnackbar({ type: "error", message: "" }));
    }
  };

  useEffect(() => {
    console.log("getDetails", getDetails);
    console.log("showTable", showTable);
  }, [getDetails]);

  return (
    <React.Fragment>
      <div id="CustomerView">
        {getDetails.client_id && (
          <CustomerComponent
            componentType="view"
            input={getDetails}
            iniValue={getDetails}
            actions={actionBtns}
            onValidData={onGetValidData}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default CustomerView;

const initialDetails = {
  companyName: "",
  registerdAddress: "",
  website: "",
  contactDetails: "",
  contactcontactPersonName: "",
  country: [],
  onBoardingDate: "",
  portal:[],
  employeeportal_domains:""
};
