import { Typography } from "@mui/material";
import { CustomLinearProgressForCompanyAverage, CustomLinearProgressForHighestValue } from "../ApplicantsDashboard/widgets";

// WidgetCard Component
const InformationWidgetCard = ({ data }) => {
    if (!data) return <Typography variant="body1">Loading...</Typography>;

    return (
        <div className="dashboard__card-content">

            <div className="dashboard__card-row">
                <h1 className="dashboard__card-contribution-value">{data.currentValue}</h1>
                <img className="dashboard__card-contribution-img" src={data.img} alt="" />
            </div>
            <p className="dashboard__card-labels dashboard__card-my-contribution">{data.currentTitle}</p>
            <div className="progressbarSection">
                <p className="dashboard__card-labels">{data.avgTitle}</p>
                <section className="dashboard__card-row">
                    <div className="dashboard__card-progress-bar">
                        <CustomLinearProgressForCompanyAverage id={`avg-value-${data.type}`} variant="determinate" value={data.avgPercentage} />
                    </div>
                    <h3>{data.avgValue}</h3>
                </section>
            </div>
            <div className="progressbarSection">
                <p className="dashboard__card-labels">{data.highestTitle}</p>
                <section className="dashboard__card-row">
                    <div className="dashboard__card-progress-bar">
                        <CustomLinearProgressForHighestValue id={`highest-value-${data.type}`} variant="determinate" value={data.highestPercentage} />
                    </div>
                    <h3>{data.highestValue}</h3>
                </section>
            </div>

        </div>
    );
};

export default InformationWidgetCard;