import React from 'react';
import { useDispatch } from 'react-redux';
// pages
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import * as _ser from '../../../_redux/_services/ApplicantsSlice';
import { setBreadCrumb } from '../../../_utilities/breadCrumb';
import './ApplicantsTable.css';

const initalState = { data: null, message: '', isloader: true }

const DuplicateApplicantsManualUploadPopup = ({ skipId, skipName, setTwoDuplicateApplicatsConfirmPopup }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onDuplicateUploadHandler = () => {
        // setBreadCrumb(skipId, skipName, "Applicant Code");
        setTwoDuplicateApplicatsConfirmPopup(false);
        navigate(`/ats/layout/applicants/view/${skipId}`);
        dispatch(_ser.uploadResumeStartReducer(initalState))
    }

    return (
        <section className="twoduplicatecustomModal">
            <div className="twoduplicatecustomModalDiv">
                <h1 className="modal-header">Duplicate Profile
                    <span onClick={() => { setTwoDuplicateApplicatsConfirmPopup(false)}}>
                        <CloseIcon style={{ color: "#ff0000" , cursor:"pointer" }} />
                    </span>
                </h1>
                <div className="twoduplicatemodal-body">
                    <h4>Identical profile exists in the system.</h4>
                   
                    <div className="modalActionBtns">
                        <Button type="button" className="viewBtn" style={{ width: "170px" }} 
                        onClick={onDuplicateUploadHandler}
                        >
                            View profile
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DuplicateApplicantsManualUploadPopup