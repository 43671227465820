import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import GetJobId from '../../../_utilities/GetJobId';
import PostingsComponent from "../PostingsComponent";

import * as _props from '../../../_redux/_services/JobPostingSlice';
import { Encrypt } from '../../../_utilities/_encryptDecryptHelper';
import { setBreadCrumb } from '../../../_utilities/breadCrumb';
import { useLocation } from 'react-router-dom';

const initalState = { data: null, message: '', isloader: true }

const PostingsView = (props) => {
  const [ viewJob, setViewJob ] = useState(null);
  const getDetails = useSelector((state) => state.Jobpostingsbyid.data);
  
  const getJobCode = GetJobId();
  const dispatch = useDispatch();
  const location = useLocation();
  
  useEffect(() => {
    if(getJobCode){
      dispatch(_props.jobpostingsbyidAsync(getJobCode));
    }
  }, [getJobCode]);

  useEffect(() => {
    if (props.getData) {
      // setViewJob(props.getData);
      dispatch(_props.jobpostingsbyidAsync(props.getData.jobopeningID));
    }
  }, [props.getData])

  useEffect(() => {
    if (location.state?.object) {
      let jobData = location.state.object;
      dispatch(_props.jobpostingsbyidAsync(jobData.jobopeningID));
    }
  }, [location])
  
  useEffect(() => {
    if (getDetails !== null && getDetails !== undefined) {
      let postingData = JSON.parse(JSON.stringify(getDetails));
      postingData.jobType = postingData.jobType.split(",");
      postingData.recruiters = postingData.recruiters ? JSON.parse(postingData.recruiters) : [];
      postingData.clientName = { id: postingData.clientId, clientName: postingData.clientName }
      setViewJob(postingData);
      let updateSkills = postingData.primarySkills.replace(/\n/g,',');
      Encrypt(updateSkills, "getSkills");
      dispatch(_props.jobpostingsbyidStartReducer(initalState));
      localStorage.setItem("JobIdentifier", postingData.affindaIdentifier);
      let name =`${postingData.jobTitle} (${postingData.jobCode})`
      setBreadCrumb(postingData.id, name, "Job Code")
    }
  }, [getDetails]);


  const onGetValidData = (data) => {
    console.log(data);
  }

  const actionBtns = [{
    variant: "contained", label: "Edit", type: "back",
    style: { backgroundColor: "#ebc700 ", color: "black",height:"40px !important", borderRadius: "10px", width: "6rem",fontSize:"14px", textTransform: "capitalize" },
    class: 'buttonHover1',
    navigateTo: `/ats/layout/jobpostings/edit/${getJobCode}`
  }]

  return (
    <div style={{ padding: "0 10px" }}>
      {viewJob && viewJob.length !== 0 ? (
        <PostingsComponent componentType="view" input={viewJob} iniValue={viewJob} //initialDetails
          actions={actionBtns} onValidData={onGetValidData} getJobDetails={false} />
      ): <div className="nodatasection">
      <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noData" />
      {"No data found"}
      </div>
      }
    </div>
  )
}

export default React.memo(PostingsView);

const initialDetails = {
  jobCode: '',
  workModel: '',
  jobStatus: '',
  jobTitle: '',
  externalSystemID:'',
  jobType: '',
  clientId: '',
  clientName: {},
  clientBillRate: '',
  payBillRate: '',
  location: '',
  teamLead: '',
  manager: '',
  recruiters: [],
  // recruitersemails: [],
  priority: '',
  primarySkills: '',
  secondarySkills: '',
  jobDesc: '',
  jobDescForSM: '',
}