import React, { useState, useEffect } from "react";
import { InputLabel, MenuItem, FormControl, Select, TextField, Button, FormHelperText } from "@mui/material";
import "./clientscreate.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,Link } from "react-router-dom";
import { FocusError } from "focus-formik-error";
import * as Yup from "yup";
import * as _ser from "../../../_redux/_services/ClientsSlice";

const initalState = { data: null, message: '', isloader: true };

const initialDetails = {
  clientName: "",
  endClient: "",
  managerEmail: "",
  region: "",
  status: "1",
  client_id: ""
};

const initClients = JSON.parse(JSON.stringify(initialDetails));

const ClientsCreate = ({ componentType = "Create", actions, onValidData, input, iniValue, disableBtn }) => {
  const dispatch = useDispatch();
  const [clients, setClients] = useState(JSON.parse(JSON.stringify(initClients)));
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(disableBtn);

  const navigate = useNavigate();

  useEffect(() => {
    if (input !== undefined && input !== null) {
      formik.values.clientName = input.clientName;
      formik.values.endClient = input.endClient;
      formik.values.managerEmail = input.managerEmail;
      formik.values.region = input.region;
      formik.values.status = input.status;
      setClients(JSON.parse(JSON.stringify(input)));
    }
  }, [input]);

  useEffect(() => {
    console.log("clients", clients);
  }, [clients]);

  useEffect(() => {
    if (componentType === "Create") {
      formik.values.status = "1";
      setClients(JSON.parse(JSON.stringify(initClients)))
    }
  }, []);

  const validationSchema = Yup.object({
    clientName: Yup.string()
      .min(1, "Must be 1 characters")
      .max(50, "Must be less than 50 ")
      .required("Please enter a client's name"),
      // endClient: Yup.string()
      // .min(3, "Must be 3 characters")
      // .max(20, "Must be less than 20 ")
      // .required("Enter End Client Name"),
    managerEmail: Yup.string().email("Please enter a valid email address")
      .matches(/^[a-zA-Z0-9_!#$%&*+/=?`{}~^.-]+@[a-zA-Z0-9.-]+$/, "Invalid email address format")
      .required("Please enter a manager's email address"),
    region: Yup.string().required("Please select the region"),
    status: Yup.string().required("Please select a status from the dropdown"),
  });

  const formik = useFormik({
    initialValues: JSON.parse(JSON.stringify(initClients)),
    validationSchema,
    onSubmit: async (values) => {
    setDisableSubmitBtn(true)

      if (componentType === "Create") {
        await CreateClients();
      } else if (componentType === "Edit") {
        await EditClients();
      }
    },
  });
  
  const code = useSelector(state => state.AddClients.data)

  const CreateClients = async () => {
    let clientsObj = JSON.parse(JSON.stringify(clients));
    // clientsObj["ATSPortal"] =  1;
    // clientsObj["VSPortal"] =  0;
    // clientsObj["CSPortal"] =  0;
    // clientsObj["IPortal"] =  0;
    dispatch(_ser.addClientsAsync(clientsObj)).then(() =>{
      setDisableSubmitBtn(false)
    }).catch(() => {
      setDisableSubmitBtn(false)
    });
  }
  
  useEffect(()=>{
    if (code === 200 ) {
      dispatch(_ser.addClientsStartReducer(initalState));
      navigate("/ats/layout/clients/landing");
    } 
  }, [code])

  const EditClients = async () => {
    onValidData(clients);
  }

  const onChangeHandler = (value, type) => {
    let obj = JSON.parse(JSON.stringify(clients));
    if(type === "managerEmail"){
      value = value.trim()
    }
    value = value.trimStart();
    obj[type] = value;
    formik.values[type] = value;
    
    setClients(obj);
  };

  return (
    <>
      {componentType !== "Create" ? (
        <div className="actionBtnRtl">
          {actions &&
            actions.map((item) => {
              if (item.type === "back") {
                return (
                  <Button
                    variant={item.variant}
                    className={item.class}
                    style={item.style}
                    size='small'
                    onClick={() => navigate(item.navigateTo)}
                  >
                    {componentType !== "view" ? <Link href="#" style={{color:"red", fontSize:"14px"}} underline="always">{item.label}{" "}</Link> : <>{item.label}{" "}</>}
                  </Button>
                );
              } else if (item.type === "submit") {
                return (
                  <Button
                    variant={item.variant}
                    className={item.class}
                    style={item.style}
                    size='small'
                    disabled={disableSubmitBtn }
                    onClick={formik.handleSubmit}
                  >
                    {item.label}{" "}
                  </Button>
                );
              }
            })}
        </div>
      ) : (
        ""
      )}
      <FocusError formik={formik} />
      <div style={{ height: "100%" }} className={componentType === "view" ? "viewSection" : ""}>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            width: "100%",
            marginBottom: "40px",
          }}
        >
          <img src={process.env.PUBLIC_URL + "/assests/img/applicant.svg"} alt="" />
          <div
            style={{ display: "flex", marginLeft: "10px", marginTop: "10px" }}
          >
            <h4> Add Client</h4>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexFlow: "wrap",
            marginTop: "60px",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "32%" }}>
            <TextField
              className="boldlabel"
              error={formik.errors.clientName && formik.touched.clientName ? true : false}
              helperText={formik.errors.clientName && formik.touched.clientName ? formik.errors.clientName : ""}
              autoFocus={formik.errors.clientName !== "" ? true : false}
              id="outlined-basic"
              variant="outlined"
              autoComplete="off"
              label="Client's Name*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
              style={{ width: "100%" }}
              name="Client Name*"
              value={clients.clientName ? clients.clientName : ''}
              InputLabelProps={{
                shrink :  true, 
              }}
              onChange={(e) => onChangeHandler(e.target.value, "clientName")}
              onKeyUp={formik.handleBlur}
              disabled={componentType !== "view" ? false : true}
            />
          </div>
          {/* <div style={{ width: "32%" }}>
            <TextField
              className="boldlabel"
              id="outlined-basic"
              variant="outlined"
              autoComplete="off"
              label="End client's name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
              style={{ width: "100%" }}
              name="Client Name*"
              value={clients.endClient ? clients.endClient : ''}
              onChange={(e) => onChangeHandler(e.target.value, "endClient")}
              onKeyUp={formik.handleBlur}
              disabled={componentType !== "view" ? false : true}
            />
          </div> */}

          <div style={{ width: "32%" }}>
            <TextField
              className="boldlabel"
              error={formik.errors.managerEmail && formik.touched.managerEmail ? true : false}
              helperText={formik.errors.managerEmail && formik.touched.managerEmail ? formik.errors.managerEmail : ""}
              id="outlined-basic"
              variant="outlined"
              autoComplete="off"
              autoFocus={formik.errors.managerEmail && formik.touched.managerEmail ? true : false}
              label="Manager's Email ID*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
              style={{ width: "100%" }}
              name="manager email id*"
              value={clients.managerEmail ? clients.managerEmail : ''}
              InputLabelProps={{
                shrink :  true, 
              }}
              onChange={(e) => onChangeHandler(e.target.value, "managerEmail")}
              onKeyUp={formik.handleBlur}
              disabled={componentType !== "view" ? false : true}
            />
          </div>
          <div style={{ width: "32%" }}>
            <FormControl
              fullWidth
              error={formik.errors.region && formik.touched.region ? true : false}
            >
              <InputLabel className={
                formik.errors.region && formik.touched.region
                  ? "text-danger boldSelectlabel"
                  : "boldSelectlabel"
              } 
              shrink ={true}
              >Region*&nbsp;
              </InputLabel>
              <Select
                value={clients.region ? clients.region : ''}
                name="region"
                disabled={componentType === "view" ? true : false}
                label="Region*&nbsp;&nbsp;"
                onChange={(e) => onChangeHandler(e.target.value, "region")}
                onFocus={formik.handleBlur}
                autoComplete="off"
                style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
              >
                <MenuItem value="Canada">Canada</MenuItem>
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="India">India</MenuItem>
                <MenuItem value="Malaysia">Malaysia</MenuItem>
              </Select>
              <FormHelperText>
                {formik.errors.region && formik.touched.region ? formik.errors.region : ""}
              </FormHelperText>
            </FormControl>
          </div>

         
        </div>

        <div style={{ marginTop: "44px", display: "flex",
            flexFlow: "wrap", justifyContent: "space-between"}}>

              
              <div style={{ width: "32%" }}>
              <FormControl fullWidth
            error={formik.errors.status && formik.touched.status ? true : false}>
            <InputLabel
              // id="status"
              className=
              {
                formik.errors.status && formik.touched.status
                  ? "text-danger boldSelectlabel"
                  : 
                  "boldSelectlabel"
              }
              shrink ={true}
            >
              Status*
            </InputLabel>
            <Select
              value={clients.status}
              name="status"
              disabled={componentType !== "view" ? false : true}
              label="Status*&nbsp;&nbsp;"
              autoComplete="off"
              onChange={(e) => onChangeHandler(e.target.value, "status")}
              onFocus={formik.handleBlur}
              style={{ width: "100%", borderRadius: "4px" }}
            >
              <MenuItem value="1">Active</MenuItem>
              <MenuItem value="0">Inactive</MenuItem>
            </Select>
            <FormHelperText>
                {formik.errors.status && formik.touched.status ? formik.errors.status : ""}
              </FormHelperText>
          </FormControl>
              </div>

              <div style={{ width: "32%" }}></div>
              <div style={{ width: "32%" }}></div>


        
        </div>

        {componentType === "Create" ? (
          <div id="clientsbuttons">
            <Button
              variant="text"
              className="clientcancelbtn"
              onClick={() => navigate("/ats/layout/clients/landing")}
            >
               <Link href="#" style={{color:"red", fontSize:"14px"}} underline="always">Cancel</Link>
            </Button>
            <Button
              variant="contained"
              className="clientssubmitbtn"
              type="submit"
              size="medium"
              disabled={disableSubmitBtn }
              onClick={formik.handleSubmit}
            >
              Submit
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ClientsCreate;