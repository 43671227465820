import React, { useEffect } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom";
import './RightSideBar.css'


const RightSideBar = (props) => {
  const { componentLayout } = props

  useEffect(() => {
    if (props.onReceivechildProps !== undefined) {
      console.log(props.onReceivechildProps);
    }
  }, [props.onReceivechildProps])

  return (
    <>
      <section className="sidebarBg">
        <div className='sidebar'>
          <div className='headertext'>
            <p className={componentLayout.pageName === "vendorportal" ? "heading1" : ""}>{componentLayout.title}</p>
            {componentLayout.navigateTo ? ( 
            <Link to={ componentLayout.navigateTo } target='_blank' type="button">
              <OpenInNewIcon /> 
            </Link>
            ) : (" ")}
            <img src={process.env.PUBLIC_URL + "/assests/img/close.svg" }alt="" className='closeicon' onClick={() => props.onReceiveProps(false, "close")} />
          </div>

          <div className="contentSection pannelContent">
            {componentLayout.description.length > 0 && <h6>{componentLayout.description}</h6>}
            <article>
              {props.componentData}
            </article>
          </div>

          <footer>
            <div className={componentLayout.pageName === "vendorportal" ? "bottomBox" : ""} />
            <img src={componentLayout.bgImage} alt={componentLayout.bgImage} />
          </footer>
        </div>
      </section>
    </>
  )
}

export default RightSideBar;