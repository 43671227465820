import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { updateLoader } from '../../../_redux/LoaderSlice';
import { updateSnackbar } from '../../../_redux/SnackbarSlice';

import MessageBar from '../../../components/SnackBar/MessageBar'
import Loader from '../../../components/Loader/Loader'
import './Register.css'
import { signUpUserWithEmail } from '../../../_redux/CognitoSlice';

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const showLoader = useSelector((state) => state.Loader.value);
  const showMessage = useSelector((state) => state.Snackbar.message);
  const showMsgType = useSelector((state) => state.Snackbar.type);

  const [userDetails, setUserDetails] = useState({
    username: "",
    role: 'user',
    password: "",
    confirmPassword: "",
    type: "registration"
  })
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setUserDetails((userDetails) => ({ ...userDetails, [e.target.name]: e.target.value }));
  }

  return (
    <div className='registerBackground'>
      {showMessage !== "" ? <MessageBar message={showMessage} type={showMsgType} /> : ''}
      {showLoader ? <Loader /> : ''}
      <div className=''>
        <h1>LOGO</h1>
        <p>The word 'lorem' for example, isn't a real Latin word,</p>
      </div>
      <div className='registration-container'>
        <div className='topLinks'>
          <Link to="/ats/login" className='d-flex align-items-center'>
            <img src={process.env.PUBLIC_URL + "/assests/signinicon.svg"} alt="signin" className='m-2' />
            <p>Sign In</p>
          </Link>
          <Link to="/ats/register" className='d-flex align-items-center'>
            <img src={process.env.PUBLIC_URL + "/assests/registericon.svg"} alt="register" className='m-2' />
            <p className='activeTab'>Register</p>
          </Link>
        </div>
        <div className='text-center m-2'>
          <img src={process.env.PUBLIC_URL + "/assests/usericon1.svg"} alt="" className='usericon1' />
          <h1>ATS</h1>
          <h3>Coming Soon..</h3>
          <h5>please Contact support@hirewing.com</h5>
        </div>
      </div>
    </div>

  )
}

export default Register

