import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import { React, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { updateLoader } from '../../../_redux/LoaderSlice';
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import GetJobId from "../../../_utilities/GetJobId";
import { Mode } from '../../../_utilities/constants';
import CkEditor from '../../../components/CkEditor/CkEditor';
import RightSideBar from '../../../components/Rightsidebar/RightSideBar';
import { Encrypt, decrypt } from "../../../_utilities/_encryptDecryptHelper";
import "./mail.css";
import { Link } from 'react-router-dom';

const initialSnackbar = { type: "", message: "" };

const Mail = () => {
    const [isRightSidePannel, setIsRightSidePannel] = useState(false);
    const [mode, setMode] = useState(Mode.CREATE)
    const [dataObject, setDataObject] = useState(JSON.parse(JSON.stringify(template)));
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [listofTemplate, setListofTemplate] = useState(null);
    const [customerId, setCustomerId] = useState(null);

    const dispatch = useDispatch();
    let getJobCode = GetJobId();

    const onReceivePropsHandler = (pannel) => {
        setIsRightSidePannel(pannel);
        localStorage.removeItem("emailBody");
        setTimeout(() => { getEmailTemplates(customerId) }, 1000);
    };

    const handleClose = () => {
        setOpen(false);
        setDeleteId(null);
    };

    const handleActionClick = (obj, type) => {
        if (type === "delete") {
            setOpen(true);
            setDeleteId(obj.id);
        } else if (type === "confirmDel" && deleteId !== null) {
            setOpen(false);
            deleteEmailTemplates(deleteId);
        }
    };

    const deleteEmailTemplates = async (deleteId) => {
        dispatch(updateLoader(true));
        dispatch(updateSnackbar(initialSnackbar));
        const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/emailtemplates/${deleteId}`, {  //${customerId}
            method: "DELETE",
            headers: { "customerid": `${customerId}`, 'Content-Type': 'Application/json' },
        });
        const jsonData = await res.json();
        if (jsonData.code === 200) {
            dispatch(updateSnackbar({ type: "success", message: jsonData.message }));
            setTimeout(() => { getEmailTemplates(customerId) }, 1000);
        }
        dispatch(updateLoader(false));
    };

    const layoutPannel = (type) => {
        return {
            title: (type === Mode.CREATE) ? "Create Template" : (type === Mode.EDIT) ? "Edit Template" : "View Template",
            description: "",
            bgImage: `${process.env.PUBLIC_URL}/assests/emal_bg_image.svg`,
        }
    };

    const onHandleComponent = (type, data) => {
        setIsRightSidePannel(true);
        setMode(type);
        setDataObject((type === Mode.CREATE) ? JSON.parse(JSON.stringify(template)) : data);
    }
    const getUserId = JSON.parse(decrypt("GetUserInfo"));

    useEffect(() => {
        if (getUserId !== null) {
            setCustomerId(getUserId[0].id);
            getEmailTemplates(getUserId[0].id);
        }
    }, []);

    const getEmailTemplates = async (getUserId) => {
        dispatch(updateLoader(true));
        dispatch(updateSnackbar(initialSnackbar));
        const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/${getUserId}/emailtemplates`, {
            method: "GET",
            headers: { "customerid": `${getUserId}`, 'Content-Type': 'Application/json' }
        });
        const jsonData = await res.json();
        let myData = jsonData.emailtemplates;
        if (jsonData.code === 200) {
            // dispatch(updateSnackbar({ type: "success", message: jsonData.message }));
            if (myData !== undefined && myData.length > 0) {
                myData.forEach(list => {
                    list["selectField"] = '';
                    list["copyMergeFieldValue"] = '';
                })
                setListofTemplate(myData);
            } else {
                setListofTemplate(null);
            }
        }
        dispatch(updateLoader(false));
    };

    return (
        <>
            <div
                className='addvendorsection'
                style={{ display: "flex", height: "34px", gap: "10px" }}
                onClick={(e) => onHandleComponent(Mode.CREATE, null)}
            >
                <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" />
                <h4 className="addnewvendortext">Create Email Template</h4>
            </div>
            <Grid container style={{ marginBottom: "20px" }} spacing={3}>
                {listofTemplate && listofTemplate.map(list => (
                    <Grid item xs={3}>
                        <div className="thumbnailSection">
                            <ul className="templateActions">
                                <li onClick={(e) => onHandleComponent(Mode.VIEW, list)}><img src={process.env.PUBLIC_URL + "/assests/email_view_icon.svg"} alt="" /></li>
                                <li onClick={(e) => onHandleComponent(Mode.EDIT, list)}><img src={process.env.PUBLIC_URL + "/assests/email_edit_icon.svg"} alt="" /></li>
                                <li onClick={(e) => handleActionClick(list, "delete")}><img src={process.env.PUBLIC_URL + "/assests/email_delete_icon.svg"} alt="" /></li>
                            </ul>
                            <div className="templateThumbnail">
                                <div className="templateContent" dangerouslySetInnerHTML={{ __html: list.emailBody }}></div>
                            </div>
                        </div>
                        <h5 className="text-center">{list.name}</h5>
                    </Grid>
                ))}
            </Grid>
            <div className="rightsidebarbtn mailSection">
                {isRightSidePannel ? (
                    <RightSideBar
                        componentData={
                            <ComponentRenderData
                                componentType={mode}
                                onReceivechildProps={onReceivePropsHandler}
                                data={dataObject}
                            />
                        }
                        componentLayout={layoutPannel(mode)}
                        onReceiveProps={onReceivePropsHandler}
                    />
                ) : (
                    ""
                )}
            </div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog"
                style={{ marginTop: "-26%" }}
            >
                <Box sx={{ width: "395px" }}>
                    <DialogTitle className="diabtn">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="diabtnjob" id="alert-dialog">
                            <p className="btntext">Are you sure you want to delete ?</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="alert-actionbtns">
                        <Button onClick={handleClose}><Link href="#" color="black" underline="always">Cancel</Link></Button>
                        <Button onClick={() => handleActionClick(null, "confirmDel")}>
                            Delete
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

const ComponentRenderData = ({ componentType, onReceivechildProps, data }) => {

    const [getTemplate, setGetTemplate] = useState(data);
    const [fieldsList, setFieldsList] = useState([]);
    const [defaultJobPostings, setDefaultJobPostings] = useState(JSON.parse(JSON.stringify(jobpostingsobj)));
    const [defaultApplicants, setDefaultApplicants] = useState(JSON.parse(JSON.stringify(applicantsobj)));
    const [defaultClients, setDefaultClients] = useState(JSON.parse(JSON.stringify(clientsobj)));
    const [customerId, setCustomerId] = useState(null);
    const [isEmailBody, setIsEmailBody] = useState(false);
    const [customValues, setCustomValues] = useState(null);
    const [customfieldText,setCustomfieldText] = useState("");

    const dispatch = useDispatch();
    let getJobCode = GetJobId();

    useEffect(() => {
        if (componentType !== Mode.CREATE) {
            formik.values.name = data.name;
            formik.values.subject = data.subject;
            setIsEmailBody((data.emailBody) ? false : true);
            setCustomValues(JSON.parse(data.customValues));
        }
    }, [componentType])

    const onChangeHandler = (value, type) => {
        let updateTemplate = JSON.parse(JSON.stringify(getTemplate));
        if (type === "category") {
            setFieldsList([])
            setCustomfieldText("")
            if (value === "Job Openings") {
                setFieldsList(defaultJobPostings)
            } else if(value === "Applicants") {
                setFieldsList(defaultApplicants)
            }else if(value === "Clients"){
                setFieldsList(defaultClients)
            }
            updateTemplate.copyMergeFieldValue = "";
        } else if (type === "selectField") {
            updateTemplate[type] = value;
            updateTemplate.copyMergeFieldValue = value;
        } else if (type === "emailBody") {
            value = value.data;
            setIsEmailBody(false)
            updateTemplate[type] = value;
            let previousData = JSON.parse(localStorage.getItem("emailBody"));
            if (previousData) {
                updateTemplate.name = previousData.name;
                updateTemplate.category = previousData.category;
                updateTemplate.subject = previousData.subject;
                updateTemplate.selectField = previousData.selectField;
                updateTemplate.copyMergeFieldValue = previousData.copyMergeFieldValue;
                updateTemplate.customValues = previousData.customValues;
            }
        }
        updateTemplate[type] = value;
        if (type === "name" || type === "subject") {
            formik.values[type] = value;
        }
        setGetTemplate(updateTemplate);
        if (type !== "emailBody") {
            localStorage.setItem("emailBody", JSON.stringify(updateTemplate));
        }
    }

    const onCustomChangeHandler=(value, type)=>{
    let customRejex = /^[a-zA-Z0-9_-]*$/
        if (customRejex.test(value)) {
        //     let finalValue =  value.trimStart()
            let updateTemplate = JSON.parse(JSON.stringify(getTemplate));
                updateTemplate.copyMergeFieldValue = `\@{Custom.${value}}@`;  
                updateTemplate.customValues = value;  
            // localStorage.setItem("emailBody", JSON.stringify(updateTemplate));
                setCustomfieldText(value);
                setGetTemplate(updateTemplate);
          }else{
            dispatch(updateSnackbar({ type: "error", message: "Please enter valid Alpha-Numberic character, special character accepted are _ and -" }));
          }
    }

    useEffect(() => {
        console.log("update getTemplate", getTemplate);
    }, [getTemplate])

    useEffect(() => {
        if (data?.category === "Job Openings") {
            setFieldsList(defaultJobPostings)
        } else if (data?.category === "Applicants") {
            setFieldsList(defaultApplicants)
        }else if(data?.category === "Clients"){
            setFieldsList(defaultClients)
        }
    }, [data,defaultJobPostings,defaultApplicants,defaultClients])
    
    const getUserId = JSON.parse(decrypt("GetUserInfo"));

    useEffect(() => {
        if (getUserId !== null) {
            setCustomerId(getUserId[0].id);
            fetchData(getUserId[0].id);
        }
    }, []);

    const fetchData = async (getUserId) => {
        dispatch(updateLoader(true));
        dispatch(updateSnackbar(initialSnackbar));
        const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/${getUserId}/customfields`, {
            method: "GET",
            headers: { "customerid": `${getUserId}`, 'Content-Type': 'Application/json' }
        });
        const jsonData = await res.json();
        const myData = jsonData.customfeilds;
        if (jsonData.code === 200) {
            if (myData !== undefined && myData.length > 0) {
                let customfielddata = myData;
                let posingsObj = JSON.parse(JSON.stringify(defaultJobPostings));
                let applicantsObj = JSON.parse(JSON.stringify(defaultApplicants));
                customfielddata.forEach((list, i) => {
                    if (parseInt(list.moduleId) === 1 && list.fieldType !== "default") {  
                        let newObj = { id: i, mappingKey: list.fieldName, copyMergeField: `\@{JobOpenings.${list.fieldName}}@`, fieldType: "custom" }
                        posingsObj.push(newObj);
                    } else if (parseInt(list.moduleId) === 2 && list.fieldType !== "default") {
                        let newObj = { id: i, mappingKey: list.fieldName, copyMergeField: `\@{Applicants.${list.fieldName}}@`, fieldType: "custom" }
                        applicantsObj.push(newObj);
                    }
                });
                setDefaultJobPostings(posingsObj);
                setDefaultApplicants(applicantsObj);
            }
        }
        dispatch(updateLoader(false));
    };

    const onCopyUrlHandler = () => {
        if (getTemplate.copyMergeFieldValue) {
            dispatch(updateSnackbar(initialSnackbar));
            var copyText = document.getElementById("copyMergeFieldValue");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
            dispatch(updateSnackbar({ type: "success", message: "Merge Field Value Copied!" }));
            if(getTemplate.category === "Custom"){
               
                if(customValues && customValues.length > 0){
                      let isSame = customValues.includes(customfieldText);
                      if(!isSame){
                          let newArray1 = [...customValues];
                              newArray1.push(customfieldText);
                              getTemplate.customValues = JSON.stringify(newArray1);
                              setCustomValues(newArray1)
                          } else {
                              getTemplate.customValues = JSON.stringify(customValues);
                          }
                } else {
                    let newArray = [];
                    newArray.push(customfieldText);
                    setCustomValues(newArray)
                    getTemplate.customValues = JSON.stringify(newArray);
                }
            } else if (customValues === null) {
                getTemplate.customValues = JSON.stringify([]);
            }
            localStorage.setItem("emailBody", JSON.stringify(getTemplate));
            console.log(getTemplate);
        } 
    }

    const handleReset = () => {
        onReceivechildProps(false);
        localStorage.removeItem("emailBody")
    };

    const handleSubmit = async (event, type) => {
        let modifydata = JSON.parse(JSON.stringify(getTemplate));
        if (type !== "Update" || type === "Update") {   // (type !== "Update" && fieldsList.length>0 && getTemplate.selectField !== "" && getTemplate.emailBody.includes(getTemplate.copyMergeFieldValue))
        if (type === "Save") {
            modifydata["id"] = Math.random().toFixed(2) * 100;
            saveEmailTemplates(modifydata);
        } else {
            updateEmailTemplates(modifydata);
        }
    }else{
        // dispatch(updateSnackbar({ type: "error", message:"All fields are required" }));
    }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Please enter template name"),
        subject: Yup.string().required("Please enter subject name"),
    });

    const formik = useFormik({
        initialValues: { name: '', subject: '',custom:'', emailBody: '' },
        validationSchema,
        validate: (values) => {
            if (getTemplate.emailBody && (getTemplate.emailBody !== " " && getTemplate.emailBody !== "")) {
                setIsEmailBody(false)
            } 
            // else if ( values.name !== "" && values.subject !== "" && (getTemplate.emailBody == " " )) {
            //     setIsEmailBody(true)
            // } 
            // else if ( values.name !== "" && values.subject !== "" && ( getTemplate.emailBody !== "")){
            //     setIsEmailBody(false)
            // } else if (  getTemplate.emailBody === "" && (formik.touched.name || formik.touched.subject) ){
            //     setIsEmailBody(false)
            // } else if ( (formik.touched.name == undefined && formik.touched.subject == undefined) ? getTemplate.emailBody === "" && values.name === "" && values.subject === "" : false){
            //     setIsEmailBody(true)
            // }
        },
        onSubmit: async (value) => {
            if ( value.name !== "" && value.subject !== "" && (getTemplate.emailBody == " " || getTemplate.emailBody == "")) {
                    setIsEmailBody(true)
                }
            if (isEmailBody=== false && (getTemplate.emailBody !== " " && getTemplate.emailBody !== "")) {
                let type = (componentType === Mode.CREATE) ? "Save" : "Update";
                handleSubmit(null, type);
                localStorage.removeItem("emailBody")
            }
        }
    })

    useEffect(()=>{
        if(formik.touched.name || formik.touched.subject ){
            if (getTemplate.emailBody && (getTemplate.emailBody !== " " && getTemplate.emailBody !== "")) {
                setIsEmailBody(false)
            } else if (  getTemplate.emailBody === "" && (!formik.touched.name && !formik.touched.subject) ){
                setIsEmailBody(false)
            } else if ( ( formik.touched.name && formik.touched.subject && formik.touched.emailBody) ? getTemplate.emailBody === "" && getTemplate.name === "" && getTemplate.subject === "" : false){
                setIsEmailBody(true)
            }
        } else if (formik.touched.name && formik.touched.subject && formik.touched.emailBody === undefined ) {
            if ( ( formik.touched.name && formik.touched.subject) ? getTemplate.emailBody === "" && getTemplate.name === "" && getTemplate.subject === "" : false){
                setIsEmailBody(false)
            }
        }
    },[getTemplate, formik.touched ])

    const saveEmailTemplates = async (data) => {
        dispatch(updateLoader(true));
        dispatch(updateSnackbar(initialSnackbar));
        let payload = data;
        payload["customerId"] = customerId;
        const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/${customerId}/emailtemplates`, {
            method: "POST",
            headers: { "customerid": `${customerId}`, 'Content-Type': 'Application/json' },
            body: JSON.stringify(payload)
        });
        const jsonData = await res.json();
        if (jsonData.code === 200) {
            dispatch(updateSnackbar({ type: "success", message: jsonData.message }));
            onReceivechildProps(false);
            localStorage.removeItem('emailBody');
        }
        dispatch(updateLoader(false));
    };

    const updateEmailTemplates = async (data) => {
        dispatch(updateLoader(true));
        dispatch(updateSnackbar(initialSnackbar));
        let payload = data;
        payload["customerId"] = customerId;
        const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/emailtemplates/${payload.id}`, {  //${customerId}
            method: "PUT",
            headers: { "customerid": `${customerId}`, 'Content-Type': 'Application/json' },
            body: JSON.stringify(payload),
        });
        const jsonData = await res.json();
        if (jsonData.code === 200) {
            dispatch(updateSnackbar({ type: "success", message: jsonData.message }));
            onReceivechildProps(false);
            localStorage.removeItem('emailBody');
        }
        dispatch(updateLoader(false));
    };

    return (
        <>
            <FocusError formik={formik} />
            <Grid container style={{ marginBottom: "20px" }} spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        className="mailboldlabel"
                        id="outlined-basic"
                        variant="outlined"
                        autoComplete="off"
                        label="Template Name "
                        style={{ width: "100%" }}
                        name="name"
                        value={getTemplate.name}
                        disabled={componentType === Mode.VIEW ? true : false}
                        onChange={(e) => onChangeHandler(e.target.value, "name")}
                        // onBlur={formik.handleBlur}
                        onKeyUp={formik.handleBlur}
                        InputLabelProps={{shrink :  true }}
                        error={
                            formik.errors.name && formik.touched.name ? true : false
                        }
                        helperText={
                            formik.errors.name && formik.touched.name ? formik.errors.name : ""
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <div className="">
                    <FormControl
              fullWidth
              autoComplete="off"
             
            >
                        <InputLabel id="category-select-label" shrink={true} className="mailboldSelectlabel">
                            Use Category</InputLabel>
                        <Select
                            value={getTemplate.category}
                            name="jobStatus"
                            labelId="category-select-label"
                            id="category-select"
                            disabled={componentType === Mode.VIEW ? true : false}
                            label="Use Category*&nbsp;&nbsp;"
                            autoComplete="off"
                            onChange={(e) => onChangeHandler(e.target.value, "category")}
                            style={{ height: "3.5rem", borderRadius: "4px",width: "100%",textAlign:"left" }}
                        >
                            {categoryList.map((list) => (
                                <MenuItem className="selectOptions" key={list.id} value={list.name}>
                                    {list.name}
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
            <Grid container style={{ marginBottom: "25px" }}>
                <TextField
                    className="mailboldlabel"
                    id="outlined-basic"
                    variant="outlined"
                    autoComplete="off"
                    label="Subject "
                    style={{ width: "100%" }}
                    name="subject"
                    value={getTemplate.subject}
                    disabled={componentType === Mode.VIEW ? true : false}
                    // onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    InputLabelProps={{shrink :  true }}
                    onChange={(e) => onChangeHandler(e.target.value, "subject")}
                    error={
                        formik.errors.subject && formik.touched.subject ? true : false
                    }
                    helperText={
                        formik.errors.subject && formik.touched.subject ? formik.errors.subject : ""
                    }
                />
            </Grid>
            <Grid container style={{ marginBottom: "20px" }} spacing={3}>
                <Grid item xs={6}>
                    {getTemplate.category === "Custom" ? 
                    <TextField id="outlined-basic" label="Custom" variant="outlined"
                    onChange={(e) => onCustomChangeHandler(e.target.value, "custom")}
                    InputLabelProps={{shrink :  true }}
                    value={customfieldText}
                    // value={getTemplate.custom}
                    // error={
                    //     formik.errors.custom && formik.touched.custom ? true : false
                    // }
                    // helperText={
                    //     formik.errors.custom && formik.touched.custom ? formik.errors.custom : ""
                    // }
                    onKeyPress={(e) => {
                        var str = e.target.value;
                        str = str.replace(/\s/g, "");
                        let updateTemplate = JSON.parse(JSON.stringify(getTemplate));
                        updateTemplate.copyMergeFieldValue = `\@{Custom.${str}}@`;  
                        updateTemplate.customValues = str;  
                        setCustomfieldText(str);
                        setGetTemplate(updateTemplate);
                    if(e.which === 32){
                      dispatch(updateSnackbar({ type: "error", message: "Spaces are not allowed" }))
                    }
                  }}
                    style={{ height: "3.5rem", borderRadius: "4px",width: "100%",textAlign:"left" }} /> :
                    <div className="">
                    <FormControl
                      fullWidth
                      autoComplete="off"
                      >
                        <InputLabel id="category-select-label" shrink={true} className="mailboldSelectlabel">
                            Select Field</InputLabel>
                        <Select
                            value={getTemplate.selectField}
                            name="selectField"
                            labelId="category-select-label"
                            id="category-select"
                            disabled={componentType === Mode.VIEW ? true : false}
                            label="Select Field*&nbsp;"
                            autoComplete="off"
                            onChange={(e) => onChangeHandler(e.target.value, "selectField")}
                            style={{ width: "100%", height: "3.5rem", borderRadius: "4px" ,textAlign:"left"}}
                        >
                            {fieldsList.length > 0 && fieldsList.map((list) => (
                                <MenuItem className="selectOptions" key={list.id} value={list.copyMergeField}>
                                    {list.mappingKey}
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </div>}
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <TextField
                            className="mailboldlabel"
                            id="copyMergeFieldValue"
                            variant="outlined"
                            autoComplete="off"
                            label="Copy Merge Field Value &nbsp;"
                            style={{ width: "90%" }}
                            name="copyMergeFieldValue"
                            value={getTemplate.copyMergeFieldValue}
                            disabled={true}
                        />
                        <ContentCopyIcon style={{ paddingLeft: "10px", paddingTop: "13px", cursor: "pointer", color: "#838789" }} onClick={onCopyUrlHandler} />
                    </div>
                </Grid>
            </Grid>
            <FormControl fullWidth error={isEmailBody === true ? true : false}>
                {/* <InputLabel
                    style={{ marginLeft: "-13px!important" }}
                    autoComplete="off"
                    className={isEmailBody === true ? "text-danger headerLabel" : "headerLabel"}
                >
                    Email Body*
                </InputLabel> */}
                <div
                    id="emailBody"
                    className={isEmailBody === true ? "brd-danger" : ""}>
                    <CkEditor
                        data={getTemplate.emailBody && getTemplate.emailBody === "" ? " " : getTemplate.emailBody}
                        error={formik.errors.emailBody && formik.touched.emailBody ? true : false}
                        name="emailBody"
                        autoComplete="off"
                        initRawData={getTemplate}
                        isReadonly={componentType === Mode.VIEW ? true : false}
                        onUpdatehandler={(update) =>
                            onChangeHandler(update, "emailBody", getTemplate)
                        }
                        onBlur={formik.handleBlur}
                    />
                </div>
                {isEmailBody === true ? (
                    <FormHelperText className="text-danger">
                        Please enter email body
                    </FormHelperText>
                ) : null}
            </FormControl>



            {componentType !== Mode.VIEW ?
                <div className="cancelsavebutton">
                    <Stack spacing={2} direction="row">
                        <Button
                            className="mailcancelbtn"
                             size="small"
                            variant="text"
                            sx={{ color: "#FF0000", textTransform: "capitalize" }}
                            type="reset"
                            onClick={handleReset}
                        >
                            <Link sx={{color: "red"}} underline="always">Cancel</Link>
                        </Button>
                        <Button
                            className="emailsavebtn"
                            size="small"
                            variant="contained"
                            type="submit"
                            // disabled={textValue.trim().length === 0 || disableSubmitBtn}
                            onClick={formik.handleSubmit}
                        //  onClick={(e) => handleSubmit(e, componentType === Mode.CREATE ? "Save" : "Update")}
                        >
                            {componentType === Mode.CREATE ? "Save" : "Update"}
                        </Button>
                    </Stack>
                </div> : null}
        </>
    );
};
export default memo(Mail);

const categoryList = [
    { id: 1, name: "Job Openings" },
    { id: 2, name: "Applicants" },
    { id: 3, name: "Clients" },
    { id: 4, name: "Custom" }
]

const template = {
    name: '', category: '',
    subject: '', selectField: '',
    copyMergeFieldValue: ' ', 
    emailBody: '',
    customValues:"[]",
}

const jobpostingsobj = [
    { id: 1, mappingKey: "Job Code", copyMergeField: "@{JobOpenings.jobCode}@", fieldType: "default" },
    { id: 2, mappingKey: "Job Title", copyMergeField: "@{JobOpenings.jobTitle}@", fieldType: "default" },
    { id: 3, mappingKey: "Job Status", copyMergeField: "@{JobOpenings.jobStatus}@", fieldType: "default" },
    { id: 4, mappingKey: "Job Type", copyMergeField: "@{JobOpenings.jobType}@", fieldType: "default" },
    { id: 5, mappingKey: "Location", copyMergeField: "@{JobOpenings.location}@", fieldType: "default" },
    { id: 6, mappingKey: "Pay rate/Salary", copyMergeField: "@{JobOpenings.payBillRate}@", fieldType: "default" },
    { id: 7, mappingKey: "Primary Skills", copyMergeField: "@{JobOpenings.primarySkills}@", fieldType: "default" },
    { id: 8, mappingKey: "Work Model", copyMergeField: "@{JobOpenings.workModel}@", fieldType: "default" },
    { id: 9, mappingKey: "Job Description For Social Media", copyMergeField: "@{JobOpenings.jobDescForSM}@", fieldType: "default" },
    { id: 10, mappingKey: "Job Description", copyMergeField: "@{JobOpenings.jobDesc}@", fieldType: "default" },
    { id: 11, mappingKey: "Secondary Skills", copyMergeField: "@{JobOpenings.secondarySkills}@", fieldType: "default" },
    { id: 12, mappingKey: "Priority", copyMergeField: "@{JobOpenings.priority}@", fieldType: "default" },
];

const applicantsobj = [
    { id: 1, mappingKey: "Applicant Code", copyMergeField: "@{Applicants.applicantCode}@", fieldType: "default" },
    { id: 2, mappingKey: "Contact Number", copyMergeField: "@{Applicants.contactNumber}@", fieldType: "default" },
    { id: 3, mappingKey: "Email Address", copyMergeField: "@{Applicants.emailId}@", fieldType: "default" },
    { id: 4, mappingKey: "FullName", copyMergeField: "@{Applicants.fullName}@", fieldType: "default" },
    { id: 5, mappingKey: "Certifications", copyMergeField: "@{Applicants.certification}@", fieldType: "default" },
    { id: 6, mappingKey: "Work Experience", copyMergeField: "@{Applicants.workExp}@", fieldType: "default" },
    // { id: 7, mappingKey: "Sourced From", copyMergeField: "@{Applicants.resumesource}@", fieldType: "default" },
        // { id: 8, mappingKey: "Education Details", copyMergeField: "@{Applicants.educationDetails}@", fieldType: "default" },
        // { id: 9, mappingKey: "Skills", copyMergeField: "@{Applicants.skillDetails}@", fieldType: "default" },
    // { id: 10, mappingKey: "candidateStatus", copyMergeField: "@Applicants.candidateStatus@", fieldType: "default" },
];

const clientsobj = [
    { id: 1, mappingKey: "Client's Name", copyMergeField: "@{Clients.clientName}@", fieldType: "default" },
    { id: 2, mappingKey: "Region", copyMergeField: "@{Clients.region}@", fieldType: "default" },
    { id: 3, mappingKey: "Status", copyMergeField: "@{Clients.status}@", fieldType: "default" },
    { id: 4, mappingKey: "Manager's Email ID", copyMergeField: "@{Clients.managerEmail}@", fieldType: "default" },
]