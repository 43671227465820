import { Button, ButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../../_redux/LoaderSlice";
import { updateSnackbar } from "../../../_redux/SnackbarSlice";
import GetJobId from "../../../_utilities/GetJobId";
import CustomDynamicTable from "../../../components/DynamicTable/CustomDynamicTable";
import { Encrypt, decrypt } from "../../../_utilities/_encryptDecryptHelper";
import './Custom.css'

const Custom = () => {
  const [customPostingsFeilds, setCustomPostingsFeilds] = useState([]);
  const [customApplicantsFeilds, setCustomApplicantsFeilds] = useState([]);
  const [moduleType, setModuleType] = useState("Job Postings");

  const dispatch = useDispatch();
  const initialSnackbar = { type: "", message: "" };

  let getJobCode = GetJobId();

  const getUserId = JSON.parse(decrypt("GetUserInfo"));

  useEffect(() => {
    if (getUserId !== null) {
      fetchData(getUserId[0].id);
    }
  }, []);

  const fetchData = async (id) => {
    setCustomPostingsFeilds([]);
    setCustomApplicantsFeilds([]);
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/${id}/customfields`);
    let Data = await res.json();
    const myData = Data.customfeilds;
    if (Data.code === 200) {
      if (myData !== undefined && myData.length > 0) {
        dispatch(updateLoader(false));
        // for jobpostings
        let postingRecords = myData.filter(x => parseInt(x.moduleId) === 1);
        let defaultPostingRecords = postingRecords.filter(x => x.fieldType === "default");
        let customPostingRecords = postingRecords.filter(x => x.fieldType === "custom");
        let hash = Object.create(null);
        const uniqueDefaultPostingRecords = defaultPostingRecords.reduce(function (r, o) {
          if (!(o.fieldName in hash)) {
            hash[o.fieldName] = r.push(o) - 1;
            return r;
          }
          if (o.id > r[hash[o.fieldName]].id) {
            r[hash[o.fieldName]] = o;
          }
          return r;
        }, []);

        let postingObj = JSON.parse(JSON.stringify(defaultPostingsData));
        uniqueDefaultPostingRecords.forEach(list => {
          let index = postingObj.findIndex(x => x.fieldName === list.fieldName);
          if (index !== -1) {
            postingObj[index].customValues = JSON.parse(list.customValues);
            postingObj[index].id = JSON.parse(list.id);
          }
        })
        customPostingRecords.forEach(e => e.customValues = JSON.parse(e.customValues));
        let finalPostingObj = [...customPostingRecords.reverse(), ...postingObj.reverse()];
        setCustomPostingsFeilds(finalPostingObj);

        // for applicants
        let applicantsRecords = myData.filter(x => parseInt(x.moduleId) === 2);
        let defaultApplicantsRecords = applicantsRecords.filter(x => x.fieldType === "default");
        let customApplicantsRecords = applicantsRecords.filter(x => x.fieldType === "custom");
        let hash1 = Object.create(null);
        const uniqueDefaultApplicantRecords = defaultApplicantsRecords.reduce(function (r, o) {
          if (!(o.fieldName in hash1)) {
            hash1[o.fieldName] = r.push(o) - 1;
            return r;
          }
          if (o.id > r[hash1[o.fieldName]].id) {
            r[hash1[o.fieldName]] = o;
          }
          return r;
        }, []);

        let applicantObj = JSON.parse(JSON.stringify(defaultApplicantsData));
        uniqueDefaultApplicantRecords.forEach(list => {
          let index = applicantObj.findIndex(x => x.fieldName === list.fieldName);
          if (index !== -1) {
            applicantObj[index].customValues = JSON.parse(list.customValues);
            applicantObj[index].id = JSON.parse(list.id);
          }
        })
        customApplicantsRecords.forEach(e => e.customValues = JSON.parse(e.customValues));
        let finalApplicantsObj = [...customApplicantsRecords.reverse(), ...applicantObj.reverse()];
        setCustomApplicantsFeilds(finalApplicantsObj);
      } else {
        dispatch(updateLoader(false));
        setCustomPostingsFeilds(JSON.parse(JSON.stringify(defaultPostingsData)));
        setCustomApplicantsFeilds(JSON.parse(JSON.stringify(defaultApplicantsData)));
      }
    } else {
      setCustomPostingsFeilds(JSON.parse(JSON.stringify(defaultPostingsData)));
      setCustomApplicantsFeilds(JSON.parse(JSON.stringify(defaultApplicantsData)));
      dispatch(updateLoader(false));
      dispatch(updateSnackbar({ type: "error", message: "" }));
    }
  };

  const deleteRecords = async (id) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const deleteJobPost = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/customfields/${id}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });
    const jsonData = await deleteJobPost.json();
    dispatch(updateSnackbar({ type: jsonData.code === 200 ? "success" : "error", message: jsonData.message }));
    setTimeout(() => {
      fetchData(getUserId[0].id);
    }, 2000);
  };

  const updateRecord = async (obj) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    obj["customerId"] = getUserId[0].id;
    const postData = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/customfields/${obj.id}`, {
      method: "PUT",
      headers: { 'Content-Type': 'Application/json' },
      body: JSON.stringify(obj),
    });
    let res = await postData.json();
    dispatch(updateSnackbar({ type: res.code === 200 ? "success" : "error", message: res.message }));
    setTimeout(() => {
      dispatch(updateSnackbar(initialSnackbar));
      dispatch(updateLoader(false));
      fetchData(getUserId[0].id);
    }, 2000)
  }

  const addRecord = async (obj) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    obj["customerId"] = getUserId[0].id;
    const postData = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/customfields`, {
      method: "POST",
      headers: { 'Content-Type': 'Application/json' },
      body: JSON.stringify(obj),
    });
    let res = await postData.json();
    dispatch(updateSnackbar({ type: res.code === 200 ? "success" : "error", message: "Custom field added successfully" })); //res.message
    setTimeout(() => {
      dispatch(updateSnackbar(initialSnackbar));
      dispatch(updateLoader(false));
      fetchData(getUserId[0].id);
    }, 2000)
  }

  const onTableData = (data, index) => {
    let Array = data;
    if (Array !== null) {
      if(Array[index].id === 0){ delete Array[index].id}
      if (Array[index].id === undefined) {
        addRecord(Array[index]);
      } else {
        updateRecord(Array[index]);
      }
      // write condition here!
      // setCustomPostingsFeilds(Array)
      // setCustomApplicantsFeilds(Array)
    } else {
      deleteRecords(index)
    }
  };

  const onChangeModule = (type) => {
    setModuleType(type);
    
  }

  return (
    <>
    <div  id= "customdynamictable" className="customgroupButtons">
      <ButtonGroup variant="contained">
        <Button className={moduleType === "Job Postings" ? "active" : ''} onClick={() => onChangeModule("Job Postings")}>Job Postings</Button>
        <Button className={moduleType === "Applicants" ? "active" : ''} onClick={() => onChangeModule("Applicants")}>Applicants</Button>
      </ButtonGroup>
    </div>
      <div id="dynamicTableSection" style={{ marginTop: "20px" }}>
        {moduleType === "Job Postings" && customPostingsFeilds.length > 0 ?
          <CustomDynamicTable moduleType="Job Postings" columns={customColoumn} data={customPostingsFeilds} receiveProps={onTableData} />
        : ''}

        {moduleType === "Applicants" && customApplicantsFeilds.length > 0 ?
          <CustomDynamicTable moduleType="Applicants" columns={customColoumn} data={customApplicantsFeilds} receiveProps={onTableData} />
        : ''}
      </div>
    </>
  );
};

export default Custom;

const customColoumn = [
  // { id: 1, displayName: "Module", name: "moduleId" },
  { id: 2, displayName: "Field Name", name: "fieldName" },
  { id: 3, displayName: "Data Type", name: "dataType" },
  { id: 4, displayName: "Values", name: "customValues" },
  { id: 5, displayName: "Action", name: "action" },
];

const defaultPostingsData = [
  {
    id: 1,
    moduleId: 1,
    fieldName: "Work model",
    fieldType: "default",
    dataType: "Single select",
    customValues: ["Hybrid", "On-site", "Remote"],
    action: "action",
  },
  {
    id: 2,
    moduleId: 1,
    fieldName: "Job status",
    fieldType: "default",
    dataType: "Single select",
    customValues: ["Closed", "Filled", "Active", "On-Hold"],
    action: "action",
  },
  {
    id: 3,
    moduleId: 1,
    fieldName: "Job type",
    fieldType: "default",
    dataType: "Single select",
    customValues: ["Full time", "Part time", "Intern", "Contract"],
    action: "action",
  },
  {
    id: 4,
    moduleId: 1,
    fieldName: "Priority",
    fieldType: "default",
    dataType: "Single select",
    customValues: ["High", "Medium", "Low"],
    action: "action",
  }
]

const defaultApplicantsData = [
  {
    id: 1,
    moduleId: 2,
    fieldName: "Source",
    fieldType: "default",
    dataType: "Single select",
    customValues: ["Cloudsourcing portal", "Employee portal", "Vendorsourcing portal","JobBoard portal", "LinkedIn", "Indeed", "Zoho Recruit", "Zip Recruit", "Monster", "Social Media", "Ceipal", "Reference", "Career Builder", "Others"],
    action: "action",
  },
  {
    id: 2,
    moduleId: 2,
    fieldName: "Candidate status",
    fieldType: "default",
    dataType: "Single select",
    customValues: ["Associated","Pending","Rejected","New","Assigned","Consent await","Submitted","Interview","Selected"],
    action: "action",
  }
]