import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSnackbar } from "../../../_redux/SnackbarSlice";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const CreateDocuments = ({ isDocData = false, onReceiveData }) => {
  const [imgList, setImgList] = useState([]);
  const [imgData, setImgData] = useState([]);
  const dispatch = useDispatch();
  const fileType = "jpg not supported";

  const onRemoveItem = (item) => {
    console.log("remaining files", item.id);
    let imgNames = JSON.parse(JSON.stringify(imgList));
    let findIndex = imgNames.findIndex((x) => x.id === item.id);
    if (findIndex !== -1) {
      imgNames.splice(findIndex, 1);
      setImgList(imgNames);
    }
    imgData.splice(parseInt(item.id), 1);
    setImgData(imgData);
  };

  const fileImgHandler = (event) => {
    let files = event.target.files;
    let imgNames = JSON.parse(JSON.stringify(imgList));
    let imgObj = imgData;
    var fileInput = document.getElementById("fileId");
    var filePath = fileInput.value;
    var allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;
    if (!allowedExtensions.exec(filePath)) {
      dispatch(
        updateSnackbar({ type: "error", message: "File not supported" })
      );
      fileInput.value = "";
      return false;
    } else {
      let tempImgNames = [],
        tempImgObj = [];
      let loopIndex = (imgList.length > 0) ? parseInt(imgList[imgList.length-1].id) : 0;
      for (var i = 0; i < files.length; i++) {
        let obj = { id: loopIndex + i, name: files[i].name };
        tempImgNames.push(obj);
        tempImgObj.push(files[i]);
      }
      imgNames.push(...tempImgNames);
      imgObj.push(...tempImgObj);
      let uniqueimgNames = imgNames.filter((value, index) => { return index === imgNames.findIndex(unique => value.name === unique.name)});
      let uniqueimgObj = imgObj.filter((value, index) => { return index === imgObj.findIndex(unique => value.name === unique.name)});
      setImgList(uniqueimgNames);
      setImgData(uniqueimgObj);
    }
    event.target.value = "";
  };

  useEffect(() => {
    if(isDocData === true){
      onReceiveData(imgData, false, "document");
    }
  }, [isDocData])

  return (
    <div className="docSection">
      <h4 className="subHeading">Please add documents relevant to the job posting</h4>
        <div>
            <Button
            variant='contained' size='medium' className='buttonHover1' component="label" disabled={imgList.length > 0 ? true : false}
            style={{ backgroundColor: "#ebc700", color: "#000", borderRadius: "10px", textTransform: "capitalize", marginBottom: "20px",width:"150px",fontSize:"16px" }}
            >
            {/* <span> */}
                {/* <b> */}
                  Browse File
                  {/* </b> */}
            {/* </span> */}
            <input
                hidden
                accept="file/*"
                type="file"
                id="fileId"
                // multiple
                onChange={(e) => fileImgHandler(e)}
            />
            </Button>
            <p className="docsupporttext">
            Supported file formats: Doc, Pdf, Docx{" "}
            </p>
        </div>
      {imgList.length > 0 ? (
        <ul className="listofUploads" style={{width: "580px"}}>
          {imgList &&
            imgList.map((list) => {
              return (
                <li key={list.id}>
                  <p>{list.name}</p>
                    {list.name.match(".jpg") ||
                    list.name.match(".png") ||
                    list.name.match(/jpg/) ||
                    list.name.match(/png/) ||
                    list.name.match(/jpeg/) ||
                    list.name.match(/JPEG/) ||
                    list.name.match(/SVG/)
                      ? fileType
                      : ""}
                  <span
                    className="closeImgbtn"
                    onClick={() => onRemoveItem(list)}
                  >
                    <DeleteOutlineIcon />
                  </span>
                </li>
              );
            })}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

export default CreateDocuments;
