import React, { useState, useEffect } from 'react'
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import './Clientstable.css';
import { useDispatch, useSelector } from 'react-redux';
import * as _ser from "../../../_redux/_services/ClientsSlice";
import { decrypt } from '../../../_utilities/_encryptDecryptHelper';

const initalState = { data: null, message: '', isloader: true }

const ClientsTable = () => {
  const [users, setUsers] = useState([])

  const dispatch = useDispatch();
  const [config, setConfig] = useState(configuration);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    let obj1 = { current: config.currentPage, offset: users.length + 1 };
    FetchData(obj1);
    let userInfo = JSON.parse(decrypt("GetUserInfo"));
    if(userInfo){
      let modifyConfig = JSON.parse(JSON.stringify(config));
      modifyConfig.isDropdownActions = userInfo[0].systemroles === "Super Admin" || userInfo[0].systemroles === "Manager" || userInfo[0].systemroles === "Admin" ? true : false
      setConfig(modifyConfig)
    } else {
      setConfig(configuration);
    }
  }, []);

  const clients = useSelector(state => state.GetClientswithPaging.data);

  const FetchData = async (obj) => {
    setUsers([]);
    dispatch(_ser.getClientswithPagingAsync(config.limitData, obj.offset));
    let configure = JSON.parse(JSON.stringify(config));
    configure.currentPage = obj.current;
    setConfig(configure);
  };

  // useEffect(() => {
  //   if (clients !== null && clients.length > 0) {
  //     let clientData = JSON.parse(JSON.stringify(clients));
  //     clientData.forEach(e => {
  //       e["isChecked"] = false;
  //       if(e.endClient !== null){
  //         e["clientEndClientName"] = e.clientName + " / " + e.endClient;
  //       } else {
  //         e["clientEndClientName"] = e.clientName;
  //       }
  //     });
  //     console.log(clientData);
  //     setUsers(clientData);
  //     let configure = JSON.parse(JSON.stringify(config));
  //     configure.totalRecords = clientData[0].total_count / config.limitData;
  //     setConfig(configure);
  //     dispatch(_ser.getClientswithPagingStartReducer(initalState))
  //   }
  // }, [clients])
  useEffect(() => {
    if (clients && clients.length > 0 ) {
      setUsers([]);
      // if (clients.length > 0) {
      let clientData = JSON.parse(JSON.stringify(clients));
      clientData.forEach(e => {
        e["isChecked"] = false;
        if(e.endClient !== null){
          e["clientEndClientName"] = e.clientName + " / " + e.endClient;
        } else {
          e["clientEndClientName"] = e.clientName;
        }
      });
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = clientData[0].total_count / config.limitData;
      setConfig(configure);
      dispatch(_ser.getClientswithPagingStartReducer(initalState))
      setUsers(clientData);
    }
    // }
    else if(clients ){  // !== null && clients !== undefined && clients.length === 0
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = 0;
      configure.currentPage = 1;
      setConfig(configure);
      setUsers('null');

    }
  }, [clients])

  // for dynamic component received props
  const onReceiveActionHandler = async (type, object) => {
    if (type === "confirmDel") {
      DeleteRecords(object);
    } else if (type === "download") {
      alert("download option is not implemented!");
    } else if (type === "pagination") {
      if (filterValue !== '') {
        getSearchResult(object, true);
      } else {
        FetchData(object);
      }
    } else if (type === "searchResult") {
      getSearchResult(object, true, type);
    } else if (type === "cancelSearchResult") {
      setFilterValue("");
      setUsers([]);
      let obj1 = { current: 1, offset: 1, limit: config.limitData };
      FetchData(obj1);
    } else if(type === "pageRange"){
      setConfig({...config,limitData:config.limitData=object})
      let obj1 = { current: config.currentPage, offset: config.currentPage !== 1 ? (config.currentPage - 1) * config.limitData + 1 : config.currentPage, limit: config.limitData };
      FetchData(obj1);
    }
  };

  let DelClientscode = useSelector(state => state.DeleteClients.data);

  const DeleteRecords = async (object) => {
    let ids;
    if (typeof object === "object" && object.length > 0) {
      ids = object.map((x) => x.id.toString());
    } else {
      ids = [object.toString()];
    }
    let obj = { ids: ids };
    dispatch(_ser.deleteClientsAsync(obj));
  };

  useEffect(() => {
    if (DelClientscode === 200) {
      let obj1 = {
        current: config.currentPage,
        offset: config.currentPage !== 1 ? (config.currentPage - 1) * 20 + 1 : config.currentPage,
      };
      dispatch(_ser.deleteClientsStartReducer(initalState))
      FetchData(obj1);
      setFilterValue('')
    }
  }, [DelClientscode])

  const getSearchResult = async (object, paging, type) => {
    // setUsers([]);
    if (paging === true) {
      SearchResultWithPaging(object, type);
    } else {
      setFilterValue(object.keyword);
      SearchResultWithoutPaging(object);
    }

  };

  const FilterClientsData = useSelector(state => state.FilterClients.data);

  const SearchResultWithoutPaging = async (object) => {
    dispatch(_ser.filterClientsAsync(object.keyword));
    let configure = JSON.parse(JSON.stringify(config));
    configure.currentPage = object.current;
    setConfig(configure);
  }

  useEffect(() => {
    if (FilterClientsData !== null && FilterClientsData.length > 0) {
      let myData = JSON.parse(JSON.stringify(FilterClientsData));
      myData.forEach(e => {
        e["isChecked"] = false;
        if(e.endClient !== null){
          e["clientEndClientName"] = e.clientName + " / " + e.endClient;
        } else {
          e["clientEndClientName"] = e.clientName;
        }
      });
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = myData[0].total_count / config.limitData;
      setConfig(configure);
      setUsers(myData.slice(0, config.limitData));
      dispatch(_ser.filterClientsStartReducer(initalState));
    }
    else if (FilterClientsData !== null && FilterClientsData.length === 0) {
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = 0;
      configure.currentPage = 1;
      setConfig(configure);
      setUsers('null');
    }
  }, [FilterClientsData])

  const FilterClientsWithPagingData = useSelector(state => state.FilterClientsWithPaging.data);

  const SearchResultWithPaging = async (object, type) => {
    let value = object.keyword? object.keyword : filterValue;
    setFilterValue(value);
    object['keyword'] = value;
    if(type === "searchResult"){
      object['offset'] = 1;
      object['limit'] = config.limitData;
    }
    dispatch(_ser.filterClientsWithPagingAsync(config.limitData, object.offset, value));
    let configure = JSON.parse(JSON.stringify(config));
    configure.currentPage = object.current;
    setConfig(configure);
  }

  useEffect(() => {
    if (FilterClientsWithPagingData !== null && FilterClientsWithPagingData.length > 0) {
      let myData = JSON.parse(JSON.stringify(FilterClientsWithPagingData));
      myData.forEach(e => {
        e["isChecked"] = false;
        if(e.endClient !== null){
          e["clientEndClientName"] = e.clientName + " / " + e.endClient;
        } else {
          e["clientEndClientName"] = e.clientName;
        }
      });
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = myData[0].total_count / config.limitData;
      setConfig(configure);
      setUsers(myData.slice(0, config.limitData));
      dispatch(_ser.filterClientsWithPagingStartReducer(initalState))
    }
    else if (FilterClientsWithPagingData !== null && FilterClientsWithPagingData.length === 0) {
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = 0;
      configure.currentPage = 1;
      setConfig(configure);
      setUsers('null');
    }
  }, [FilterClientsWithPagingData])

  useEffect(() => {
    console.log("users", users)
  }, [users])

  return (
    <>
      <div style={{ marginTop: "80px", marginBottom: "60px" }}>
        <div style={{ background: "#fff", padding: "22px 47px 47px" }}>
          {/* for dynamic Table */}
          {users && users.length > 0 && (
            <DynamicTable
              config={config}
              data={users}
              colmns={columnsData}
              actionDropdown={actionsDropdownData}
              filterValue={filterValue}
              addModel={addNewModel}
              searchKeyWord="Search by Client Name"
              noDataText="No Clients found"
              actions={actionsList}
              onReceiveActionProps={(type, obj) =>
                onReceiveActionHandler(type, obj)
              }
            />
          )}
          {users && users.length === 0 && (
            <DynamicTable
              config={config}
              data={users}
              colmns={columnsData}
              actionDropdown={actionsDropdownData}
              filterValue={filterValue}
              addModel={addNewModel}
              searchKeyWord="Search by Client Name"
              noDataText="No Clients found"
              actions={actionsList}
              onReceiveActionProps={(type, obj) =>
                onReceiveActionHandler(type, obj)
              }
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ClientsTable

/// for dynamic Table config Object
const configuration = {
  isSearchbar: true,
  isPaging: true,
  isDropdownActions: true,
  isCheckbox: true,
  isActions: true,
  recordperPage: 1,
  currentPage: 1,
  totalRecords: 0,
  limitData: 20,
  isCheckAlignRight: true,
  isPaginationTop: true,
  isSearchServer: true,
  isPagingServer: true,
  isPageRange:true,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["Delete"];

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isDelete: false,
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    // displayName: "Client's name/End client's name",
    // mappingName: "clientEndClientName",
    displayName: "Client Name",
    type:"clients",
    mappingName: "clientName",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center NameCapitalise",
    width: "350px",
    isEdit: true,
    nagigate: "/ats/layout/clients/view"
  },
  {
    id: 2,
    displayName: "Region",
    type:"clients",
    mappingName: "region",
    theadClass: "dynatableheadtext text-center ",
    tbodyClass: "dynatablebodytext  text-center",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/clients/view"
  },
  {
    id: 3,
    displayName: "Manager Email ID",
    type:"clients",
    mappingName: "managerEmail",
    theadClass: "dynatableheadtext text-center  ",
    tbodyClass: "dynatablebodytextemail text-center",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/clients/view"
  },
  {
    id: 4,
    displayName: "Status",
    type:"clients",
    mappingName: "status",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/clients/view"
  },
];

const addNewModel = {
  url: "/ats/layout/clients/create",
  displayName: " New Client"
}