import React, { useState, useEffect } from "react";

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateJobCode } from '../_redux/SeletedJobCodeSlice';

const GetJobId = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const JobId = useSelector((state) => state.SeletedJobCode.data);

  let url = location.pathname;
  const path = url.substring(url.lastIndexOf("/") + 1);
  if (path !== "landing" && path !== "create") {
    let params = JobId ? JobId : parseInt(url.substring(url.lastIndexOf('/') + 1));
    dispatch(updateJobCode(params));
    return params;
  }

};

export default GetJobId;