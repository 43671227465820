import { useRef } from 'react';
import SimpleReactValidator from "simple-react-validator";

export const ValidatesQuestions = () => {
  return useRef(
    new SimpleReactValidator({
      validators: {
        element: (message) => {
          <span className="error-message">{message}</span>
        },
      },
      messages: {
        required: 'Please enter a question',
      },
    }),
  );
};

export const ValidatesType = () => {
  return useRef(
    new SimpleReactValidator({
      validators: {
        element: (message) => {
          <span className="error-message">{message}</span>
        },
      },
      messages: {
        required: 'Please select a type',
      },
    }),
  );
};

export const ValidatesOption = () => {
  return useRef(
    new SimpleReactValidator({
      validators: {
        element: (message) => {
          <span className="error-message">{message}</span>
        },
      },
      messages: {
        required: 'Please enter an option',
      },
    }),
  );
};