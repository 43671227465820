import { CKEditor } from "ckeditor4-react";
import React, { memo } from "react";

const editorConfig = {
	toolbar: [
		{ name: 'basicstyles', items: ['CopyFormatting', 'RemoveFormat',] },
		{ name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'SelectAll', '-', 'Undo', 'Redo', '-'] },
		{ name: 'editing', items: ['Find', 'Replace', '-'] },
		{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-'] },
		{ name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
		{ name: 'insert', items: ['HorizontalRule', 'SpecialChar'] },
		{ name: 'document', items: ['Source'] },
		{ name: 'links', items: ['Link', 'Unlink', '-',] },
		{ name: 'insert', items: ['Table', '-',] },
		{ name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-'] },
		{ name: 'colors', items: ['TextColor', 'BGColor', '-'] },
		{ name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
	],
	fillEmptyBlocks: false,
	versionCheck: false
}

const CkEditor = (props) => {

	const onEditorChange = (evt) => {
		let updateData = evt.editor.getData();
		if (updateData) {
			let value = updateData.replace(/"/g, "'");
			let obj = { data: value, initData: props.initRawData }
			props.onUpdatehandler(obj);
		} else {
			let obj = { data: " ", initData: props.initRawData }
			props.onUpdatehandler(obj);
		}
	}

	return (
		<>
			{ props.data ?
				<CKEditor initData={props.data} readOnly={props.isReadonly} config={editorConfig} onChange={onEditorChange} />
			: <CKEditor initData={props.data} readOnly={props.isReadonly} config={editorConfig} onChange={onEditorChange} />
			}
		</>
	)
}

export default memo(CkEditor);