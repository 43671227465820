import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../../_redux/CognitoSlice';
import * as _ser from "../../../_redux/_services/ApplicantsSlice";
import GetJobId from '../../../_utilities/GetJobId';
import { Encrypt, decrypt } from "../../../_utilities/_encryptDecryptHelper";
import { setBreadCrumb } from '../../../_utilities/breadCrumb';
import ApplicantsComponent from '../ApplicantsComponent';

const initalState = { data: null, message: '', isloader: true }

const ApplicantsEdit = ({ }) => {
  const [getDetails, setGetDetails] = useState(initialDetails);
  const getJobCode = GetJobId();
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [overwrideObj, setOverwrideObj] = useState(null);

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(()=>{
console.log(overwrideObj);

  },[overwrideObj])

  const ApplicantData = useSelector(state => state.ApplicantsById.data);

  useEffect(() => {
    if (getJobCode !== null) {
      dispatch(_ser.applicantsByIdAsync(getJobCode));
    }
  }, [getJobCode, dispatch]);

  useEffect(() => {
    if (location.state?.applicant && location.state.isOverwriteEdit) {
      let newObj = location.state.applicant;
      let educationObj = newObj.educationDetails;
      if (educationObj && educationObj.length > 0) {
        educationObj.forEach((item, i) => {
          item["id"] = i;
          item["isEdit"] = false;
          item['startDate'] = item?.startDate && item.startDate ? new Date(item?.startDate).toLocaleDateString() : '';
          item['endDate'] = item?.endDate && item.endDate ? new Date(item?.endDate).toLocaleDateString() : '';
          if (item?.education) { item['degree'] = item.education; }
        })
      }
      let workExpObj = newObj.workExp;
      if (workExpObj && workExpObj.length > 0) {
        workExpObj.forEach((item, i) => {
          item["id"] = i;
          item["isEdit"] = false;
          item['locationFormated'] = item?.location?.formatted;
          item['startDate'] = item?.startDate && item.startDate ? new Date(item?.startDate).toLocaleDateString() : '';
          item['endDate'] = item?.endDate && item.endDate ? new Date(item?.endDate).toLocaleDateString() : '';
        })
      }
      newObj['educationDetails'] = educationObj;
      newObj['workExp'] = workExpObj;
      newObj['Applicants']['contactNumber'] = newObj.Applicants?.contactNumber ? newObj.Applicants?.contactNumber : newObj.Applicants?.phone ? newObj.Applicants.phone : '';
      newObj['Applicants']["resumesourceId"] = newObj.resumesource ? newObj.resumesource : newObj.sourceId ? newObj.sourceId : newObj.Applicants?.resumesourceId;
      newObj['Applicants']["candidateStatus"] = newObj.Applicants?.candidateStatus ? newObj.Applicants?.candidateStatus : "";
      newObj['applicantCode'] = newObj.applicantCode ? newObj.applicantCode : "";
      Encrypt(JSON.stringify(newObj), "OverwriteEditObj");
      setOverwrideObj(newObj);
    }
  }, [location])

  useEffect(() => {
    if (ApplicantData !== null && ApplicantData.length>0) {
      let initobj = JSON.parse(JSON.stringify(initialDetails));
      let myData = JSON.parse(JSON.stringify(ApplicantData));
      initobj.Applicants.firstName = myData[0].firstName;
      initobj.Applicants.lastName = myData[0].lastName;
      initobj.Applicants.contactNumber = myData[0].contactNumber;
      initobj.Applicants.AlternateContactNumber = myData[0].AlternateContactNumber;
      initobj.Applicants.emailId = myData[0].emailId;
      initobj.Applicants.resumesourceId = myData[0].resumesourceId;
      initobj.Applicants.candidateStatus = myData[0].candidateStatus;
      initobj.source = myData[0].source;
      initobj.applicantCode = myData[0].applicantCode;
      initobj.customFields = myData[0].customFields;
      // initobj.csAssociatedJobs = JSON.parse(myData[0].csAssociatedJobs);   /// Removed in Backend ////
      // initobj.eAssociatedJobs = JSON.parse(myData[0].eAssociatedJobs);     /// Removed in Backend ////
      // initobj.vsAssociatedJobs = JSON.parse(myData[0].vsAssociatedJobs);   /// Removed in Backend ////
      let educationObj = JSON.parse(myData[0].educationDetails);
      if (educationObj !== null && educationObj.length > 0) {
        educationObj.forEach((item, i) => {
          item["id"] = i;
          item["isEdit"] = false;
          item['startDate'] = item?.startDate && item.startDate ? new Date(item?.startDate).toLocaleDateString() : '';
          item['endDate'] = item?.endDate && item.endDate ? new Date(item?.endDate).toLocaleDateString() : '';
          if (item?.education) { item['degree'] = item.education; }
        })
      }
      initobj.educationDetails = educationObj && educationObj.length > 0 ? educationObj : [];
      let workExpObj = JSON.parse(myData[0].workExp);
      if (workExpObj !== null && workExpObj.length > 0) {
        workExpObj.forEach((item, i) => {
          item["id"] = i;
          item["isEdit"] = false;
          item['locationFormated'] = item?.location?.formatted;
          item['startDate'] = item?.startDate && item.startDate ? new Date(item?.startDate).toLocaleDateString() : '';
          item['endDate'] = item?.endDate && item.endDate ? new Date(item?.endDate).toLocaleDateString() : '';
        })
      }
      initobj.workExp = workExpObj && workExpObj.length > 0 ? workExpObj : [];
      initobj.skillDetails = myData[0].skillDetails ? JSON.parse(myData[0].skillDetails) : [];
      initobj.certification = myData[0].certification && myData[0].certification !== "undefined" ? JSON.parse(myData[0].certification) : [];
      initobj.id = myData[0].id;
      let OverwriteEditObj = decrypt('OverwriteEditObj');
      if (overwrideObj !== null) {
        setGetDetails(overwrideObj);
      } else if (OverwriteEditObj) {
        setOverwrideObj(JSON.parse(OverwriteEditObj));
        setGetDetails(JSON.parse(OverwriteEditObj));
      } else {
        setGetDetails(initobj)
      }
      console.log(initobj);
      dispatch(_ser.applicantsByIdStartReducer(initalState))
      setBreadCrumb(myData[0].id,myData[0].fullName, "Applicant Code")
    }
  }, [ApplicantData])

  const Editdata = useSelector(state => state.EditApplicants.data);
  let userData = JSON.parse(decrypt('GetUserInfo'));
  const GetValidData = async (data) => {
    let finalObj = data;
    finalObj = { ...data, ...data.Applicants };
    finalObj["id"] = getJobCode;
    finalObj.educationDetails = JSON.stringify(data.educationDetails)
    finalObj.workExp = JSON.stringify(data.workExp)
    finalObj.certification = JSON.stringify(data.certification);
    finalObj.skillDetails = JSON.stringify(data.skillDetails);
    finalObj.customFields = data.customFields;
    const userInfo = await getUserDetails()
    finalObj["userFirstName"] = userInfo.firstName ? userInfo.firstName : data.firstName;
    finalObj["userLastName"] = userInfo.lastName ? userInfo.lastName : data.lastName;
    finalObj["userId"] = userInfo.userId;
    dispatch(_ser.editApplicantsAsync(getJobCode, finalObj)).then(() => {
      setDisableSubmitBtn(false)
    }).catch(() => {
      setDisableSubmitBtn(false)
    });;
    if (overwrideObj !== null) {
      const userInfo = await getUserDetails();
      let formData = new FormData();
      formData.append("Resume", overwrideObj.Resume);
      formData.append("id", getJobCode);
      formData.append("userId", userInfo.userId);
      formData.append("DbUserId",userData[0].userId);
      formData.append("ResumeUserId", userData[0].userId);
      formData.append("fileType", "Resume");
      formData.append("resumesourceId", overwrideObj.Applicants.resumesourceId);
      dispatch(_ser.uploadDocumentsAsync(getJobCode, formData, true));
    }
    let newobj = { id: getJobCode, name: `${data.Applicants.firstName} ${data.Applicants.lastName}`, type: "Applicant Code" };
    let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
    if (subPathObj && subPathObj.length > 0) {
      let index = subPathObj.findIndex(x => x.id === newobj.id);
      if (index !== -1) {
        subPathObj[index] = newobj;
      } else {
        subPathObj.push(newobj);
      }
    } else {
      subPathObj = [newobj];
    }
    localStorage.setItem("subpathObj", JSON.stringify(subPathObj));
  }

  useEffect(() => {
    if (Editdata === 200) {
      dispatch(_ser.editApplicantsStartReducer(initalState))
      dispatch(_ser.applicantsByIdStartReducer(initalState));
      localStorage.removeItem("OverwriteEditObj");
      navigate(`/ats/layout/applicants/view/${getJobCode}`)
    }
  }, [Editdata])

  const actionBtns = [
    {
      variant: "text",
      style: { color: "red", marginRight: "25px", fontWeight: "bolder" },
      navigateTo: overwrideObj !== null ? "/ats/layout/applicants/landing" : `/ats/layout/applicants/view/${getJobCode}`,
      label: "Cancel",
      type: "back",
      class: 'buttonHover',
    },
    {
      variant: "contained",
      style: { backgroundColor: "#ffd800", color: "black", borderRadius: "10px", width: "6rem", fontSize:"14px" },
      navigateTo: null,
      label: "Save",
      type: "submit",
      class: 'buttonHover1',
    }
  ]

  return (
    <React.Fragment>
      <div style={{ padding: "0 10px" }} id="applicantsView">
        {getDetails.Applicants.firstName !== '' ?
          <ApplicantsComponent componentType="Edit" input={getDetails} iniValue={initialDetails}
            actions={actionBtns} onValidData={GetValidData} disableSubmitBtn={disableSubmitBtn} />
          : 
          <div className="nodatasection">
          <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noData" />
          {"No data found"}
          </div>
          }
      </div>
    </React.Fragment>
  )
}

export default ApplicantsEdit;

const initialDetails = {
  Applicants: { firstName: '', lastName: '', contactNumber: '', AlternateContactNumber: '', emailId: '' },
  educationDetails: [{ id: '', education: '', startDate: null, endDate: null, location: '' }],
  workExp: [{ id: '', organization: '', jobTitle: '', industry: '', startDate: null, endDate: null, isCurrent: '', location: '', jobDescription: '' }],
  skillDetails: [],
  certification: [],
  totalExperience: null,
  id: 0,
  userId: '',
  customFields: '',
  firstName: '',
  lastName: '',
  identifier: '',
  customerId: 0,
  source: "ATS",
  resumesourceId: '',
  candidateStatus: '',
  applicantCode: '',
  csAssociatedJobs: "",
  eAssociatedJobs: "",
  vsAssociatedJobs: "",
  Resume: null,
}