import React from 'react';

const loadingLayoutStyle = {
  backgroundColor: '#000000db',
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 999
}

const loadingTextStyle = {
  color: "yellow",
  fontSize: "2.5rem",
  fontWeight: "200",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  letterSpacing: "3px",
}

const Loading = () => {
  return (
    <div style={loadingLayoutStyle}>
      <p style={loadingTextStyle}>Please wait...</p>
    </div>
  )
}

export default Loading