import axios from "axios";
import { decrypt } from "../../_utilities/_encryptDecryptHelper";
import { getUserDetails } from "../CognitoSlice";

const getUserId = decrypt('GetUserId');
const userId = getUserId ? JSON.parse(getUserId) : '';
const getCountryId = decrypt('selectCountryId');
const countryId = getCountryId ? JSON.parse(getCountryId) : '';
const getUserName = decrypt('GetUserName') ? decrypt('GetUserName') : '';

if(userId === '' && countryId === '' && getUserName === ''){
  let url = window.location.href;
  let path = url.substring(url.lastIndexOf('/') + 1);
  if(path === 'ats'){
    window.location.assign('/ats/login')
  }
}

var headers = { PortalType: "atsportal", 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" }

var axiosApi = axios.create({
  baseURL: process.env.REACT_APP_JOBOPENING,
  headers: headers,
  timeout: 2500
});

axios.interceptors.request.use(function (config) {
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosApi.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => Promise.reject(error)
);

// Get method
export async function getApi(url, baseurl) {
  axiosApi.defaults.headers['Customerid'] = await JSON.parse(decrypt('GetUserId'));
  axiosApi.defaults.headers['Countryid'] = await JSON.parse(decrypt('selectCountryId'));
  axiosApi.defaults.headers['CustomerName'] = await JSON.parse(decrypt('GetUserName'));
  headers.Customerid = await JSON.parse(decrypt('GetUserId'))
  headers.Countryid = await JSON.parse(decrypt('selectCountryId'))
  headers.CustomerName = await JSON.parse(decrypt('GetUserName'));
  headers['Content-Type'] = 'application/json';
  axiosApi.defaults.headers['Content-Type'] = 'application/json';
  try {
    if (baseurl === undefined) {
      const response = await axiosApi.get(url);
      return response;
    } else {
      const response = await axios.get(`${baseurl}${url}`, { headers: headers });
      return response;
    }
  }
  catch (err) {
    console.log(err);
    // throw new Error(err);
  }
}

// Post method
export async function postApi(url, data, baseurl, isFormData, jobObj) {
  if(url !== "/mgmt/utils/atsuser"){
    axiosApi.defaults.headers['Customerid'] = await JSON.parse(decrypt('GetUserId'));
    axiosApi.defaults.headers['Countryid'] = await JSON.parse(decrypt('selectCountryId'));
    axiosApi.defaults.headers['CustomerName'] = await JSON.parse(decrypt('GetUserName'));
    headers.Customerid = await JSON.parse(decrypt('GetUserId'))
    headers.Countryid = await JSON.parse(decrypt('selectCountryId'))
    headers.CustomerName = await JSON.parse(decrypt('GetUserName'));
  }
  try {
    const userInfo = await getUserDetails();
    if (url !== "/forgot_password" && url !== "/verify_otp" && url !== "/generate_password" //&& url !== "/mgmt/utils/sendmail"
      && Boolean(isFormData) === false) {
      data['userId'] = userInfo.userId;
    }
    if(isFormData === true){
      headers['Content-Type'] = 'multipart/form-data';
      axiosApi.defaults.headers['Content-Type'] = 'multipart/form-data';
      if(jobObj){
        headers.Jobid = jobObj.jobId;
        headers.Jobcode = jobObj.jobCode;
        axiosApi.defaults.headers['Jobid'] = jobObj.jobId;
        axiosApi.defaults.headers['Jobcode'] = jobObj.jobCodeObj;
      }
    } else {
      headers['Content-Type'] = 'application/json';
      axiosApi.defaults.headers['Content-Type'] = 'application/json';
    }
    if (baseurl === undefined) {
      const response = await axiosApi.post(url, data, { headers: headers });
      return response;
    } else {
      // const response = await axios.post({ url: url, baseURL: baseurl, data: data, headers: headers});
      const response = await axios.post(`${baseurl}${url}`, data, { headers: headers });
      return response;
    }
  }
  catch (err) {
    throw new Error(err);
  }
}

// Put method
export async function putApi(url, data, baseurl, isFormData) {
  axiosApi.defaults.headers['Customerid'] = await JSON.parse(decrypt('GetUserId'));
  axiosApi.defaults.headers['Countryid'] = await JSON.parse(decrypt('selectCountryId'));
  axiosApi.defaults.headers['CustomerName'] = await JSON.parse(decrypt('GetUserName'));
  headers.Customerid = await JSON.parse(decrypt('GetUserId'))
  headers.Countryid = await JSON.parse(decrypt('selectCountryId'))
  headers.CustomerName = await JSON.parse(decrypt('GetUserName'));
  try {
    const userInfo = await getUserDetails()
    data['userId'] = userInfo.userId;
    if (Boolean(isFormData) === false) {
      data['userId'] = userInfo.userId;
    }
    if(isFormData === true){
      headers['Content-Type'] = 'multipart/form-data';
      axiosApi.defaults.headers['Content-Type'] = 'multipart/form-data';
    } else {
      headers['Content-Type'] = 'application/json';
      axiosApi.defaults.headers['Content-Type'] = 'application/json';
    }
    if (baseurl === undefined) {
      const response = await axiosApi.put(url, data);
      return response;
    } else {
      const response = await axios.put(`${baseurl}${url}`, data, { headers: headers });
      return response;
    }
  }
  catch (err) {
    throw new Error(err);
  }
}

// Patch method
export async function patchApi(url, data, baseurl, isFormData) {
  axiosApi.defaults.headers['Customerid'] = await JSON.parse(decrypt('GetUserId'));
  axiosApi.defaults.headers['Countryid'] = await JSON.parse(decrypt('selectCountryId'));
  axiosApi.defaults.headers['CustomerName'] = await JSON.parse(decrypt('GetUserName'));
  headers.Customerid = await JSON.parse(decrypt('GetUserId'))
  headers.Countryid = await JSON.parse(decrypt('selectCountryId'))
  headers.CustomerName = await JSON.parse(decrypt('GetUserName'));
  try {
    const userInfo = await getUserDetails()
    data['userId'] = userInfo.userId;
    if (Boolean(isFormData) === false) {
      data['userId'] = userInfo.userId;
    }
    if(isFormData === true){
      headers['Content-Type'] = 'multipart/form-data';
      axiosApi.defaults.headers['Content-Type'] = 'multipart/form-data';
    } else {
      headers['Content-Type'] = 'application/json';
      axiosApi.defaults.headers['Content-Type'] = 'application/json';
    }
    if (baseurl === undefined) {
      const response = await axiosApi.patch(url, data);
      return response;
    } else {
      const response = await axios.patch(`${baseurl}${url}`, data, { headers: headers });
      return response;
    }
  }
  catch (err) {
    throw new Error(err);
  }
}

// Delete method
export async function deleteApi(url, data, baseurl) {
  axiosApi.defaults.headers['Customerid'] = await JSON.parse(decrypt('GetUserId'));
  axiosApi.defaults.headers['Countryid'] = await JSON.parse(decrypt('selectCountryId'));
  axiosApi.defaults.headers['CustomerName'] = await JSON.parse(decrypt('GetUserName'));
  headers.Customerid = await JSON.parse(decrypt('GetUserId'))
  headers.Countryid = await JSON.parse(decrypt('selectCountryId'))
  headers.CustomerName = await JSON.parse(decrypt('GetUserName'));
  headers['Content-Type'] = 'application/json';
  axiosApi.defaults.headers['Content-Type'] = 'application/json';
  try {
    const userInfo = await getUserDetails()
    data['userId'] = userInfo.userId;
    data['customerId'] = headers.Customerid;
    data['Countryid'] = headers.Countryid;
    if (baseurl === undefined) {
      const response = await axiosApi.delete(url, { data: data });
      return response;
    } else {
      const response = await axios.delete(`${baseurl}${url}`, { data: data }, { headers: headers });
      return response;
    }
  }
  catch (err) {
    return err.response;
  }
}