import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Custom hook to warn users about unsaved changes when attempting to navigate away from the current page.
 * This hook handles both browser/tab navigation (like refresh or close) and in-app route changes.
 *
 * @param {boolean} unsavedChanges - A boolean indicating if there are unsaved changes.
 * @param {string} message - A custom warning message to show when navigating away with unsaved changes.
 */
const useUnsavedChangesWarning = (unsavedChanges, message = 'You have unsaved changes. Are you sure you want to leave?') => {
  // Get the current location path to determine if navigation is changing.
  const location = useLocation();
  const currentPath = location.pathname;
  const previousPathRef = useRef(currentPath); // Track the previous path to avoid unnecessary prompts.

  /**
   * Handle browser refresh or tab close events.
   * Shows a warning dialog if there are unsaved changes.
   */
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = message; // Standard message shown by the browser.
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges, message]);

  /**
   * Handle in-app navigation events, such as clicking a link or using the back/forward buttons.
   * Displays a confirmation dialog when there are unsaved changes.
   */
  useEffect(() => {

    const handlePopState = (event) => {
      const nextPath = window.location.pathname;
      if (unsavedChanges && nextPath !== currentPath) {
        const confirmLeave = window.confirm(message);
        if (!confirmLeave) {
          // Restore the previous path if navigation was blocked.
          window.history.pushState(null, '', previousPathRef.current);
        } else {
          // Update the reference to the new path if navigation is allowed.
          previousPathRef.current = nextPath;
        }
      }
    };

    // Listen for navigation events initiated via browser back/forward buttons.
    window.addEventListener('popstate', handlePopState);

    // Store the original `pushState` and `replaceState` methods to override them.
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    // Override `pushState` to handle navigation events triggered programmatically.
    window.history.pushState = (...args) => {
      const nextPath = args[2];
      if (unsavedChanges && nextPath !== currentPath) {
        const confirmLeave = window.confirm(message);
        if (confirmLeave) {
          originalPushState.apply(window.history, args);
          previousPathRef.current = nextPath;
        }
      } else {
        originalPushState.apply(window.history, args);
      }
    };

    // Override `replaceState` to handle in-app redirects.
    window.history.replaceState = (...args) => {
      const nextPath = args[2];
      if (unsavedChanges && nextPath !== currentPath) {
        const confirmLeave = window.confirm(message);
        if (confirmLeave) {
          originalReplaceState.apply(window.history, args);
          previousPathRef.current = nextPath;
        }
      } else {
        originalReplaceState.apply(window.history, args);
      }
    };

    return () => {
      // Clean up listeners and restore original methods when the component unmounts.
      window.removeEventListener('popstate', handlePopState);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [unsavedChanges, message, currentPath]);
};

export default useUnsavedChangesWarning;
