import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import './VerifyOTP.css';
import { useDispatch, useSelector } from 'react-redux';
import * as _ser from "../../../_redux/_services/UserManagerSlice";
import { sendCode, forgotPassword } from "../../../_redux/CognitoSlice";
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import { updateLoader } from '../../../_redux/LoaderSlice';
import MessageBar from '../../../components/SnackBar/MessageBar';
import Loader from '../../../components/Loader/Loader';

const VerifyOTP = () => {
  const location = useLocation();
  const [otp, setOTP] = useState("");
  const [encryptmail, setEncryptmail] = useState("");
  const emailProps = location.state.email;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showLoader = useSelector((state) => state.Loader.value);
  const showMessage = useSelector((state) => state.Snackbar.message);
  const showMsgType = useSelector((state) => state.Snackbar.type);
  const { data } = useSelector((state) => state.UserManager);
  const [initialSnackbar, setInitialSnackbar] = useState({ type: "", message: "" })

  const handleSubmit = async () => {
    if (otp) {
      dispatch(updateLoader(true));
      dispatch(updateSnackbar(initialSnackbar));
      let randomString = Math.random().toString(36).substring(2, 10);
      const randomPassword = `T${randomString}@`;
      dispatch(forgotPassword(emailProps, otp, randomPassword)).then(res => {
        dispatch(updateLoader(false));
        dispatch(updateSnackbar({ type: 'success', message: "OTP Verified" }));
        setTimeout(() => {
          navigate('/ats/reset-password', { state: { email: emailProps, oldPassword: randomPassword } });
          dispatch(_ser.userManagerStartReducer({}))
        }, 3000)
      }).catch((error) => {
        dispatch(updateLoader(false));
        let msg = sessionStorage.getItem("cognitoMsg");
        dispatch(updateSnackbar({ type: 'error', message: msg }));
        // dispatch(updateSnackbar({type: "error", message: "Invalid OTP"}));
        sessionStorage.removeItem("cognitoMsg");
      });
    }else{
      dispatch(updateSnackbar(initialSnackbar));
      setInitialSnackbar({ type: 'error', message: "Enter the OTP sent to your email" });
    }
  }

  useEffect(() => {
    if (emailProps) {
      getemail();
    }
  }, [emailProps])


  const resendOTP = async () => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar({ type: '', message: "" }));
    dispatch(sendCode(emailProps)).then(res=>{
      dispatch(updateLoader(false));
      dispatch(updateSnackbar({ type: 'success', message: "OTP resent successfully" }));
    }).catch(err => {
      dispatch(updateLoader(false));
      let msg = sessionStorage.getItem("cognitoMsg");
      dispatch(updateSnackbar({ type: 'error', message: msg }));
      sessionStorage.removeItem("cognitoMsg");
    });
  }

  const getemail = () => {
    let mail = emailProps;
    let splitmails = mail.split("@");
    if (splitmails.length > 0) {
      console.log(splitmails[0].length - 1);
      let value = splitmails[0].substr(0, 3) + 'xxxxxx' + splitmails[0].substr([splitmails[0].length - 1], 1) + "@" + splitmails[1];
      setEncryptmail(value);
    }
  }

  return (
    <div className='registerBackground-otp'>
      {/* <div className='BGImg1otp'>
        <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.svg"} alt="" />
        <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.1.svg"} alt="" />
      </div> */}
      <div className='BGImg1'>
        {/* <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.svg"} alt="" /> */}
        {/* <img src={process.env.PUBLIC_URL + "/assests/img/BGImg1.1.svg"} alt="" /> */}
  <div className='login-page'>
          <div className='login-page__left-container' >
            <img src={process.env.PUBLIC_URL + "/assests/img/login-icon-1.svg"} height={80} width={60} alt="HireWing" className="login-page__icon-1" />
            <img src={process.env.PUBLIC_URL + "/assests/img/login-icon-2.svg"} height={80} width={60} alt="HireWing" className="login-page__icon-2" />
            <img src={process.env.PUBLIC_URL + "/assests/img/login-icon-3.svg"} height={80} width={60} alt="HireWing" className="login-page__icon-3" />
            <img src={process.env.PUBLIC_URL + "/assests/img/login-icon-4.svg"} height={80} width={60} alt="HireWing" className="login-page__icon-4" />
            <img src={process.env.PUBLIC_URL + "/assests/img/login-icon-4.svg"} height={80} width={60} alt="HireWing" className="login-page__icon-5" />
            <div className="login-page__platform-info" >
              <div className="login-page__platform-info-logo">
                <img src={process.env.PUBLIC_URL + "/assests/img/Hirewing-logo.png"} alt="Hirewing logo" />
              </div>
              <div className="login-page__platform-info-heading">AI-Driven Recruitment Platform</div>
              <div className="login-page__platform-info-subtext">
                Let HireWing's advanced technology transform the way you recruit, making it easier and more efficient to find the perfect match for your organization.
              </div>
              <div className="login-page__platform-info-illustration">
                <img src={process.env.PUBLIC_URL + "/assests/img/hirewing-illustration.svg"} alt="Illustration" />
              </div>
            </div>
          </div>
          {showMessage !== "" ? <MessageBar message={showMessage} type={showMsgType} /> : ''}
          {showLoader ? <Loader /> : ''}
          <div className='login-page__right-section'>
            <div className='login-page__mobile-section'>
              <div className="login-page__platform-info-logo-mobile">
                <img src={process.env.PUBLIC_URL + "/assests/img/Hirewing-logo.png"} alt="Hirewing logo" />
              </div>
              <div className="login-page__platform-info-heading-mobile">AI-Driven Recruitment Platform</div>
              <div className="login-page__platform-info-subtext-mobile">
              Let HireWing's advanced technology transform the way you recruit, making it easier and more efficient to find the perfect match for your organization.
              </div>
            </div>
         
          </div>
        </div>


      </div>
      {showMessage !== "" ? <MessageBar message={showMessage} type={showMsgType} /> : ''}
      {showLoader ? <Loader /> : ''}
      {/* <div className='BGImg2otp'>
        <img src={process.env.PUBLIC_URL + "/assests/img/BGImg2.svg"} alt="" />
      </div> */}
      <div className='registration-container-otp'>
        <div className='text-center  d-flex flex-column  align-items-center h600'>
          {/* <div> <img src={process.env.PUBLIC_URL + "/assests/img/HirewingLogin.svg"} alt="" className='hirewinglogoverifyotp' /></div> */}
          <br />
          <br />
          <p style={{marginTop:"43px"}}>Verification code sent to <br /><b>{encryptmail}</b></p>
          <div className='d-flex justify-content-start flex-column text-left' style={{marginTop:"50px"}}>
            <div className='d-flex  text-center'>
              <OtpInput
                value={otp}
                onChange={setOTP}
                autoComplete="off"
                inputStyle={{ width: 42, height: 52, margin: 6, fontSize: 20, boxShadow: "0px 3px 6px #00000029", border: "1px solid #E5E5E5", borderRadius: "10px" }}
                numInputs={6}
                separator={""}
              />
            </div>
            <div className='text-center'>
              <div className='didntreceive'>
                <p>Didn't receive? <button className="forgotlink2" onClick={() => resendOTP()}>Resend otp</button></p>
              </div>
              <button type="submit" onClick={handleSubmit} className="btn-submitotp-verify btn-submitlogin">
                Submit
              </button>

              <Link to="/ats/login" type="button" className='backtologin'>
                Back to login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyOTP
