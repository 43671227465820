import { updateLoader } from "../_redux/LoaderSlice";
import * as _AmazonCognitoIdentity from "amazon-cognito-identity-js";
import {
  getCustDetailsAsync,
  userManagerReducer,
} from "../_redux/_services/UserManagerSlice";
import { updateAuth } from "../_redux/AuthSlice";
import { updateSnackbar } from "./SnackbarSlice";
import { Encrypt, decrypt } from "../_utilities/_encryptDecryptHelper";

const initialSnackbar = { type: "", message: "" };

let currentUser;
let userPool;
initUserSession();

export const signInWithEmailAsync = (username, password, newPasswordRequired) => async (dispatch) => {
  dispatch(updateLoader(true));
  dispatch(updateSnackbar(initialSnackbar));
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails =
      new _AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    currentUser = getCognitoUser(username);
    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        console.log("auth success");
        const session = await getSession();
        if (session.isValid()) {
          const accessToken = result.getAccessToken().getJwtToken();
          const idToken = result.getIdToken().getJwtToken();
          const refreshToken = result.getRefreshToken().getToken();
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("idToken", idToken);
          localStorage.setItem("refreshToken", refreshToken);
        }
        initUserSession();
        resolve(result);
        const userInfo = await getUserDetails();
        dispatch(updateAuth(userInfo));
        dispatch(updateSnackbar(initialSnackbar));
        let USERId = { userId: userInfo.userId };
        dispatch(getCustDetailsAsync(USERId));
      },
      onFailure: function (err) {
        // reject(err);
        dispatch(updateLoader(false));
        if (err.code === "InvalidParameterException") {
          dispatch(updateSnackbar({ type: "error", message: "Login failed" }));
        } else {
          dispatch(updateSnackbar({ type: "error", message: err.message }));
        }
      },
      newPasswordRequired: newPasswordRequired,
    });
  })
  .catch((err) => {
    throw err;
  });
};

export function initUserSession() {
  const poolData = {
    UserPoolId: process.env.REACT_APP_USERPOOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
    storage: window.sessionStorage,
  };
  userPool = new _AmazonCognitoIdentity.CognitoUserPool(poolData);
  currentUser = userPool.getCurrentUser();
  if (currentUser) {
    const accessToken = localStorage.getItem("accessToken");
    const idToken = localStorage.getItem("idToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const userSession = new _AmazonCognitoIdentity.CognitoUserSession({
      IdToken: new _AmazonCognitoIdentity.CognitoIdToken({ IdToken: idToken }),
      AccessToken: new _AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: accessToken,
      }),
      RefreshToken: new _AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: refreshToken,
      }),
      ClockDrift: 0,
    });
    currentUser.setSignInUserSession(userSession);
  }
}

export function getCurrentUser() {
  return currentUser;
}

function getCognitoUser(username) {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new _AmazonCognitoIdentity.CognitoUser(userData);
  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err, session) {
      if (err) {
        // reject(err);
      } else {
        resolve(session);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signUpUserWithEmail(username, email, password) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new _AmazonCognitoIdentity.CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
    ];

    userPool.signUp(username, password, attributeList, [], function (err, res) {
      if (err) {
        // reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export const verifyCode = (username, code) => async (dispatch) => {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      dispatch(updateSnackbar(initialSnackbar));
      if (err) {
        dispatch(updateSnackbar({ type: "error", message: err }));
      } else {
        resolve(result);
        dispatch(userManagerReducer({ username: username, type: "verifyCode" }));
      }
    });
  }).catch((err) => {
    throw err;
  });
};

export function signOut() {
  if (currentUser) {
    currentUser.signOut();
  }
}

export async function getUserDetails() {
  const attributes = await getAttributes();
  if(attributes){
    const user = {
      firstName: attributes.find((a) => a.Name === "given_name").Value,
      lastName: attributes.find((a) => a.Name === "family_name").Value,
      email: attributes.find((a) => a.Name === "email").Value,
      userId: attributes.find((a) => a.Name === "sub").Value,
    };
    localStorage.setItem("getAttributes", JSON.stringify([user]));
    return user;
  } else {
    let getUserDetails = localStorage.getItem("getAttributes");
    if(getUserDetails){
      let details = JSON.parse(getUserDetails);
      const user = {
        firstName: details[0].firstName,
        lastName: details[0].lastName,
        email: details[0].email,
        userId: details[0].userId,
      };
      return user;
    } else {
      signOut();
      window.location = '/ats/login';
    }
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    if (currentUser === null) {
      console.log("User session invalid");
    }
      currentUser.getUserAttributes(function (err, attributes) {
        if (err) {
          reject(err);
        } else {
          resolve(attributes);
        }
      });
    }).catch((err) => {
      signOut();
    // throw err;
  });
}

export async function setAttribute(attribute) {
  return new Promise(function (resolve, reject) {
    const attributeList = [];
    const res = new _AmazonCognitoIdentity.CognitoUserAttribute(attribute);
    attributeList.push(res);
    currentUser.updateAttributes(attributeList, (err, res) => {
      if (err) {
        // reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export const sendCode = (username) => async (dispatch) => {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);
    dispatch(updateSnackbar(initialSnackbar));
    if (!cognitoUser) {
      dispatch(updateSnackbar({ type: "error", message: `could not find ${username}` }));
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
        dispatch(userManagerReducer({ username: username, type: "Generate OTP" }));
      },
      onFailure: function (err) {
        sessionStorage.setItem("cognitoMsg", err.message);
        dispatch(updateSnackbar({ type: "error", message: err }));
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
};

export const forgotPassword = (username, code, randomPassword) => async (dispatch) => {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);
    dispatch(updateSnackbar(initialSnackbar));
    if (!cognitoUser) {
      dispatch(updateSnackbar({ type: "error", message: `could not find ${username}` }));
      return;
    }
    cognitoUser.confirmPassword(code, randomPassword, {
      onSuccess: function (res) {
        resolve(res);
        dispatch(
          userManagerReducer({ username: randomPassword, type: "Otp Verified" })
        );
      },
      onFailure: function (err) {
        sessionStorage.setItem("cognitoMsg", err.message);
        dispatch(updateSnackbar({ type: "error", message: err.message }));
        reject(err);
      },
    });
  });
};

export const changePassword = (newPassword, oldPassword, username) => async (dispatch) => {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: oldPassword,
    };

    const authenticationDetails =
      new _AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new _AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (session) {
        cognitoUser.changePassword(
          oldPassword,
          newPassword,
          function (err, result) {
            if (err) {
              sessionStorage.setItem("cognitoMsg", err.message);
              // reject(err)
            } else {
              dispatch(userManagerReducer({ username: result, type: "passwordChanged" }));
              resolve(result);
            }
          }
        );
      },
      onFailure: function (err) {
        console.log("Error authenticating user:", err);
      },
    });
  })
};

export async function completePasswordChallenge(newPassword, userAttr) {
  return new Promise(function (resolve, reject) {
    currentUser.completeNewPasswordChallenge(newPassword, userAttr, {
      onFailure: function (err) {
        if (err) {
          // reject(err);
        }
      },
      onSuccess: function (userSession) {
        currentUser.setSignInUserSession(userSession);
        resolve(currentUser);
      },
    });
  });
};

// export async function refreshToken() {
export const refreshToken = () => async (dispatch) => {
  const Token = localStorage.getItem("refreshToken");
  if(Token){
    return await new Promise(function (resolve, reject) {
      const refreshToken = new _AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: Token });
      currentUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          console.error('Token refresh failed:', err);
          window.location = '/ats/login';
        } else {
          console.log(session);
          const accessToken = session.getAccessToken().getJwtToken();
          const idToken = session.getIdToken().getJwtToken();
          const refreshToken = session.getRefreshToken().getToken();
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("idToken", idToken);
          localStorage.setItem("refreshToken", refreshToken);
          console.log('Token refreshed successfully:', session);
          initUserSession();
          getUserDetails().then(userInfo => {
            dispatch(updateAuth(userInfo));
            let USERId = { userId: userInfo.userId };
            dispatch(getCustDetailsAsync(USERId));
          })
        }
      });
    });
  }
}