export const defaultPostingsData = [
    {
        id: 1,
        moduleId: "1",
        fieldName: "workmodel",
        fieldType: "default",
        dataType: "Single select",
        customValues: ["Hybrid", "On-site", "Remote"],
        action: "action",
    },
    {
        id: 2,
        moduleId: "1",
        fieldName: "JobStatus",
        fieldType: "default",
        dataType: "Single select",
        customValues: ["Closed", "Filled", "Active", "On-Hold"],
        action: "action",
    },
    {
        id: 3,
        moduleId: "1",
        fieldName: "JobType",
        fieldType: "default",
        dataType: "Single select",
        customValues: ["Full time", "Part time", "Intern", "Contract"],
        action: "action",
    },
    {
        id: 4,
        moduleId: "1",
        fieldName: "priority",
        fieldType: "default",
        dataType: "Single select",
        customValues: ["High", "Medium", "Low"],
        action: "action",
    }
]

export const defaultApplicantsData = [
    {
        id: 1,
        moduleId: "2",
        fieldName: "Source",
        fieldType: "default",
        dataType: "Single select",
        customValues: ["LinkedIn", "Indeed", "Zoho Recruit", "Zip Recruit", "Monster", "Social Media", "Ceipal", "Reference", "Career Builder", "Others"],
        action: "action",
    },
    {
        id: 2,
        moduleId: "2",
        fieldName: "Candidate status",
        fieldType: "default",
        dataType: "Single select",
        customValues: [
            {
                "id": "Pending",
                "internal": "Pending",
                "external": "Pending"
            },
            {
                "id": "Associated",
                "internal": "Associated",
                "external": "Associated"
            },
            {
                "id": "Consent_await",
                "internal": "Consent await",
                "external": "Consent await"
            },
            {
                "id": "Submitted",
                "internal": "Submitted",
                "external": "Submitted"
            },
            {
                "id": "Interview",
                "internal": "Interview",
                "external": "Interview"
            },
            {
                "id": "Selected",
                "internal": "Selected",
                "external": "Selected"
            },
            {
                "id": "Rejected",
                "internal": "Rejected",
                "external": "Rejected"
            },
            {
                "id": "Assigned",
                "internal": "Assigned",
                "external": "Assigned"
            }
        ],
        action: "action",
      }
]


export const defaultmailApplicantsData = [
    {
        id: 1,
        fieldName: "Template",
        fieldType: "default",
        dataType: "SingleSelect",
        customValues: ["Email Template 001" ,"Email Template 002" ,"Email Template 003", "Email Template 004"],
        action: "action",
    },
]
