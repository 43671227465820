import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as _ser from '../../../_redux/_services/ApplicantsSlice';
import { updateSnackbar } from "../../../_redux/SnackbarSlice";
import { setBreadCrumb } from '../../../_utilities/breadCrumb';
import GetJobId from '../../../_utilities/GetJobId';
import GenerateTemplate from '../../../components/GenerateTemplate/GenerateTemplate';
import RightSideBar from '../../../components/Rightsidebar/RightSideBar';
import ApplicantsComponent from '../../Applicants/ApplicantsComponent';


const initalState = { data: null, message: '', isloader: true }

const ApplicantsView = (props) => {
  const [getDetails, setGetDetails] = useState(initialDetails);
  const [isGenerateTemplate, setIsGenerateTemplate] = useState(false);

  let getJobCode = props.getData ? null : GetJobId();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actionBtns = [{
    // variant: "contained", label: "Edit", type: "back", /// To DO
    style: { backgroundColor: "#ebc700 ", color: "black", borderRadius: "25px", width: "6rem", textTransform: "capitalize" },
    class: 'buttonHover1',
    navigateTo: `/ats/layout/applicants/edit/${getJobCode}`
  } ]

  const actionMenus = [
    { id: 0, name: 'Edit', navigate: true, url: `/ats/layout/applicants/edit/${getJobCode}` },
    { id: 1, name: 'Send Mail', navigate: false, isTemplate: true },
  ]

  useEffect(() => {
    if (getJobCode !== null) {
      GetData();
    }
  }, [getJobCode])

  useEffect(() => {
    if (props.getData) {
      dispatch(_ser.applicantsByIdAsync(props.getData.applicantID)); //TODO
    }
  }, [props.getData])

  useEffect(() => {
  }, [getDetails])

  const onGetValidData = (data) => {
    console.log(data);
  }

  const ApplicantData = useSelector(state => state.ApplicantsById.data);

  const GetData = async () => {
    dispatch(_ser.applicantsByIdAsync(getJobCode));
  };

  useEffect(() => {
    if (ApplicantData && ApplicantData.length > 0) {
      let initobj = JSON.parse(JSON.stringify(initialDetails));
      let myData = JSON.parse(JSON.stringify(ApplicantData));
      initobj.Applicants.firstName = myData[0].firstName;
      initobj.Applicants.lastName = myData[0].lastName;
      initobj.Applicants.contactNumber = myData[0].contactNumber;
      initobj.Applicants.AlternateContactNumber = myData[0].AlternateContactNumber;
      initobj.Applicants.emailId = myData[0].emailId;
      initobj.Applicants.resumesourceId = myData[0].resumesourceId;
      initobj.candidateStatus = myData[0].candidateStatus;
      initobj.customFields = myData[0].customFields;
      initobj.source = myData[0].source;
      initobj.resumesource = myData[0].resumesource;
      initobj.applicantCode = myData[0].applicantCode;
      // initobj.csAssociatedJobs = JSON.parse(myData[0].csAssociatedJobs);   /// Removed in Backend ////
      // initobj.eAssociatedJobs = JSON.parse(myData[0].eAssociatedJobs);     /// Removed in Backend ////
      // initobj.vsAssociatedJobs = JSON.parse(myData[0].vsAssociatedJobs);   /// Removed in Backend ////
      let educationObj = JSON.parse(myData[0].educationDetails);
      if (educationObj !== null && educationObj.length > 0) {
        educationObj.forEach((item, i) => {
          item["id"] = i;
          item["isEdit"] = false;
          item['startDate'] = item?.startDate && item.startDate ? new Date(item?.startDate).toLocaleDateString() : '';
          item['endDate'] = item?.endDate && item.endDate ? new Date(item?.endDate).toLocaleDateString() : '';
          if (item?.education) { item['degree'] = item.education; }
        })
      }
      initobj.educationDetails = educationObj && educationObj.length > 0 ? educationObj : [];
      let workExpObj = JSON.parse(myData[0].workExp);
      if (workExpObj !== null && workExpObj.length > 0) {
        workExpObj.forEach((item, i) => {
          item["id"] = i;
          item["isEdit"] = false;
          item['locationFormated'] = item?.location?.formatted;
          item['startDate'] = item?.startDate && item.startDate ? new Date(item?.startDate).toLocaleDateString() : '';
          item['endDate'] = item?.endDate && item.endDate ? new Date(item?.endDate).toLocaleDateString() : '';
        })
      }
      initobj.workExp = workExpObj && workExpObj.length > 0 ? workExpObj : [];
      initobj.skillDetails = myData[0].skillDetails ? JSON.parse(myData[0].skillDetails) : [];
      initobj.certification = myData[0].certification && myData[0].certification !== "undefined" ? JSON.parse(myData[0].certification) : [];
      initobj.id = myData[0].id;
      setGetDetails(initobj)
      dispatch(_ser.applicantsByIdStartReducer(initalState))
      setBreadCrumb(myData[0].id, myData[0]?.fullName ? myData[0].fullName : myData[0].name , "Applicant Code")
    }
  }, [ApplicantData])

  const onSelectActionHandler = (value) => {
    if (actionMenus[value].name === "Edit") {
      navigate(actionMenus[value].url);
    } else {
      if((getDetails.Applicants.emailId !== "" && getDetails.Applicants.emailId) ||(getDetails.emailId !== "" && getDetails.emailId) ){

        setIsGenerateTemplate(true)
      }else{
        dispatch(updateSnackbar({ type: "error", message: "EmailId Doesn't Exists" }));
      }
    
    }
  }

  const onReceivePropsHandler = (pannel, load) => {
    if (load === "close") {
      setIsGenerateTemplate(pannel);
    }
  };

  return (
    <>
      <div className="action-btn-dropdown">
         <FormControl style={{ textDecorationColor: "black", width: "32%" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="actionDropdown"
            value="Action"
            style={{ paddingLeft: "20px", width: "180px" }}
            onChange={(e) => onSelectActionHandler(e.target.value)}
          >
            {actionMenus.map(list => (
              <MenuItem
                style={{
                  display: "grid",
                  justifyContent: "left",
                  padding: "6px 30px",
                  cursor: "pointer",
                  width: "180px",
                }}
                key={list.id}
                value={list.id}
              >
                {list.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> 
      </div>
      <div style={{ padding: "0 10px" }} id="applicantsView">
        {getDetails.Applicants.firstName !== '' ?
          <ApplicantsComponent componentType="view" input={getDetails} iniValue={initialDetails}
            actions={actionBtns} onValidData={onGetValidData} />
          : <div className="nodatasection">
          <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noData" />
          {"No data found"}
        </div>}
      </div>
      {Boolean(isGenerateTemplate) && (
        <RightSideBar
          componentData={<GenerateTemplate isAssociatedProfile={false} applicantDetails={getDetails} jobCode={null} onReceiveProps={onReceivePropsHandler} />}
          componentLayout={MailOBJ}
          onReceiveProps={onReceivePropsHandler}
        />
      )}
    </>
  )
}

export default ApplicantsView;

const initialDetails = {
  Applicants: { firstName: '', lastName: '', contactNumber: '', AlternateContactNumber: '', emailId: '' },
  educationDetails: [{ id: '', education: '', startDate: null, endDate: null, location: '' }],
  workExp: [{ id: '', organization: '', jobTitle: '', industry: '', startDate: null, endDate: null, isCurrent: '', location: '', jobDescription: '' }],
  skillDetails: [],
  certification: [],
  totalExperience: null,
  customFields: '',
  id: 0,
  userId: '',
  firstName: '',
  lastName: '',
  identifier: '',
  source: 1,
  resumesourceId: '',
  candidateStatus: '',
  csAssociatedJobs: "",
  eAssociatedJobs: "",
  vsAssociatedJobs: "",
}

const MailOBJ = {
  title: "Email",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/emailbackground.svg`,
};