import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { getUserDetails } from '../../../_redux/CognitoSlice';
import { updateLoader } from '../../../_redux/LoaderSlice';
import * as _ser from "../../../_redux/_services/ApplicantsSlice";
import * as _postingsser from "../../../_redux/_services/JobPostingSlice";
import { decrypt } from '../../../_utilities/_encryptDecryptHelper';
import ApplicantsComponent from "../ApplicantsComponent";
import ApplicantsManualUploadPopup from '../ApplicantsTable/ApplicantsManualUploadPopup';
import './ApplicantsCreate.css';
import DuplicateApplicantsManualUploadPopup from '../ApplicantsTable/DuplicateApplicantsManualUploadPopup';

const initalState = { data: null, message: '', isloader: true, manualDuplicateApplicant: null }

const ApplicantsCreate = () => {
  
  const [inputData, setInputData] = useState(undefined);
  const [isUpload, setIsUpload] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [actionButtons, setActionButtons] = useState(JSON.parse(JSON.stringify(actionBtns)));
  const [uploadFile, setUploadFile] = useState()
  const [GetUserId, setUserId] = useState(null);
  const [applicantFile, setApplicantFile] = useState(null);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [duplicateApplicatsConfirmPopup, setDuplicateApplicatsConfirmPopup] = useState(false);
  const [skipId, setSkipId] = useState(null);
  const [ skipName, setSkipName ] = useState(null);
  const [twoDuplicateApplicatsConfirmPopup, setTwoDuplicateApplicatsConfirmPopup] = useState(false);
  const [manualDuplicateApplicant, setManualDuplicateApplicant] = useState({});

  const uploadResumeData = useSelector(store => store.UploadResume.data);

  const DuplicateResume = useSelector(store => store.DuplicateResume.data);
  const UploadDocCode = useSelector(state => state.UploadDocuments.data);

  useEffect(()=>{
    if(GetUserId === null){
      setUserId(JSON.parse(decrypt('GetUserId')));
    }
  }, [GetUserId])

  useEffect( () => {
    if(location.state?.applicant && location.state?.sourceId && location.state?.uploadfile){
      setIsUpload(false);
      let json = JSON.parse(JSON.stringify(location.state.applicant));
      json.Applicants["resumesourceId"] = location.state.sourceId;
      json.Applicants["candidateStatus"] = location.state.candidateStatus;
      json["Resume"] = location.state.uploadfile;
      setApplicantFile(location.state.uploadfile);
      getRenderUI(json)
    }
  }, [location])


  useEffect( () => {
    if(location.state?.isAssociate !== null){
      // console.log(location.state,"haiiiii");
      
      let array = JSON.parse(JSON.stringify(actionBtns));
      
      array[0].navigateTo = `/ats/layout/jobpostings/associatedapplicants/${location.state?.isAssociate.jobId}`
      
      setActionButtons(array);
    } else {
      // console.log(location.state,"byyyyyy");
      setActionButtons(JSON.parse(JSON.stringify(actionBtns)));
    }
  }, [location])
 
  useEffect( () => {
    let path = location.pathname;
    if(path.includes("create-duplicate")){
      setDisableSubmitBtn(false)
    }
    setDuplicateApplicatsConfirmPopup(false);
  }, [location])

  useEffect(()=>{
    if(uploadResumeData !== null){
      setIsLoading(false);
      setIsUpload(false);
      dispatch(_ser.uploadResumeStartReducer(initalState))
      let json = JSON.parse(JSON.stringify(uploadResumeData[0]));
      getRenderUI(json)
    }
  }, [uploadResumeData])
  
  useEffect(() => {
    if(DuplicateResume !== null){
      setDisableSubmitBtn(false);
      if(location.state?.associateAnyway === true){
        dispatch(_ser.addApplicantWithAssocatedStartReducer(initalState));
        let userId = JSON.parse(localStorage.getItem("getAttributes"));
        let userInfo = JSON.parse(decrypt('GetUserInfo'));
        let obj = {id : DuplicateResume.insertId, applicantCode : DuplicateResume.latestapplicantcode, portalassociatedApplicants : 'associatedApplicants', submittedBy: userInfo[0].userId, source: "ATS", firstName: userId[0].firstName , lastName: userId[0].lastName, status: "Associated", docId: DuplicateResume.docId};
        dispatch(_postingsser.addAssociatedjobsAsync(location.state.isAssociate.jobId, obj));
        // let obj1 = { id: location.state.isAssociate.jobId, portalassociatedJobs : "associatedJobs"}
        // dispatch(_ser.addAssociatedjobsAsync(DuplicateResume.insertId, obj1));
        dispatch(_ser.addApplicantsStartReducer(initalState));
      } else {
        dispatch(_ser.duplicateResumeStartReducer(initalState))
        navigate("/ats/layout/applicants/landing");
      }
    }
  }, [DuplicateResume])
  
  // useEffect(() => {
  //   if (uploadResumeData !== null){
  //     setIsLoading(false);
  //     setIsUpload(false);
  //     let json = JSON.parse(JSON.stringify(uploadResumeData[0]));
  //     getRenderUI(json)
  //   }
  // }, [uploadResumeData])

  const getRenderUI = (json) => {
    let obj = json;
      obj.Applicants = json.Applicants;
      obj.Applicants['contactNumber'] = json.Applicants.phone;
      obj.Applicants['AlternateContactNumber'] = json.Applicants.AlternateContactNumber ? json.Applicants.AlternateContactNumber : '';
      obj.Applicants['phone'] = obj.Applicants.phone ? obj.Applicants.phone : "";
      json.educationDetails && json.educationDetails.forEach((e, i) => {
        e["isEdit"] = false; e["id"] = i;
        e["startDate"] = e.startDate ? new Date(e.startDate).toLocaleDateString() : null;
        e["endDate"] = e.endDate ? new Date(e.endDate).toLocaleDateString() : null;
        e["duration"] = `${e.startDate} - ${e.endDate}`;
        e["isCurrent"] = false;
        e["location"] = e.location && e.location.formatted ? e.location.formatted : '';
        e["location"] = undefined;
      })
      obj.educationDetails = json.educationDetails !== undefined ? json.educationDetails : [];
      json.workExperiences && json.workExperiences.forEach((e, i) => {
        e["isEdit"] = false; e["id"] = i;
        e["startDate"] = e.experienceInMonths && e.experienceInMonths.startDate ? new Date(e.experienceInMonths.startDate).toLocaleDateString() : null;
        e["endDate"] = e.experienceInMonths && e.experienceInMonths.endDate ? new Date(e.experienceInMonths.endDate).toLocaleDateString() : null;
        e["duration"] = `${e.startDate} - ${e.endDate}`;
        e["monthsInPosition"] = e.experienceInMonths && e.experienceInMonths.monthsInPosition ? e.experienceInMonths.monthsInPosition : '';
        e["isCurrent"] = e.experienceInMonths && e.experienceInMonths.isCurrent ? e.experienceInMonths.isCurrent : false;
        e["rawText"] = e.experienceInMonths && e.experienceInMonths.rawText ? e.experienceInMonths.rawText : '';
        e["experienceInMonths"] = undefined;
      })
      obj["workExp"] = json.workExperiences !== undefined ? json.workExperiences : [];
      obj.skillDetails = json.skillDetails;
      obj['certification'] = json.certifications || [];
      obj.totalExperience = json.totalExperience;
      obj.jobDescription = json.jobDescription ? json.jobDescription : '';
      obj.source = json.source ? json.source :  "ATS";
      obj.Applicants.candidateStatus = json.Applicants.candidateStatus ? json.Applicants.candidateStatus :  "";
      setInputData(obj);
      dispatch(_ser.uploadResumeStartReducer(initalState))
  }

  const Applicantdata = useSelector(store => store.AddApplicants.data);
  const Applicantcode = useSelector(store => store.AddApplicants.code);

  const { code, data } = useSelector(store => store.AddApplicantWithAssocated);

  const ManualDuplicateResume = useSelector(store => store.AddApplicants.manualDuplicateApplicant);

  const AllowDuplicateResume = useSelector(store => store.AddApplicants.updatedata);
  let userData = JSON.parse(decrypt('GetUserInfo'));
  const GetValidData = async (updateData) => {
    const userInfo = await getUserDetails()
    let formData = new FormData();
    formData.append("firstName", updateData.Applicants.firstName);
    formData.append("lastName", updateData.Applicants.lastName);
    formData.append("contactNumber", updateData.Applicants.contactNumber);
    formData.append("AlternateContactNumber", updateData.Applicants.AlternateContactNumber 
    // ? updateData.Applicants.AlternateContactNumber : updateData.Applicants.phone ? updateData.Applicants.phone : ""
    );
    formData.append("emailId", updateData.Applicants.emailId);
    formData.append("educationDetails", JSON.stringify(updateData.educationDetails ? updateData.educationDetails : []));
    formData.append("workExp", JSON.stringify(updateData.workExp ? updateData.workExp : []));
    formData.append("certification", JSON.stringify(updateData.certification ? updateData.certification : []));
    formData.append("skillDetails", JSON.stringify(updateData.skillDetails ? updateData.skillDetails : []));
    formData.append("jobTitle", updateData.workExp && updateData.workExp.length > 0 ? updateData.workExp[updateData.workExp.length - 1].jobTitle : '');
    formData.append("jobDescription", updateData.jobDescription);
    formData.append("Resume", updateData.applicantFiles && Object.keys(updateData.applicantFiles).length > 0 ? updateData.applicantFiles : applicantFile);
    formData.append("customFields", updateData.customFields);
    formData.append("userId", userInfo.userId);
    formData.append("userFirstName", userInfo.firstName);
    formData.append("userLastName", userInfo.lastName);
    formData.append("identifier", updateData.identifier ? updateData.identifier : updateData.oldidentifier_f);
    formData.append("customerId", GetUserId);
    // formData.append("ATSPortal", 1);
    // formData.append("VSPortal", 0);
    // formData.append("CSPortal", 0);
    // formData.append("IPortal", 0);
    formData.append("source", "ATS");
    formData.append("DbUserId", userData[0].userId);
    formData.append("resumesourceId",updateData.Applicants.resumesourceId );
    formData.append("candidateStatus",updateData.Applicants.candidateStatus );
    setInputData(updateData);
    setDisableSubmitBtn(false);
    if(location.state.isduplicate === true){
      setDuplicateApplicatsConfirmPopup(false);
      dispatch(_ser.duplicateResumeAsync(formData, true));
    } else if(location.state.isAssociate !== null){
      if(data !== null && data.message.includes("Applicant Already Exists In Parsed DB With Identifier:")){ }
      else {
        if(location.state.isManual === true){
          dispatch(_ser.addApplicantsAsync(formData, true));
        } else {
          dispatch(_ser.addApplicantWithAssocatedAsync(formData, location.state.isAssociate.jobId, true));
        }
      }
    } else {
      dispatch(_ser.addApplicantsAsync(formData, true));
    }
  }
  
  useEffect(()=>{
    if (Applicantcode === 200 || Applicantcode === 500) {
      if(location.state.isAssociate === null){
        setDisableSubmitBtn(false);
        dispatch(_ser.addApplicantsStartReducer(initalState));
        navigate("/ats/layout/applicants/landing");
      } else if(Applicantdata !== null){
        setDisableSubmitBtn(false);
        dispatch(_ser.addApplicantWithAssocatedStartReducer(initalState));
        let userId = JSON.parse(localStorage.getItem("getAttributes"));
        let userInfo = JSON.parse(decrypt('GetUserInfo'))
        let obj = {id : Applicantdata.insertedid, jobCode : Applicantdata.jobCode, applicantCode : Applicantdata.latestapplicantcode, portalassociatedApplicants : 'associatedApplicants', submittedBy: userInfo[0].userId, source: "ATS", firstName: userId[0].firstName , lastName: userId[0].lastName, status: "Associated", docId : Applicantdata.docId};
        dispatch(_postingsser.addAssociatedjobsAsync(location.state.isAssociate.jobId ? location.state.isAssociate.jobId : location.state.isAssociate, obj));  ///  location.state.isAssociate.jobId
        // let obj1 = { id: location.state.isAssociate.jobId, portalassociatedJobs : "associatedJobs"}
        // dispatch(_ser.addAssociatedjobsAsync(Applicantdata.insertedid, obj1));
        dispatch(_ser.addApplicantsStartReducer(initalState));
      }
    }
  }, [Applicantcode, Applicantdata])

  useEffect(() => {
    if(AllowDuplicateResume !== null){
      setDuplicateApplicatsConfirmPopup(true);
      setSkipId(AllowDuplicateResume.id);
      setDisableSubmitBtn(false);
    }
  }, [AllowDuplicateResume])

  useEffect(() => {
    if(ManualDuplicateResume ){
      // setManualDuplicateApplicant(ManualDuplicateResume);
      setTwoDuplicateApplicatsConfirmPopup(true);
      setSkipId(ManualDuplicateResume.id);
      setSkipName(ManualDuplicateResume.fullName);
      setDisableSubmitBtn(false);
      dispatch(_ser.addApplicantsStartReducer(initalState));
    }
  }, [ManualDuplicateResume])


  useEffect(()=>{
    if (code === 200) {
      setDisableSubmitBtn(false);
      dispatch(_ser.addApplicantWithAssocatedStartReducer(initalState));
      let userId = JSON.parse(localStorage.getItem("getAttributes"));
      let userInfo = JSON.parse(decrypt('GetUserInfo'))
      console.log(userId);
      let obj = {id : data.insertedid, jobCode : data.jobCode, applicantCode : data.latestapplicantcode, portalassociatedApplicants : 'associatedApplicants', submittedBy: userInfo[0].userId, source: "ATS", firstName: userId[0].firstName , lastName: userId[0].lastName, status: "Associated",docId : data.documentId};
      dispatch(_postingsser.addAssociatedjobsAsync(location.state.isAssociate.jobId, obj));
      // let obj1 = { id: location.state.isAssociate, portalassociatedJobs : "associatedJobs"}
      // dispatch(_ser.addAssociatedjobsAsync(data.insertedid, obj1));
    }
  }, [code, data]);

  const AddAssociatedjobscode = useSelector((state) => state.AddAssociatedjobs.data);

  useEffect(() => {
    if (AddAssociatedjobscode === 200) {
      dispatch(updateLoader(false));
      navigate(`/ats/layout/jobpostings/associatedapplicants/${location.state.isAssociate.jobId ? location.state.isAssociate.jobId : location.state.isAssociate}`);  /// location.state.isAssociate.jobId
      dispatch(_ser.addAssociatedjobsStartReducer(initalState))
    }
  }, [AddAssociatedjobscode])

  useEffect(()=>{
    console.log("isUpload", isUpload)
    console.log("isLoading", isLoading)
  },[isUpload, isLoading])

  useEffect(() => {
    if (UploadDocCode === 200) {
      navigate(`/ats/layout/applicants/documents/${skipId}`);
      dispatch(_ser.uploadDocumentsStartReducer(initalState))
    }
  }, [UploadDocCode])

  return (
    <>
      {/* {isUpload === true ?
        <div className='applicantsupload'>
          <img src='/assests/img/Group.svg' alt='' className='uploadimgcss' />
          <h3 className='uploadappl'>Applicants / Upload</h3>
          <div className='upload_btn_icon' >
            <Button component="label">
              <CloudUploadIcon />
              <input hidden accept="file/*" multiple type="file" onChange={(e) => UploadImgHandler(e, e.target.files)} />
              <p> Upload</p>
            </Button>
          </div>
          {isLoading === true ? <Loading /> : ''}
        </div> :
        <ApplicantsComponent componentType="Create" input={inputData} iniValue={initialDetails} actions={actionBtns} onValidData={GetValidData} />
      } */}
      
      <ApplicantsComponent componentType="Create" input={inputData} iniValue={initialDetails} actions={actionButtons} onValidData={GetValidData} disableSubmitBtn={disableSubmitBtn} />
      
      {duplicateApplicatsConfirmPopup === true && (
        <ApplicantsManualUploadPopup
            skipId={skipId}  // comes from service id
            isAssociatedApplicant={location.state.associateAnyway}
            updateProfileWithNewResume={inputData}
            sourceId={inputData.resumesourceId}
            defaultFile={applicantFile}
            applicantCode={AllowDuplicateResume.applicantCode}
            setDuplicateApplicatsConfirmPopup={(e) => {setDuplicateApplicatsConfirmPopup(e)}}
          />
      )}

        {twoDuplicateApplicatsConfirmPopup === true && (
          <DuplicateApplicantsManualUploadPopup
            skipId={skipId}
            skipName={skipName}
            setTwoDuplicateApplicatsConfirmPopup={() => {
              setTwoDuplicateApplicatsConfirmPopup(false);
              dispatch(_ser.addApplicantsStartReducer(initalState));
              setSkipId(null);
              setSkipName(null);
              setInputData(initialDetails);
            }}
          />
        )}
    </>
  )
}

export default ApplicantsCreate;

const initialDetails = {
  Applicants: { firstName: '', lastName: '', contactNumber: '', AlternateContactNumber: '', emailId: '' },
  educationDetails: [{ id: '', education: '', startDate: null, endDate: null, location: '' }],
  workExp: [{ id: '', organization: '', jobTitle: '', industry: '', startDate: null, endDate: null, isCurrent: '', location: '', jobDescription: '' }],
  skillDetails: [],
  certification: [],
  customFields:'',
  totalExperience: null,
  id: 0,
  userId: '',
  firstName: '',
  lastName: '',
  identifier: '',
  customerId: 0,
  source: "ATS",
  resumesourceId:'',
  candidateStatus:''
}

const actionBtns = [
  {
    variant: "text",
    style: { color: "red", marginRight: "25px", fontWeight: "bolder" },
    navigateTo: "/ats/layout/applicants/landing",
    label: "Cancel",
    type: "back",
    class: 'buttonHover',
  },
  {
    variant: "contained",
    style: { backgroundColor: "#ffd800", color: "black", borderRadius: "25px", width: "6rem" },
    navigateTo: null,
    label: "Save",
    type: "submit",
    class: 'buttonHover1',
  }
]