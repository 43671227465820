import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import GridLayout from 'react-grid-layout';
import dayjs from "dayjs";
import { Card, CardContent, Typography, Button, IconButton, Grid, TextField, Select, MenuItem, Switch, Menu } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DateRangePicker } from "materialui-daterange-picker";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './ApplicantsNewDashboard.css';
import '../ApplicantsDashboard/applicantsdashboard.css';
import InformationWidgetCard from './InformationWidget';
import * as _appSer from '../../../_redux/_services/ApplicantsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    BarPlot,
    BarElement,
    ChartsTooltip,
    ChartsXAxis,
    ChartsYAxis,
    ResponsiveChartContainer,
} from '@mui/x-charts';
import { PieChart } from '@mui/x-charts/PieChart';
import DynamicTable from '../../../components/DynamicTable/DynamicTable';
import { decrypt } from '../../../_utilities/_encryptDecryptHelper';
import { customFieldsAsync } from "../../../_redux/_services/UserManagerSlice";
import { getCandidateInternalStatus, getCandidateStatusCustomValues } from "../../../_utilities/internalexternalhelper";
import { updateDashboardConfigAsync } from '../../../_redux/_services/UserManagerSlice';
import useUnsavedChangesWarning from '../../../hooks/useUnsavedChangesWarning';

// Initial layout configuration
const initialLayoutConfig = [
    { i: '1', x: 0, y: 0, w: 3, h: 6, type: 'resume', title: 'Total Resume Contributed' },
    { i: '2', x: 3, y: 0, w: 3, h: 6, type: 'submission', title: 'Total Submissions' },
    { i: '3', x: 6, y: 0, w: 3, h: 6, type: 'interview', title: 'Total Profiles in Interview' },
    { i: '4', x: 9, y: 0, w: 3, h: 6, type: 'selected', title: 'Total Profiles Selected' },
    { i: '5', x: 0, y: 7, w: 6, h: 4, type: 'pieChart', title: 'Breakdown of Source of Resumes' },
    { i: '6', x: 0, y: 11, w: 6, h: 4, type: 'barChart', title: 'Total Resumes Uploaded vs Status' },
    { i: '7', x: 6, y: 7, w: 6, h: 8, type: 'requirementsWidget', title: 'List of Requirements Assigned to Recruiters' }
];

const widgetTypes = [
    { type: 'resume', title: 'Total Resume Contributed' },
    { type: 'submission', title: 'Total Submissions' },
    { type: 'interview', title: 'Total Profiles in Interview' },
    { type: 'selected', title: 'Total Profiles Selected' },
    { type: 'pieChart', title: 'Breakdown of Source of Resumes' },
    { type: 'barChart', title: 'Total Resumes Uploaded vs Status' },
    { type: 'requirementsWidget', title: 'List of Requirements Assigned to Recruiters' },
];

// for dynamic Table config Object
const configuration = {
    isSearchbar: false,
    isPaging: false,
    isDropdownActions: false,
    isCheckbox: false,
    isActions: false,
    recordperPage: 10,
    currentPage: 1,
    totalRecords: 0,
    limitData: 20,
    isCheckAlignRight: true,
    isPaginationTop: true,
    isSearchServer: false,
    isPagingServer: false,
    isPageRange: false,
};

// for dynamic Table Headers and columns
const columnsData = [
    {
        id: 1,
        displayName: "Job Code",
        type: "Job Code",
        mappingName: "jobCode",
        theadClass: "dynatableheadtext text-center",
        tbodyClass: "dynatablebodytext text-center ",
        width: "150px",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    },
    {
        id: 2,
        displayName: "Job Title",
        type: "Job Code",
        mappingName: "jobTitle",
        theadClass: "dynatableheadtext text-center ",
        tbodyClass: "dynatablebodytext text-center NameCapitalise",
        width: "300px",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    },
    {
        id: 3,
        displayName: "Location",
        type: "Job Code",
        mappingName: "location",
        theadClass: "dynatableheadtext text-center",
        tbodyClass: "dynatablebodytext text-center NameCapitalise",
        width: "300px",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    },
    {
        id: 4,
        displayName: "Job Status",
        type: "Job Code",
        mappingName: "jobStatus",
        theadClass: "dynatableheadtext text-center",
        tbodyClass: "dynatablebodytext text-center",
        width: "180px",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    },
    {
        id: 5,
        displayName: "Client",
        type: "Job Code",
        mappingName: "clientName",
        theadClass: "dynatableheadtext text-center",
        tbodyClass: "dynatablebodytext text-center",
        width: "250px",
        isEdit: true,
        nagigate: "/ats/layout/jobpostings/view",
    }
];

const valueFormatter = (value) => `${value} uploaded`;

const widgetsMockData = [
    {
        type: 'resume',
        title: 'Total Resume Contributed',
        currentValue: 0,
        currentTitle: 'My Contribution',
        avgValue: 0,
        avgPercentage: 0,
        avgTitle: "Company's Average",
        highestValue: '0',
        highestPercentage: 100,
        highestTitle: 'Top Performer',
        img: `${process.env.PUBLIC_URL + "/assests/TotalContributed.svg"}`
    },
    {
        type: 'submission',
        title: 'Total Submissions',
        currentValue: 0,
        currentTitle: 'My Contribution',
        avgValue: 0,
        avgPercentage: 0,
        avgTitle: "Company's Average",
        highestValue: '0',
        highestPercentage: 100,
        highestTitle: 'Top Performer',
        img: `${process.env.PUBLIC_URL + "/assests/TotalSubmissions.svg"}`
    },
    {
        type: 'interview',
        title: 'Total Profiles in Interview',
        currentValue: 0,
        currentTitle: 'My Contribution',
        avgValue: 0,
        avgPercentage: 0,
        avgTitle: "Company's Average",
        highestValue: '0',
        highestPercentage: 100,
        highestTitle: 'Top Performer',
        img: `${process.env.PUBLIC_URL + "/assests/TotalInterview.svg"}`
    },
    {
        type: 'selected',
        title: 'Total Profiles Selected',
        currentValue: 0,
        currentTitle: 'My Contribution',
        avgValue: 0,
        avgTitle: "Company's Average",
        highestValue: '0',
        avgPercentage: 0,
        highestPercentage: 100,
        highestTitle: 'Top Performer',
        img: `${process.env.PUBLIC_URL + "/assests/TotalSelected.svg"}`
    },
    {
        type: 'pieChart',
        title: 'Breakdown of Source of Resumes',
        data: [],
    },
    {
        type: 'barChart',
        title: 'Total Resumes Uploaded vs Status',
        data: [],
    }
];

const initalState = { data: null, message: '', isloader: true }
// const barChartColors=["#BFBFBF", "#606060", "#37323E", "#6D6A75", "#DFDFDF", "#000000", "#808080", "#404040", "#9F9F9F", "#202020"];
const barChartColors = ["#9c9494", "#8ba3bc", "#9fbb7d", "#dcc882", "#e76f51", "#2a9d8f", "#c88d68", "#6e6a52", "#5c6c74", "#6c5444", "#c9c9c9"];

const BarCustomElement = (props) => {
    const { data, ...other } = props;
    const color = barChartColors[other.ownerState.dataIndex];
    return <BarElement
        {...other}
        style={{
            ...props.style,
            fill: color
        }}
        id={other.ownerState.id}
        dataIndex={other.ownerState.dataIndex}
    />
}

const renderSwitch = (type, breakDownSourceStatus, setBreakDownSourceStatus, resumeUploadStatus, setResumeUploadStatus, requirementsAssigned, setRequirementsAssigned, fetchRequirementAssigned) => {
    switch (type) {
        case 'pieChart':
            return (
                <div className="switch-container">
                    <span className="text">Individual</span>
                    <Switch
                        checked={breakDownSourceStatus}
                        name="pieChartIndividualORTeam"
                        onChange={() => setBreakDownSourceStatus(!breakDownSourceStatus)}
                    />
                    <span className="text">Team</span>
                </div>
            );
        case 'barChart':
            return (
                <div className="switch-container">
                    <span className="text">Individual</span>
                    <Switch
                        checked={resumeUploadStatus}
                        name="barChartIndividualORTeam"
                        onChange={() => setResumeUploadStatus(!resumeUploadStatus)}
                    />
                    <span className="text">Team</span>
                </div>
            );
        case 'requirementsWidget':
            return (
                <div className="switch-container">
                    <span className="text">Individual</span>
                    <Switch
                        checked={requirementsAssigned}
                        name="tableDataIndividualORTeam"
                        onChange={() => setRequirementsAssigned(!requirementsAssigned)}
                    />
                    <span className="text">Team</span>
                    {/* <CachedIcon style={{ color: "#757575", marginRight: "10px", cursor: "pointer" }} onClick={fetchRequirementAssigned} /> */}
                </div>
            );
        default:
            return null;
    }
};

const renderCardContent = (card, widgetsData, users, editingCard, selectedCardType, setSelectedCardType, handleSaveCard, columnsData, configuration) => {
    if (editingCard === card.i) {
        return (
            <div className='dashboard__select-card-type-section'>
                <Select
                    value={selectedCardType || card.type}
                    onChange={(e) => setSelectedCardType(e.target.value)}
                    fullWidth
                >
                    {widgetTypes.map((widget) => (
                        <MenuItem key={widget.type} value={widget.type}>
                            {widget.title}
                        </MenuItem>
                    ))}
                </Select>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '10px' }}
                    className='save-btn dashboard__toolbar-button'
                    onClick={() => handleSaveCard(card.i, selectedCardType)}
                >
                    Save
                </Button>
            </div>
        );
    }

    const widgetData = widgetsData.find(widget => widget.type === card.type);

    switch (card.type) {
        case 'pieChart':
            return widgetData?.data.length > 0 ? (
                <PieChart
                    height={310}
                    colors={["#9c9494", "#8ba3bc", "#9fbb7d", "#dcc882", "#e76f51", "#2a9d8f", "#c88d68", "#6e6a52", "#5c6c74", "#6c5444", "#c9c9c9"]}
                    series={[
                        {
                            data: widgetData?.data || [],
                            innerRadius: 2.5,
                            outerRadius: 150,
                            paddingAngle: 0.5,
                            cornerRadius: 2,
                            startAngle: -180,
                            endAngle: 180,
                            cx: 150,
                            cy: 150,
                        }
                    ]}
                />
            ) : (
                <div className='dashboard__card-no-data-container'>No data available</div>
            );

        case 'barChart':
            return widgetData?.data[0]?.length > 0 ? (
                <ResponsiveChartContainer
                    series={[
                        { type: 'bar', data: widgetData?.data[0] || [] },
                    ]}
                    xAxis={[{ scaleType: 'band', data: widgetData?.data[1] || [] }]}
                    height={300}
                >
                    <BarPlot
                        skipAnimation
                        slots={{ bar: BarCustomElement }}
                        slotProps={{
                            bar: { data: widgetData?.data[0] || [] }
                        }}
                    />
                    <ChartsXAxis />
                    <ChartsYAxis position="left" />
                    <ChartsTooltip />
                </ResponsiveChartContainer>
            ) : (
                <Typography className='no-data-container'>No data available</Typography>
            );

        case 'requirementsWidget':
            return users && users.length > 0 ? (
                <DynamicTable
                    config={configuration}
                    data={users}
                    colmns={columnsData}
                    actionDropdown={[]}
                    actions={[]}
                    filterValue={''}
                    noDataText={"No Postings found"}
                    addModel={{}}
                    searchKeyWord=""
                />
            ) : (
                <div className="no-data-container"><label>No data available</label></div>
            );

        default:
            return <InformationWidgetCard data={widgetData} />;
    }
};
const ApplicantsNewDashboard = () => {
    const [layoutConfig, setLayoutConfig] = useState(() => {
        const dashboardConfig = JSON.parse(decrypt('DashboardConfig')); // Get dashboardConfig from local storage
        return dashboardConfig ? dashboardConfig : initialLayoutConfig; // Use config if available, otherwise default
    });
    const [gridWidth, setGridWidth] = useState(1200);
    const [editMode, setEditMode] = useState(false);
    const [widgetsData, setWidgetsData] = useState(widgetsMockData);
    const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').toDate());
    const [endDate, setEndDate] = useState(new Date());
    const [open, setOpen] = useState(false);
    const [dateRange, setDateRange] = useState({ startDate: dayjs().subtract(7, 'day').toDate(), endDate: new Date() });
    const widgetsResponse = useSelector((state) => state.GetResumeCount.data);
    const pieChartResponse = useSelector((state) => state.GetPieChartData.data);
    const barChartResponse = useSelector((state) => state.GetBarChartData.data);
    const customFieldsData = useSelector(state => state.CustomFields.data);
    const responseData = useSelector((state) => state.GetRequirementAssigned.data);
    const [editingCard, setEditingCard] = useState(null);
    const [breakDownSourceStatus, setBreakDownSourceStatus] = useState(false);
    const [resumeUploadStatus, setResumeUploadStatus] = useState(false);
    const [requirementsAssigned, setRequirementsAssigned] = useState(false);
    const [users, setUsers] = useState([]);
    const [GetUserId, setGetUserId] = useState(null);
    const [getAttributes, setGetAttributes] = useState(null);
    const [selectCountryId, setselectCountryId] = useState(null);
    const [selectedCardType, setSelectedCardType] = useState(null);
    const [editingTitleId, setEditingTitleId] = useState(null); // State to track which card title is being edited
    const [tempTitle, setTempTitle] = useState(''); // Temporary title during editing
    const [menuAnchorEls, setMenuAnchorEls] = useState({});
    const [candidateStatusValues, setCandidateStatusValues] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const containerRef = useRef(null);
    const newCardRef = useRef(null);

    const dispatch = useDispatch();

    useUnsavedChangesWarning(unsavedChanges, 'You have unsaved changes. Are you sure you want to leave?');

    useEffect(() => {
        const updateGridWidth = () => {
            if (containerRef.current) {
                const containerPadding = 40; // 20px padding on each side
                setGridWidth(containerRef.current.offsetWidth - containerPadding);
            }
        };

        updateGridWidth();
        window.addEventListener('resize', updateGridWidth);

        return () => {
            window.removeEventListener('resize', updateGridWidth);
        };
    }, []);

    useEffect(() => {
        if (GetUserId && selectCountryId && getAttributes) {
            fetchRequirementAssigned()
            // onPieChartHandler(dateForPieChart);
            // onBarChartHandler(dateForBarChart);
        } else {
            setGetUserId(JSON.parse(decrypt('GetUserId')));
            setselectCountryId(JSON.parse(decrypt('selectCountryId')))
            setGetAttributes(JSON.parse(localStorage.getItem('getAttributes')))
        }
    }, [GetUserId, selectCountryId, getAttributes]);

    useEffect(() => {
        if (responseData !== null && responseData !== undefined) {
            setUsers([]);
            if (responseData) {
                let myData = requirementsAssigned ? JSON.parse(JSON.stringify(responseData?.companyData)) : JSON.parse(JSON.stringify(responseData?.userData)) ;
                
                // dispatch(_appSer.getRequirementAssignedStartReducer(initalState));
                setUsers(myData);
            }
        }
        else if (responseData !== null && responseData !== undefined && responseData.data.length === 0) {
            setUsers('null');
        }
    }, [responseData, requirementsAssigned])

    const fetchRequirementAssigned = () => {
        setUsers([]);
        let data = {
            limit: 10,
            offSet: 1,
            userId: getAttributes[0].userId
        }
        dispatch(_appSer.getRequirementAssignedAsync(data));
    }

    useEffect(() => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 100);
    }, []);
  
    useEffect(() => {
        const getAttributes = JSON.parse(localStorage.getItem('getAttributes'));
        if (getAttributes) {
            const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
            const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
            const payload = {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                userId: getAttributes[0].userId
            }
            dispatch(_appSer.getResumeCountAsync(payload));
            dispatch(_appSer.getPieChartDataAsync(payload));
            dispatch(_appSer.getBarChartDataAsync(payload));
        }
    }, []);

    useEffect(() => {
        let userId = JSON.parse(decrypt('GetUserId'));
        dispatch(customFieldsAsync(userId));
    }, [])

    useEffect(() => {
        if (customFieldsData && customFieldsData.length > 0) {
            const array = getCandidateStatusCustomValues(customFieldsData);
            setCandidateStatusValues(array);
        }
    }, [customFieldsData])

    useEffect(() => {
        if (widgetsResponse) {
            const updatedWidgetsData = widgetsData.map(widget => {
                const responseKey = `${widget.type}Data`;
                const responseData = widgetsResponse?.[responseKey];
                if (responseData) {
                    let updatedWidget = { ...widget };
                    switch (widget.type) {
                        case 'resume':
                            updatedWidget.currentValue = responseData?.resumesForCurrentUser ?? widget.currentValue;
                            updatedWidget.avgValue = responseData?.averageResumes?.toFixed(0) ?? widget.avgValue;
                            updatedWidget.highestValue = responseData?.highestResumes?.toFixed(0) ?? widget.highestValue;
                            updatedWidget.avgPercentage = updatedWidget.highestValue > 0
                                ? (updatedWidget.avgValue / updatedWidget.highestValue) * 100
                                : 0;
                            break;
                        case 'submission':
                            updatedWidget.currentValue = responseData?.submissionsForCurrentUser ?? widget.currentValue;
                            updatedWidget.avgValue = responseData?.avgSubmissions?.toFixed(0) ?? widget.avgValue;
                            updatedWidget.highestValue = responseData?.highestSubmissions?.toFixed(0) ?? widget.highestValue;
                            updatedWidget.avgPercentage = updatedWidget.highestValue > 0
                                ? (updatedWidget.avgValue / updatedWidget.highestValue) * 100
                                : 0;
                            break;
                        case 'interview':
                            updatedWidget.currentValue = responseData?.submissionsinInterviewForCurrentUser ?? widget.currentValue;
                            updatedWidget.avgValue = responseData?.averageSubmissionsinInterview?.toFixed(0) ?? widget.avgValue;
                            updatedWidget.highestValue = responseData?.highestSubmissionsinInterview?.toFixed(0) ?? widget.highestValue;
                            updatedWidget.avgPercentage = updatedWidget.highestValue > 0
                                ? (updatedWidget.avgValue / updatedWidget.highestValue) * 100
                                : 0;
                            break;
                        case 'selected':
                            updatedWidget.currentValue = responseData?.selectedForCurrentUser ?? widget.currentValue;
                            updatedWidget.avgValue = responseData?.averageSelected?.toFixed(0) ?? widget.avgValue;
                            updatedWidget.highestValue = responseData?.highestSelected?.toFixed(0) ?? widget.highestValue;
                            updatedWidget.avgPercentage = updatedWidget.highestValue > 0
                                ? (updatedWidget.avgValue / updatedWidget.highestValue) * 100
                                : 0;
                            break;
                        default:
                            break;
                    }
                    return updatedWidget;
                }
                return widget;
            });
            setWidgetsData(updatedWidgetsData);
        }
    }, [widgetsResponse]);


    useLayoutEffect(() => {
        if (pieChartResponse) {
            const pieChartData = breakDownSourceStatus ? pieChartResponse.companyData : pieChartResponse.userData;
            const newArray = pieChartData.map(list => ({
                value: list.resumecount,
                label: list.resumesource_name
            }));

            setWidgetsData(prevWidgets =>
                prevWidgets.map(widget => widget.type === 'pieChart' ? { ...widget, data: newArray } : widget)
            );

            // Update layout based on data availability
            setLayoutConfig(prevLayout =>
                prevLayout.map(card => {
                    if (card.type === 'pieChart') {
                        return { ...card, h: newArray.length > 0 ? 7 : 3 };  // Adjust height dynamically
                    } else if (card.type === 'barChart') {
                        return card; // Keep bar chart height as is
                    } else if (card.type === 'requirementsWidget') {
                        // Calculate the combined height for pieChart and barChart
                        const pieHeight = newArray.length > 0 ? 7 : 3;
                        const barHeight = prevLayout.find(c => c.type === 'barChart')?.h || 7;
                        return { ...card, h: pieHeight + barHeight };  // Dynamic height for requirements widget
                    }
                    return card;
                })
            );
        }
    }, [pieChartResponse, breakDownSourceStatus]);


    useLayoutEffect(() => {
        if (barChartResponse && candidateStatusValues.length) {
            const barChartData = resumeUploadStatus ? barChartResponse.companyData : barChartResponse.userData;
            const barData = barChartData.map(list => list.statusCount);
            const barLabel = barChartData.map(list => getCandidateInternalStatus(list.candidateStatus, candidateStatusValues));

            setWidgetsData(prevWidgets =>
                prevWidgets.map(widget => widget.type === 'barChart' ? { ...widget, data: [barData, barLabel] } : widget)
            );

            // Update layout based on data availability
            setLayoutConfig(prevLayout =>
                prevLayout.map(card => {
                    if (card.type === 'barChart') {
                        return { ...card, h: barData.length > 0 ? 7 : 3 };  // Adjust height dynamically
                    } else if (card.type === 'pieChart') {
                        return card;  // No change in pie chart height
                    } else if (card.type === 'requirementsWidget') {
                        // Adjust height to accommodate both pieChart and barChart heights
                        const barHeight = barData.length > 0 ? 7 : 3;
                        const pieHeight = prevLayout.find(c => c.type === 'pieChart')?.h || 7;
                        return { ...card, h: pieHeight + barHeight };  // Dynamic height for requirements widget
                    }
                    return card;
                })
            );
        }
    }, [barChartResponse, candidateStatusValues, resumeUploadStatus]);


    useEffect(() => {
        if (editingCard) {
            const element = document.querySelector(`[data-grid-id='${editingCard}']`);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth', // Smooth scroll effect
                    block: 'end',    // Scroll to the center vertically
                    inline: 'nearest'   // Keep it in the nearest horizontal alignment
                });
                newCardRef.current = element;
            }
        }
    }, [editingCard]);

    const handleDateChange = (range) => {
        setStartDate(range.startDate);
        setEndDate(range.endDate);
        setDateRange(range);
        setOpen(false);
    };

    const toggle = () => setOpen(!open);

    const onLayoutChange = (newLayout) => {
        const updatedLayout = newLayout.map((item, index) => ({
            ...item,
            type: layoutConfig[index].type,
            title: layoutConfig[index].title,
        }));

        setLayoutConfig(updatedLayout);
    };

    const onLoadWidgetHandler = () => {
        const formatStartDate = dayjs(startDate).format("YYYY-MM-DD");
        const formatEndDate = dayjs(endDate).format("YYYY-MM-DD");
        const getAttributes = JSON.parse(localStorage.getItem('getAttributes'));
        if (getAttributes) {
            const payload = {
                startDate: formatStartDate,
                endDate: formatEndDate,
                userId: getAttributes[0].userId
            };
            dispatch(_appSer.getResumeCountAsync(payload));
            dispatch(_appSer.getPieChartDataAsync(payload));
            dispatch(_appSer.getBarChartDataAsync(payload));
            fetchRequirementAssigned()
        }
    };

    const handleDeleteCard = (id) => {
        setLayoutConfig(prevLayout => prevLayout.filter(card => card.i !== id));
    };
    const handleEditCard = (id) => {
        if (editingCard === id) {
            setEditingCard(null); // Toggle off editing mode
            setSelectedCardType(null); // Clear the selected type
        } else {
            setEditingCard(id); // Enter editing mode for the clicked card
            const currentCard = layoutConfig.find(card => card.i === id);
            setSelectedCardType(currentCard?.type || ''); // Set current card type for editing
        }
    };

    const handleSaveCard = (id, newType) => {
        if (!newType) return; // Prevent saving without selecting a type

        const selectedWidget = widgetTypes.find(widget => widget.type === newType);
        if (!selectedWidget) return; // Prevent saving if the widget type is not found

        setLayoutConfig(prevLayout => prevLayout.map(card => {
            if (card.i === id) {
                return { ...card, type: newType, title: selectedWidget.title };
            }
            return card;
        }));
        setEditingCard(null);
        setSelectedCardType(null); // Clear the selected type after saving
        setUnsavedChanges(true); // Set unsaved changes
    };

    const handleAddCard = () => {
        // Find the highest key from the layout to avoid duplicates
        const maxKey = layoutConfig.reduce((max, card) => Math.max(max, parseInt(card.i, 10)), 0);

        // Find the first available position in the grid (empty slot)
        let newCardX = 0;
        let newCardY = 0;
        let foundSlot = false;

        // Traverse the grid to find the first empty slot
        while (!foundSlot) {
            foundSlot = true;
            // Check if the new slot (newCardX, newCardY) is occupied
            for (const card of layoutConfig) {
                const isOverlap = card.x < newCardX + 3 &&
                    newCardX < card.x + card.w &&
                    card.y < newCardY + 6 && // Adjust based on card height
                    newCardY < card.y + card.h;

                if (isOverlap) {
                    // If overlap, move to next column
                    newCardX += card.w;
                    if (newCardX >= 12) {
                        // Move to next row if no more columns are available
                        newCardX = 0;
                        newCardY++;
                    }
                    foundSlot = false;
                    break;
                }
            }
        }

        // Add a new card with a unique key
        const newId = (maxKey + 1).toString(); // Ensure the new ID is unique
        const newCard = { i: newId, x: newCardX, y: newCardY, w: 3, h: 6, type: '', title: 'New Widget' };
        setLayoutConfig((prevLayout) => [...prevLayout, newCard]);
        setEditingCard(newId); // Set the new card to be in editing mode
        setUnsavedChanges(true); // Set unsaved changes
    };

    useEffect(() => {
        // Bug fix: Remove any newly added card that has not been assigned a type after editing mode is disabled.
        if (!editMode) {
            setLayoutConfig(prevLayout => prevLayout.filter(card => card.type !== ''));
        }
    }, [editMode]);

    const handleSaveConfig = () => {
        setEditMode(false);
        const payload = {
            userId: getAttributes[0].userId,
            dashboardConfig: JSON.stringify(layoutConfig),
        };

        // Dispatch the async action to save the updated configuration
        dispatch(updateDashboardConfigAsync(payload));
        setUnsavedChanges(false); // Mark changes as saved

    };

    const handleEditCardTitle = (id, currentTitle) => {
        setEditingTitleId(id); // Set title editing mode for the specified card
        setTempTitle(currentTitle); // Set the current title in tempTitle
    };


    // Handle saving title
    const handleSaveCardTitle = (id) => {
        setLayoutConfig(prevLayout =>
            prevLayout.map(card => {
                if (card.i === id) {
                    return { ...card, title: tempTitle }; // Save the new title
                }
                return card;
            })
        );
        setEditingTitleId(null); // Exit title edit mode
        setTempTitle(''); // Clear the temporary title
    };


    const handleTitleChange = (newTitle) => {
        setTempTitle(newTitle); // Update the temp title as user types
    };

    const handleMenuClick = (event, cardId) => {
        setMenuAnchorEls((prevState) => ({
            ...prevState,
            [cardId]: event.currentTarget,
        }));
    };
    const handleMenuClose = (cardId) => {
        setMenuAnchorEls((prevState) => ({
            ...prevState,
            [cardId]: null,
        }));
    };
    const isMenuOpen = (cardId) => Boolean(menuAnchorEls[cardId]);
    const isAddCardDisabled = layoutConfig.some(card => card.type === '');

    return (
        <div ref={containerRef} className="dashboard">
            <div className='date-time-toolbar-container'>
                <div className="dashbaord__date-time-container">
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <div className="educationTermRangePicker">
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    autoComplete="off"
                                    label="Start Date"
                                    placeholder="Start Date"
                                    style={{ width: "50%" }}
                                    name="startdate"
                                    value={startDate.toLocaleDateString()}
                                    onClick={() => setOpen(true)}
                                    InputLabelProps={{
                                        style: {
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    autoComplete="off"
                                    label="End Date"
                                    placeholder="End Date"
                                    style={{ width: "50%" }}
                                    name="enddate"
                                    value={endDate.toLocaleDateString()}
                                    onClick={() => setOpen(true)}
                                    InputLabelProps={{
                                        style: {
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            </div>
                            <DateRangePicker
                                open={open}
                                toggle={toggle}
                                maxDate={new Date()}
                                onChange={handleDateChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                className="onHover"
                                style={{
                                    backgroundColor: "#FFD800",
                                    color: "#3B4046",
                                    borderRadius: "10px",
                                    textTransform: "capitalize",
                                    width: "92px",
                                    font: "normal normal normal 0.875rem Roboto",
                                    height: "47px",
                                    fontWeight: "600",
                                    fontSize:"14px",
                                }}
                                onClick={onLoadWidgetHandler}
                            >
                                Reload
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div className="dashboard__toolbar">
                    {editMode && (
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', }} >
                            <Button
                                onClick={handleAddCard}
                                disabled={isAddCardDisabled}
                                style={{
                                    marginRight: '15px',
                                    padding: 0,
                                }}
                            >
                                <AddIcon className='add-icon' />  <span className='add-text'>Add</span>
                            </Button>

                        </div>
                    )}
                    <Button
                        onClick={() => (editMode ? handleSaveConfig() : setEditMode(true))}
                        className="dashboard__toolbar-button"
                        variant="contained"
                    >
                        {editMode ? 'Save' : 'Edit'}
                    </Button>

                </div>
            </div>
            <GridLayout
                className="dashboard__grid layout"
                layout={layoutConfig}
                cols={12}
                rowHeight={50}
                width={gridWidth}
                isResizable={editMode}
                isDraggable={editMode}
                onLayoutChange={onLayoutChange}
                margin={[20, 20]}
                draggableCancel=".dashboard__card-icons, .save-btn, .dashboard__card-edit-icon, .dashboard__card-header-section, .switch-container"

            >
                {layoutConfig.map((card) => {
                    return (
                        <div key={card.i} data-grid-id={card.i} data-grid={card} className={card.type === 'requirementsWidget' ? "Requirementwidget dashboard__grid-item" : 'dashboard__grid-item'}>
                            <Card className="dashboard__card">
                                <div className="dashboard__card-row dashboard__card-header-section">
                                    {editingTitleId === card.i ? (
                                        // Show input field for editing card title when editing is active
                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '10px' }}>
                                            <TextField
                                                value={tempTitle} // Use tempTitle during editing
                                                onChange={(e) => handleTitleChange(e.target.value)} // Update tempTitle on input change
                                                fullWidth
                                                label="Edit Card Title"
                                                size='small'
                                            />
                                            <Button
                                                variant="contained"
                                                className=" dashboard__toolbar-button--small"
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => handleSaveCardTitle(card.i)} // Save the card title
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    ) : (
                                        // Display the card title and the edit icon
                                        <div style={{ display: 'flex', alignItems: 'center', width: '75%' }}>
                                            <Typography className="dashboard__card-header" variant="subtitle1">
                                                {card.title}
                                            </Typography>

                                        </div>
                                    )}

                                    {editMode && (
                                        <div>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls={`card-menu-${card.i}`}
                                                aria-haspopup="true"
                                                className='dashboard__card-icon'
                                                onClick={(event) => handleMenuClick(event, card.i)} // Pass the card id
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id={`card-menu-${card.i}`}
                                                anchorEl={menuAnchorEls[card.i]} // Use the card-specific anchor element
                                                open={isMenuOpen(card.i)} // Check if the menu is open for this specific card
                                                onClose={() => handleMenuClose(card.i)} // Close the specific card's menu
                                            >
                                                <MenuItem className='dashboard__card-icons' onClick={() => handleEditCardTitle(card.i, card.title)}>
                                                    <EditIcon className='dashboard__card-icon' fontSize="small" /> Edit Card Title
                                                </MenuItem>
                                                <MenuItem className='dashboard__card-icons' onClick={() => handleEditCard(card.i)}>
                                                    <SettingsIcon className='dashboard__card-icon' fontSize="small" /> Configure Card
                                                </MenuItem>
                                                <MenuItem className='dashboard__card-icons' onClick={() => handleDeleteCard(card.i)}>
                                                    <DeleteIcon className='dashboard__card-icon' fontSize="small" /> Delete Card
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    )}
                                </div>
                                <CardContent style={{ position: 'relative' }}>
                                    <div style={{ textAlign: 'right' }}>
                                        {renderSwitch(card.type, breakDownSourceStatus, setBreakDownSourceStatus, resumeUploadStatus, setResumeUploadStatus,requirementsAssigned, setRequirementsAssigned, fetchRequirementAssigned)}
                                    </div>
                                    {renderCardContent(card, widgetsData, users, editingCard, selectedCardType, setSelectedCardType, handleSaveCard, columnsData, configuration)}
                                </CardContent>
                            </Card>
                        </div>
                    )
                })}
            </GridLayout>
        </div>
    );
};

export default ApplicantsNewDashboard;