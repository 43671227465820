///// JOBPOSTINGS /////
export const DELETEJOB_SUCCESS_MSG = "Job deleted successfully";
export const DELETEJOB_ERROR_MSG = "Delete job - Failed, please try again";

export const ADDNEWJOBPOSTING_SUCCESS_MSG = "Job posted Successfully";                          // not using
export const ADDNEWJOBPOSTING_ERROR_MSG = "Add Job - Failed, please try again";                 // not using

export const UPDATEJOBPOSTING_SUCCESS_MSG = "Job details updated successfully";
export const UPDATEJOBPOSTING_ERROR_MSG = "Update job posting - Failed, please try again";

export const ASSOCIATEFINDAPPLICANTADD_SUCCESS_MSG = "Associated applicant successfully";
export const ASSOCIATE_APPLICANT_SUCCESS_MSG = "Associated applicant successfully";

export const ASSOCIATEAPPLICANTADD_SUCCESS_MSG = "Associate Applicant - Successful";                 // not using
export const ASSOCIATEAPPLICANTADD_ERROR_MSG = "Associate Applicant - Failed, please try again";     // not using

export const ASSOCIATEAPPLICANTREMOVE_SUCCESS_MSG = "Associated applicant deleted successfully";
export const ASSOCIATEAPPLICANTREMOVE_ERROR_MSG = "Remove Applicant - Failed, please try again";     // not using
export const NOASSOCIATEDAPPLICANTMSG = "No applicant associated to current job";                    // not using

export const ASSOCIATEAPPLICANTDESELECT_SUCCESS_MSG = "Associated applicant deselected successfully";

///// Applicants /////
export const DELETEAPPLICANT_SUCCESS_MSG = "Applicant deleted successfully";
export const DELETEAPPLICANT_ERROR_MSG = "Delete Applicant - Failed, please try again";

export const ADDNEWAPPLICANT_SUCCESS_MSG = "Applicant added successfully";
export const ADDNEWAPPLICANT_ERROR_MSG = "Add Applicant - Failed, please try again";

export const UPDATEAPPLICANT_SUCCESS_MSG = "Applicant details updated successfully";
export const UPDATEAPPLICANT_ERROR_MSG = "Update Applicant - Failed, please try again";

export const ASSOCIATEJOB_SUCCESS_MSG = "Associated jobs added successfully";
export const ASSOCIATEJOB_ERROR_MSG = "Associate Job - Failed, please try again";                        // not using

export const ASSOCIATEJOBREMOVE_SUCCESS_MSG = "Associated jobs deleted successfully";
export const ASSOCIATEJOBREMOVE_ERROR_MSG = "Associated jobs delete - Failed, please try again";
export const NOASSOCIATEDJOBMSG = "No Job associated to current Applicant";

export const ASSOCIATEJOBDESELECT_SUCCESS_MSG = "Associated jobs deselected successfully";
export const ASSOCIATEJOBDESELECT_ERROR_MSG = "Associated jobs deselect - Failed, please try again";      // not using


///// clients /////
export const DELETECLIENT_SUCCESS_MSG = "Client deleted successfully";
export const DELETECLIENT_ERROR_MSG = "Delete client - Failed, please try again";

export const ADDNEWCLIENT_SUCCESS_MSG = "Client added successfully";
export const ADDNEWCLIENT_ERROR_MSG = "Add client - Failed, please try again";                          // not using

export const UPDATECLIENT_SUCCESS_MSG = "Client updated successfully";
export const UPDATECLIENT_ERROR_MSG = "Update client - Failed, please try again";


///// common /////
export const ADDNOTE_SUCCESS_MSG = "Note added successfully";
export const ADDNOTE_ERROR_MSG = "Add note - Failed, please try again";

export const EDITNOTE_SUCCESS_MSG = "Note updated successfully";
export const EDITNOTE_ERROR_MSG = "Update note - Failed, please try again";

export const UPLOADDOCUMENT_SUCCESS_MSG = "Document added successfully";
export const UPLOADDOCUMENT_ERROR_MSG = "Document upload - Failed, please try again";

export const DOWNLOADDOCUMENT_SUCCESS_MSG = "Document downloaded successfully";
export const DOWNLOADDOCUMENT_ERROR_MSG = "Document download - Failed, please try again";

export const DELETEDOCUMENT_SUCCESS_MSG = "Document deleted successfully";
export const DELETEDOCUMENT_ERROR_MSG = "Delete document - Failed, please try again";


///// OTP /////
export const LOGINEMAIL_SUCCESS_MSG = "OTP Sent - Successful";                            // not using




///LOGIN///////
export const LOGINEMAILPASSWORD_ERROR_MSG = "Invalid User - Successful";                 // not using
