import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, FormControl, Grid, MenuItem, Select, Switch, TextField, Typography, InputLabel } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { updateSnackbar } from "../../../_redux/SnackbarSlice";
import "./jobboardportal.css";

const label = { inputProps: { "aria-label": "Switch demo" } };
const initialSnackbar = { type: "", message: "" };


const JobboardPortalComponent = ({ actions, componentType, disableSubmitBtn, initial, data, updateHandler, handleSubmit }) => {

  const [disableBtn, setDisableBtn] = useState(disableSubmitBtn);
  const [portalData, setPortalData] = useState(initial);
  const [imgList, setImgList] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [preview, setPreview] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setPortalData(data);
      setImgList(data.preview);
    }
  }, [data])

  useEffect(() => {
    setDisableBtn(disableSubmitBtn);
  }, [disableSubmitBtn])

  useEffect(() => {
    if (initial) {
      setPortalData(initial);
    }
  }, [initial])


  const onChangePlatformHandler = (e) => {
    const { name, checked } = e.target;
    let modifyData = JSON.parse(JSON.stringify(portalData));
    modifyData[name] = checked;
    updateHandler(modifyData);
  }

  const fileImgHandler = (event, i) => {
    let files = event.target.files;
    if (files[0]) {
      var fileInput = document.getElementById('fileId');
      var filePath = fileInput.value;
      var allowedExtensions = /(\.png|\.jpg|\.tiff|\.jpeg|\.JPG)$/i;
      if (!allowedExtensions.exec(filePath)) {
        dispatch(updateSnackbar({ type: 'error', message: "Invalid file format. Please upload a png, jpg, or tiff file" }));
        fileInput.value = '';
      }
      else if (files.length > 0) {
        if (files.item(i).size > 1e+6) {
          dispatch(updateSnackbar({ type: 'error', message: "Please upload a file smaller than 1 MB" }));
        } else {
          const objectUrl = URL.createObjectURL(files[0])
          setImgList(files[0].name);
          setPreview(objectUrl);
          setImgData(files[0]);
        }
      }
    }
  };



  // if(this.files[0].size > 1000 ) {
  //   alert("File is too big!");
  //   this.value = "";}

  // const onRemoveItem = (item) => {
  //   setImgList(null);
  //   setPreview(null);
  //   setImgData(null);
  // };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let modifyData = JSON.parse(JSON.stringify(portalData));
    if (name === "Primary Color") {
      modifyData.primaryData[0].value = value;
    } else if (name === "Secondary Color") {
      modifyData.primaryData[1].value = value;
    } else if (name === "mainheading") {
      modifyData.typography[0].fontName = value;
    } else if (name === "bodyheading") {
      modifyData.typography[1].fontName = value;
    } else if (name === "mainheadingColor") {
      modifyData.typography[0].color = value;
    } else if (name === "bodyheadingColor") {
      modifyData.typography[1].color = value;
    } else if (name === "url") {
      modifyData.url = value;
    }
    updateHandler(modifyData);
  }

  const onSubmitHandler = () => {
    let modifyData = JSON.parse(JSON.stringify(portalData));
    handleSubmit(modifyData, imgData);
  }

  const onCopyUrlHandler = () => {
    dispatch(updateSnackbar(initialSnackbar));
    var copyText = document.getElementById("generateURL");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    dispatch(updateSnackbar({ type: "success", message: "URL Copied!" }));
  }

  const onRemoveItem = () => {
    setPreview(null)
    let modifyData = JSON.parse(JSON.stringify(portalData));
    modifyData.logo = '';
    modifyData.preview = null;
    setImgList(null);
    updateHandler(modifyData);
    setPortalData(modifyData)
    setImgData(null)
  }

  return (
    <React.Fragment>
      {componentType !== "Create" ? (
        <div className="actionBtnRtl" style={{ marginTop: "-82px" }}>
          {actions &&
            actions.map((item, i) => {
              if (item.type === "back") {
                return (
                  <Button
                    key={i}
                    variant={item.variant}
                    className={item.class}
                    style={item.style}
                    size="small"
                    onClick={() => navigate(item.navigateTo)}
                  >
                    {componentType !== "View" ? <Link href="#" style={{color:"red", fontSize:"14px"}} underline="always">  {item.label}{" "} </Link> : <>{item.label}{" "}</>}
                  </Button>
                );
              } else if (item.type === "submit") {
                return (
                  <Button
                    key={i}
                    variant={item.variant}
                    className={item.class}
                    disabled={disableBtn}
                    style={item.style}
                    size="small"
                    onClick={onSubmitHandler}
                  >
                    {item.label}{" "}
                  </Button>
                );
              }
            })}
        </div>
      ) : ("")}
      <div className='jobBoardPortalBody'>
      <p className="font-weight-bold1">
        Enable Job Board Portal
        <span id="jobBoardSwitch" style={{ marginLeft: "110px" }}>
          <Switch
            {...label}
            checked={portalData.isJobPortal}
            name="isJobPortal"
            disabled={componentType !== "View" ? false : true}
            onChange={(e) => onChangePlatformHandler(e)}
          />
        </span>
      </p>
      {Boolean(portalData.isJobPortal) && (
        <>
          <p className="font-weight-bold1">
            Brand Logo
          </p>
          <div id="jobBoardportal">
            <Grid container gap={5}>
              {componentType !== "View" && (
                <Grid item md={5}>
                  <Button
                    variant="contained"
                    component="label"
                    className="jobBoardportaluploadresume"
                  >
                    <span>
                      <b style={{ color: "#FFD800" }} className="uploadtext">Upload </b>
                      <b style={{ color: "#9E9E9E" }} className="uploadtext">browse file to upload</b></span>
                    <input
                      hidden
                      accept="file/*"
                      type="file"
                      id="fileId"
                      onChange={(e) => fileImgHandler(e)} />
                  </Button>
                  <small className="docsupporttext">Supported file formats: png, jpg, jpeg, tiff | upto 1 mb</small>
                  {imgList !== null && (<ul className="filelistupload">
                    <li>
                      <p>{imgList}</p>
                      <span
                        className="closeImgbtn"
                        onClick={onRemoveItem}
                      >
                        <DeleteOutlineIcon />
                      </span>
                    </li>
                  </ul>)}
                </Grid>
              )}
              <Grid item md={6} style={{marginTop: "20px"}}>
                <div className="imgSection">
                  <img src={preview !== null ? preview : portalData.logo} width="100%" />
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container gap={10}>
            <Grid item md={5}>
              {portalData.primaryData.map(list => (
                <Grid container gap={5} alignItems="center" className="primarySection">
                  <Grid item md={3}>
                    <p className="font-weight-bold1">{list.title}</p>
                  </Grid>
                  <Grid item md={7}>
                    <input type="color" name={list.title} value={list.value} disabled={componentType !== "View" ? false : true}
                      onChange={onChangeHandler} />
                  </Grid>
                </Grid>
              ))}
              <p className="font-weight-bold1" style={{ marginTop: "30px" }}>
                Typography
              </p>
              {portalData.typography.map(list => (
                <Grid container gap={2.5} alignItems="center" className="typographySection">
                  <Grid item md={3}>
                    <p style={{ width: "120px" }}  className="font-weight-bold1">{list.title}</p>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="jobboard" className={componentType !== "View" ? "boldSelectlabelCreate" : "boldSelectlabel"} shrink={true}>Font Name&nbsp;</InputLabel>
                      <Select
                        name={list.name}
                        size="small"
                        autoComplete="off"
                        labelId={list.name}
                        id={list.name}
                        value={list.fontName}
                        onChange={onChangeHandler}
                        disabled={componentType !== "View" ? false : true}
                      >
                        {defaultFontsList.map(list => (
                          <MenuItem className="selectOptions" key={list.id} value={list.name}>{list.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <label className='textcolorlabel'>Text Color</label>
                    <input type="color" value={list.color} name={list.name + "Color"}
                      disabled={componentType !== "View" ? false : true}
                      onChange={onChangeHandler}
                    />
                  </Grid>
                </Grid>
              ))}
              <p className="font-weight-bold1">Website URL</p>
              <Grid container className="typographySection">
                <Grid item md={3}>
                  <p style={{ marginTop: "13px" }}  className="font-weight-bold1">Url</p>
                </Grid>
                <Grid item md={8}>
                  <div className="typographySection">
                    <TextField className="jobBoardboldlabel" 
                      id="generateURL"
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                      style={{ width: "100%" }}
                      name="url"
                      value={portalData.url}
                      disabled={true}
                      onChange={onChangeHandler}
                    />
                  </div>
                </Grid>
                <Grid item md={1}>
                  <ContentCopyIcon style={{ paddingLeft: "10px", paddingTop: "8px", cursor: "pointer" }} onClick={onCopyUrlHandler} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <p className="font-weight-bold1">Preview</p>
              <div className="previewSection">
                <header style={{ backgroundColor: portalData.primaryData[0].value }}>
                  <img src={preview !== null ? preview : portalData.logo} />
                  <p className="menulist" style={{ fontFamily: portalData.typography[1].fontName }}>Job Feed</p>
                </header>
                <div style={{ display: "flex" }} className="sidesubmitbtn">
                  <div
                    style={{ display: "flex", marginTop: "30px" }}
                    className="squarelinedot"
                  >
                    <div className="square" style={{ backgroundColor: portalData.primaryData[1].value }}>
                      <div className="vert-line" style={{ borderColor: portalData.primaryData[1].value }}></div>
                    </div>
                    <div className="circle" style={{ backgroundColor: portalData.primaryData[0].value }}></div>
                  </div>
                  <div className="displaycard">
                    <div className="displaytext">
                      <Typography
                        sx={{ fontSize: "23px", font: "roboto", fontWeight: 700 }} component="div" color="#4F5357"
                      >
                        <span style={{ fontSize: "19px", fontFamily: portalData.typography[0].fontName, color: portalData.typography[0].color }}>UX Designer</span>
                      </Typography>
                      <Typography
                        sx={{ fontSize: 17, fontFamily: portalData.typography[1].fontName, fontWeight: 400, color: portalData.typography[1].color }}
                      >
                        The Company Media Office - Full Time
                      </Typography>
                      <Typography
                        sx={{ fontSize: 17, fontFamily: portalData.typography[1].fontName, fontWeight: 400, color: portalData.typography[1].color }}
                        gutterBottom
                      >
                        Location : ontario
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontFamily: portalData.typography[1].fontName,
                          fontWeight: 400,
                          textAlign: "left",
                        }}
                        component="div"
                        color={portalData.typography[1].color}
                      >
                        19 minutes ago
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: 17,
                          fontFamily: portalData.typography[1].fontName,
                          fontWeight: 400,
                          color: portalData.typography[1].color
                        }}
                      >
                        5LPA
                      </Typography>
                      <h4 style={{ marginBottom: "10px", color: portalData.typography[0].color, fontSize: 20, fontFamily: portalData.typography[0].fontName, fontWeight: 700 }}>Job Description</h4>
                      <Typography
                        variant="body2"
                        color={portalData.typography[1].color}
                        sx={{ fontSize: 15, fontFamily: portalData.typography[1].fontName, fontWeight: 400 }}
                      >
                        Do comensive user and market research, and create well-formed UX analysis documents. Articulate vision, inspire clients to adopt a design thinking mindset, drive the product strategy, roadmap, and governance.
                      </Typography>
                    </div>
                  </div>
                  <div className="submissionbtn">
                    <button className="btn-submit-candidate" style={{ backgroundColor: portalData.primaryData[0].value, fontFamily: portalData.typography[1].fontName, pointerEvents: "none" }}>
                      <p style={{ fontSize: "15px" }}>Apply Now</p>
                    </button>
                  </div>
                </div>
                {/* <div className="footercomp" style={{ backgroundColor: portalData.primaryData[1].value }}>
                  <ul>
                    {Dummy.map((item) => {
                      return (
                        <li style={{ fontFamily: portalData.typography[1].fontName, color: "#fff" }}>{item.name}</li>
                      );
                    })}
                  </ul>
                  <div className='yellowbottom' style={{ backgroundColor: portalData.primaryData[0].value, fontFamily: portalData.typography[1].fontName }}>© 2022 Vantage</div>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </>
      )}
       </div>

    </React.Fragment>
  );
};

export default memo(JobboardPortalComponent);

const defaultFontsList = [
  { id: 1, name: '"Roboto", sans-serif' },
  { id: 2, name: '"Noto Sans", sans-serif' },
  { id: 3, name: '"Montserrat", sans-serif' },
  { id: 4, name: '"Raleway", sans-serif' },
  { id: 5, name: '"Mulish", sans-serif' },
]

export const Dummy = [
  { id: 1, name: "Registration", url: "sdfsdf" },
  { id: 2, name: "Privacy Policy", url: "sdfsdf" },
  { id: 3, name: "T&C", url: "sdfsdf" },
  { id: 4, name: "Feedback", url: "sdfsdf" },
  { id: 5, name: "Contact Us", url: "sdfsdf" },
];