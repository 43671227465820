import React, { useState, useEffect } from "react";
import { styled, Table, TableBody, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, MenuItem, Select } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from "react-redux";
import { updateSnackbar } from "../../_redux/SnackbarSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import InternalExternalTable from "../InternalExternalTable/InternalExternalTable";
import { generateId } from "../../_utilities/internalexternalhelper";
import "./DynamicTableStyle.css";

const CustomDynamicTable = ({ data, moduleType, columns, receiveProps }) => {

  const [tableData, setTableData] = useState([]);
  const [tableRawData, setTableRawData] = useState([]);
  const [isDisableActions, setIsDisableActions] = useState(false);
  const [customValues, setCustomValues] = useState("");
  const initialSnackbar = { type: "", message: "" };
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length > 0 && moduleType) {
        setTableData(data);
        setTableRawData(data);
        data.forEach(e => { e["isEdit"] = false })
    }
  }, [data, moduleType])


  useEffect(() => {
    if (tableData.length > 0) {
      console.log('tableData', tableData)
      console.log('tableRawData', tableRawData)
      let isFlag = tableData.filter(x => x.isEdit === true);
      if (isFlag.length > 0) {
        setIsDisableActions(true)
      } else {
        setIsDisableActions(false)
      }
    }
  }, [tableData, tableRawData])

  const onTableHandler = (item, type) => {
    dispatch(updateSnackbar(initialSnackbar));
    let Array = JSON.parse(JSON.stringify(tableData));
    let Index = Array.findIndex(x => x.id === item.id);
    if (type === 'edit') {
      if (Index !== -1) {
        Array[Index].isEdit = true;
        setTableData(Array);
        // receiveProps(item, type);
      }
    }
    else if (type === 'delete') {
      if (Index !== -1) {
        setIsDisableActions(false)
        receiveProps(null, Array[Index].id);
        Array.splice(Index, 1);
        setTableData(Array);
        setTableRawData(Array);
      }
    }
    else if (type === 'cancel') {
      let Array1 = JSON.parse(JSON.stringify(tableRawData));
      Array1.forEach(e => { e.isEdit = false })
      setIsDisableActions(false)
      setTableData(Array1);
    }
    else if (type === 'save') {
      if (Index !== -1) {
        let object = Array.filter(x => x.fieldName.toLowerCase() === Array[Index].fieldName.toLowerCase());
        if(object.length > 1){
          dispatch(updateSnackbar({ type: "error", message: "Duplicates are not allowed" }))
        } else if(Array[Index].fieldName === ''){
          dispatch(updateSnackbar({ type: "error", message: "Field name is required" }))
        } else if(Array[Index].dataType === ''){
          dispatch(updateSnackbar({ type: "error", message: "Data type selection is required" }))
        } else if(Array[Index].customValues.length === 0 && Array[Index].dataType !== "Text box" ){
          dispatch(updateSnackbar({ type: "error", message: "Values are required for this field" }))
        } else {
          let defaultIds = (moduleType === "Job Postings") ? [1, 2, 3, 4] : [1,2]
          let isDefault = defaultIds.includes(item.id);
          if(isDefault){ delete Array[Index].id }
          Array[Index].isEdit = false;
          if (Array[Index].fieldName === "Candidate status" &&
            Array[Index].customValues.some(value => !value?.internal?.trim() || !value?.external?.trim())) {
            return;
          }
          setTableRawData(Array);
          setTableData(Array);
          receiveProps(Array, Index);
        }
      }
    }
    setCustomValues("");
  }

  const onChangeHandler = (value, label, obj) => {
    let Array = JSON.parse(JSON.stringify(tableData));
    let index = Array.findIndex(x => x.id === obj.id);
     const regex = /^[a-zA-Z0-9_-]*$/
    if(index !== -1){
      if(label !== "customValues" && label !=='fieldName'){
        Array[index][label] = value;
        Array[index].customValues = [];
        setTableData(Array);
      } else if (label ==='fieldName'){
        if(regex.test(value)) {
          Array[index][label] = value;
          Array[index].customValues = [];
          setTableData(Array);
        } else {
          dispatch(updateSnackbar({ type: "error", message: "Please enter valid Alpha-Numberic character, special character accepted are _ and -" }))
        }
      }
    }
  }

  const addRowHandler = (type) => {
    let Array = JSON.parse(JSON.stringify(tableData));
    if (Array.length === 1) {
      let result = !Object.values(Array[0]).every(o => o === null || o === '');
      if (!result) { Array = []; setTableRawData([]) }
    }
    let obj = JSON.parse(JSON.stringify(columns))
    let newObj = {};
    obj.forEach(e => { newObj[e.name] = '' })
    newObj["isEdit"] = true;
    newObj["id"] = 0;
    newObj["customValues"] = [];
    newObj["fieldType"] = "custom";
    newObj['moduleId'] = moduleType === "Job Postings" ? 1 : 2;
    setIsDisableActions(true);
    Array.unshift(newObj);
    setTableData(Array);
  }

  const onkeyhandlerhandler = (e, name, obj) => {
    if(e.which === 13){
      let Array = JSON.parse(JSON.stringify(tableData));
      let index = Array.findIndex(x => x.id === obj.id);
      if(index !== -1){
          Array[index][name].push(customValues);
          setCustomValues("");
      }
      setTableData(Array);
    }
  }

  const updateCustomValues = (name, obj, customValues) => {
    let Array = JSON.parse(JSON.stringify(tableData));
    let index = Array.findIndex((x) => x.id === obj.id);
    if (index !== -1) {
      Array[index][name] = customValues.map((val)=>{
        if(!val.id){
            val.id = generateId(val.internal);
        }
        val.internal = val.internal.trim();
        val.external = val.external.trim();
        return val;
      });
    }
    setTableData(Array);
  };

  const onRemoveValueHandler = (e, data, list, i) => {
    let Array = JSON.parse(JSON.stringify(tableData));
      let index = Array.findIndex(x => x.id === data.id);
      if(index !== -1){
          Array[index].customValues.splice(i, 1);
          setCustomValues("");
      }
      setTableData(Array);
  }

  return (
    <>
      <IconButton color="" className="addRow" disabled={isDisableActions} onClick={() => addRowHandler()} component="label">
        <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" />
        New Field
      </IconButton>
      <TableContainer component={Paper}>
        <Table id="inlineEditTable" aria-label="customized table">
          <TableHead >
            <TableRow >
              {columns.map(col => {
                return (<StyledTableCell className="applicantdynatablehead" width={col.displayName === "Action" ? "10%" : "20%"}>{col.displayName} </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length > 0 ?
              <>
                {tableData && tableData.map((data, id) => {
                  if (data.isEdit === false) {

                    return (

                      <StyledTableRow>
                        {columns.map(col => {
                          if (col.displayName === 'Module' && col.name === 'moduleId') {
                            return (
                              <StyledTableCell width="20%"> {data.moduleId === "1" ? "Job Postings" : "Applicants"} </StyledTableCell>
                            )
                          }
                          else if (col.displayName === 'Field Name' && col.name === 'fieldName') {
                            return (
                              <StyledTableCell width="20%">{data.fieldName}</StyledTableCell>
                            )
                          }
                          else if (col.displayName === 'Data Type' && col.name === 'dataType') {
                            return (
                              <StyledTableCell width="20%">{data.dataType}</StyledTableCell>
                            )
                          }
                          else if (col.name === 'customValues'  && data.fieldName === 'Candidate status') {
                            return (
                              <StyledTableCell width="20%"><InternalExternalTable {...data}/></StyledTableCell>
                            )
                          }
                          else if (col.displayName === 'Values' && col.name === 'customValues') {
                            return (
                              <StyledTableCell width="20%">{data.customValues.join(", ")}</StyledTableCell>
                            )
                          }
                          else if (col.displayName === 'Action') {
                              return (
                                <StyledTableCell width="30">
                                  <IconButton color="primary" disabled={isDisableActions} onClick={() => onTableHandler(data, 'edit')} component="label">
                                    <EditIcon style={{ color: "#3E3E3E" }} className="appediticon" />
                                  </IconButton>
                                  {data.fieldType  !== "default" ? (
                                    <IconButton color="primary" disabled={isDisableActions} onClick={() => onTableHandler(data, 'delete')} component="label">
                                      <DeleteIcon style={{ color: "#3E3E3E" }} className="appdeleteicon" />
                                    </IconButton>
                                  ) : " "}
                                </StyledTableCell>
                              )
                            } else {
                              return (<StyledTableCell> </StyledTableCell>)
                            }
                        })}
                      </StyledTableRow>
                    )

                  } else if (data.isEdit === true) {
                    return (
                      <>
                        <StyledTableRow>
                          {columns.map(col => {
                            if (col.name === 'moduleId' && data.fieldType !== 'default' && data.id === 0) {
                              return (
                                <StyledTableCell >
                                  <Select
                                    style={{ width: "100%" }}
                                    id="outlined-basic"
                                    value={data.module}
                                    size="small"
                                    name="module"
                                    onChange={(e) => { onChangeHandler(e.target.value, col.name, data) }}
                                  >
                                    <MenuItem className="" value="1">Job Postings</MenuItem>
                                    <MenuItem className="" value="2">Applicants</MenuItem>
                                  </Select>
                                </StyledTableCell>
                              )
                            } else if (col.name === 'moduleId') {
                              return (<StyledTableCell width="20%">{data.moduleId === "1" ? "Job Postings" : "Applicants"}</StyledTableCell>)
                            }
                            else if (col.name === 'fieldName' && data.fieldType !== 'default' && data.id === 0) {
                              return (
                                <StyledTableCell>
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    autoComplete="off"
                                    size="small"
                                    placeholder="Field Name"
                                    style={{ width: "100%",marginTop:"-10px" }}
                                    name="fieldName"
                                    value={data.fieldName}
                                    onChange={(e) => { onChangeHandler(e.target.value, col.name, data) }}
                                    onKeyPress={(e) => {
                                      let Array = JSON.parse(JSON.stringify(tableData));
                                      let index = Array.findIndex(x => x.id === data.id);
                                      if(index !== -1){
                                        if(col.name === "fieldName"){
                                          var str = e.target.value;
                                          str = str.replace(/\s/g, "");
                                            Array[index][col.name] = str;
                                        } 
                                      }
                                      setTableData(Array);
                                      if(e.which === 32){
                                        dispatch(updateSnackbar({ type: "error", message: "Spaces are not allowed" }))
                                      }
                                    }}
                                  >
                                  </TextField>
                                </StyledTableCell>
                              )
                            }
                            else if (col.name === 'fieldName') {
                              return (<StyledTableCell width="20%">{data.fieldName}</StyledTableCell>)
                            }
                            else if (col.name === 'dataType' && data.fieldType !== 'default') {
                              return (
                                <StyledTableCell>
                                  <div className="educationTermRangePicker" >
                                    <Select
                                      style={{ width: "100%" }}
                                      id="outlined-basic"
                                      size="small"
                                      value={data.dataType}
                                      name="dataType"
                                      onChange={(e) => { onChangeHandler(e.target.value, col.name, data) }}
                                    >
                                      <MenuItem value="Text box">Text box</MenuItem>
                                      <MenuItem value="Single select">Single select drop down</MenuItem>
                                      <MenuItem value="Multi select">Multi select drop down</MenuItem>
                                    </Select>

                                  </div>
                                </StyledTableCell>
                              )
                            } else if (col.name === 'dataType') {
                              return ( <StyledTableCell width="20%">{data.dataType}</StyledTableCell> )
                            } else if(col.name === 'customValues' && data.fieldName === 'Candidate status'){
                              return (
                                <StyledTableCell width="20%"><InternalExternalTable {...data} updateCustomValues={(customValues)=> updateCustomValues(col.name, data, customValues)}/></StyledTableCell>
                              )
                            }
                            else if (col.name === 'customValues' && data.dataType === "Text box" ) {
                              return (<StyledTableCell></StyledTableCell>)
                             } else if(col.name === 'customValues' && data.dataType !== "Text box"){
                                return (
                                  <StyledTableCell>
                                    <div className="insOptionsSection">
                                      <ul>{data.customValues && data.customValues.map((list, i) => {
                                          if(list === "Cloudsourcing portal" || list === "Employee portal" || list === "Vendorsourcing portal" || list === "JobBoard portal" || list === "Rejected" || list === "Pending" || list === "Associated" ){
                                            return <li className="defList">{list}</li>
                                          } else {
                                            return <li onClick={(e)=>onRemoveValueHandler(e, data, list, i)}>{list}<span>x</span></li>
                                          }
                                        })}</ul>
                                      <input type="text" value={customValues}
                                        onKeyUp={e => onkeyhandlerhandler(e, col.name, data)}
                                        onChange={(e) => { setCustomValues(e.target.value) }} />
                                    </div>
                                  </StyledTableCell>
                                )
                            }
                            else {
                              return (
                                <StyledTableCell>
                                  <IconButton color="primary" onClick={() => onTableHandler(data, 'save')} component="label">
                                    <DoneIcon style={{ color: "#3E3E3E" }} />
                                  </IconButton>
                                  <IconButton color="primary" onClick={() => onTableHandler(data, 'cancel')} component="label">
                                    <CloseIcon style={{ color: "#3E3E3E" }} />
                                  </IconButton>
                                </StyledTableCell>
                              )
                            }
                          })}
                        </StyledTableRow>
                      </>
                    )
                  }
                }
                )}
              </>
              : ''}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};

export default React.memo(CustomDynamicTable);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRight: "none"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // borderRight: "1px solid #E8E8E8",
  },
}));
