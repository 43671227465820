import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: "",
    firstName: "",
    lastName: "",
    userId:''
}

export const AuthSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        updateAuth: (state, action) => {
            if (action.payload) {
                state.email = action.payload.email;
                state.firstName = action.payload.firstName;
                state.lastName = action.payload.lastName;
                state.userId = action.payload.userId;
            } else {
                state = initialState
            }
        },
    },
});

export const { updateAuth } = AuthSlice.actions;

export default AuthSlice.reducer;