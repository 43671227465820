import React from "react";
import { LinearProgress } from "@mui/material";
import { styled } from "@mui/system";
import "./applicantsdashboard.css";

export const CustomLinearProgressForCompanyAverage = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 19, 
  backgroundColor: "#e0e0e0", 
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundImage:
      "repeating-linear-gradient( 90deg, #FFD800, #FFD800 18px, #fff 20px, #fff 17px )", // Customize filled portion color (yellow)
  },
}));

export const CustomLinearProgressForHighestValue = styled(LinearProgress)(
  ({ theme }) => ({
    height: 20, 
    borderRadius: 19,
    backgroundColor: "#e0e0e0", // Base color (for unfilled portion)
    "& .MuiLinearProgress-bar": {
      borderRadius: 5,
      backgroundImage:
        "repeating-linear-gradient( 90deg, #404040, #404040 18px, #fff 20px, #fff 17px )", // Customize filled portion color (yellow)
    },
  })
);

const Widgets = ({ data }) => {

  return (
    <aside>
      <label>{data.title}</label>
      <div className="d-flex-around">
        <h1>{data.currentValue}</h1>
        <img src={data.img} alt="" />
      </div>
      <p className="widget-title">{data.currentTitle}</p>
      <div className="progressbarSection">
        <p>{data.avgTitle}</p>
        <section className="d-flex-around">
          <div className="progress-bar">
            <CustomLinearProgressForCompanyAverage id={`avg-value-${data.currentTitle}`} variant="determinate" value={data?.avgPercentage || 0}/>
          </div>
          <h3>{data.avgValue}</h3>
        </section>
      </div>
      <div className="progressbarSection">
        <p>{data.highestTitle}</p>
        <section className="d-flex-around">
        <div className="progress-bar">
            <CustomLinearProgressForHighestValue id={`highest-value-${data.currentTitle}`} variant="determinate" value={data?.highestPercentage || 0} />
          </div>
          <h3>{data.highestValue}</h3>
        </section>
      </div>
    </aside>
  );
};

export default Widgets;