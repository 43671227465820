/* eslint-disable react/prop-types */
import { Breadcrumbs, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Loader from '../components/Loader/Loader';
import MessageBar from '../components/SnackBar/MessageBar';

import { useDispatch, useSelector } from 'react-redux';
import { updateAuth } from "../_redux/AuthSlice";
import { updateNextPath } from '../_redux/SelectedNextPath';
import GetJobId from '../_utilities/GetJobId';

import { BreadCrumbData, BreadCrumbObj, applicantsMenus, clientsMenus, customerMenus, helpSectionMenus, jobPostingMenus } from './layoutdata';

import { getUserDetails } from "../_redux/CognitoSlice";
import Header from "./Header";
import NavSidebar from "./NavSidebar";

import { ApplicantsById } from '../_redux/_apiHelpers/ApplicantsApihelper';
import { GetClients } from '../_redux/_apiHelpers/ClientsApihelper';
import { Jobpostingsbyid } from '../_redux/_apiHelpers/JobPostingApihelper';
import * as _clientslice from '../_redux/_services/ClientsSlice';
import * as _postingslice from '../_redux/_services/JobPostingSlice';
import { getCustDetailsAsync } from "../_redux/_services/UserManagerSlice";
import { decrypt } from "../_utilities/_encryptDecryptHelper";
import { setBreadCrumb } from '../_utilities/breadCrumb';


const drawerWidth = 230;

function Layout() {
  const [menuData, setMenuData] = useState(null);
  const [menuType, setMenuType] = useState(null);
  const [breadcrumbChild, setBreadcrumbChild] = useState([]);
  const [islandingPage, setIslandingPage] = useState(false);
  const showLoader = useSelector((state) => state.Loader);
  const showUncommonLoader = useSelector((state) => state.UncommonLoader.value);
  const showMessage = useSelector((state) => state.Snackbar.message);
  const showMsgType = useSelector((state) => state.Snackbar.type);
  const nextPathValue = useSelector((state) => state.SeletedNextPath.value);
  const [finishStatus, setfinishStatus] = useState(false);
  const [subPathName, setSubPathName] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const getJobCode = GetJobId();
  const [isAssociated, setIsAssociated] = useState('');
  const atsCustomerDetails = useSelector((state) => state.GetCustDetails.data);

  useEffect(() => {
        const GetInfo =  getUserDetails();
        if (GetInfo) {
          if (!atsCustomerDetails) {
            let USERId = { userId: GetInfo.userId };
            dispatch(getCustDetailsAsync(USERId));
          }
        
      } 
  }, []);

  useEffect(() => {
    if (nextPathValue !== '' && nextPathValue !== 'null' && breadcrumbChild.length > 0) {
      let rawData = JSON.parse(JSON.stringify(breadcrumbChild));
      let subpath = { id: 5, name: nextPathValue, icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`, type: "" };
      if (rawData[rawData.length - 1].id === 5) {
        rawData[rawData.length - 1] = subpath;
      } else {
        rawData.push(subpath);
      }
      setBreadcrumbChild(rawData);
    } if (nextPathValue === 'null') {
      let rawData = JSON.parse(JSON.stringify(breadcrumbChild));
      console.log(rawData);
      if (rawData[0].name === "Job Postings") {
        setMenuData(jobPostingMenus);
        setMenuType('jobpostings');
        navigate(`${jobPostingMenus[2].url}/${isAssociated}`);
      }
      else {
        setMenuData(applicantsMenus);
        setMenuType('applicants');
        navigate(`${applicantsMenus[2].url}/${isAssociated}`);
      }
      rawData.length = 3;
      setBreadcrumbChild(rawData);
      sessionStorage.removeItem("AssociatedId");
      setIsAssociated('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPathValue])

  useEffect(() => {
    MenuChangeHandler();
    const url = window.location.pathname;
    const path = url.substring(url.lastIndexOf("/") + 1);
    if (path === "landing" || path === "layout" || path === "jobdetails" || path === "dashboard") {
      sessionStorage.removeItem("AssociatedId");
      sessionStorage.removeItem("actualId");
      setIsAssociated('');
      setMenuType('');
      setMenuData(null);
      setBreadcrumbChild([]);
    }
    // eslint-disable-next-line
  }, [location, getJobCode]);

  useEffect(() => {
    sessionStorage.removeItem("AssociatedId");
  }, [])

  useEffect(() => {
    async function fetchData() {
      const userInfo = await getUserDetails();
      if (userInfo) {
        dispatch(updateAuth(userInfo))
        // let USERId = { userId: userInfo.userId };
        // dispatch(getCustDetailsAsync(USERId));
      }
    }
    function fetchClientId() {
      dispatch(_clientslice.filterClientsAsync(""));
    }
    function fetchCountiesList() {
      dispatch(_postingslice.getCountriesAsync());
    }
    fetchData();
    // fetchClientId();
    // fetchCountiesList();
  }, [dispatch]);

  useEffect(() => {
    console.log("isAssociated >>>>>>>", isAssociated);
  }, [isAssociated])



  const MenuChangeHandler = async () => {
    const url = window.location.pathname;
    const path = url.substring(url.lastIndexOf("/") + 1);
    const isJobposting = url.includes("jobpostings");
    const isApplicants = url.includes("applicants");
    const isClients = url.includes("clients");
    const isHelpSection = url.includes("helpsection");
    const isCustomer = url.includes("customer");
    let comparePath = removelastSlash(url);
    if (isAssociated !== "") {
      let updateBreadcrumb = JSON.parse(JSON.stringify(breadcrumbChild));
      let pathName = comparePath.substring(comparePath.lastIndexOf("/") + 1);

      let index = updateBreadcrumb.findIndex(x => x.name === "Associated Jobs")
      if (pathName !== "jobview" && index === -1) {
        updateBreadcrumb[4] = { id: 0, name: (pathName === "associatedJobs" && pathName === "associatedapplicants") ? "View" : pathName, url: `#`, icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`, type: "" }
      }
      // else if ((pathName === "associatedJobs" || pathName === "associatedapplicants") && index === -1){
      //   updateBreadcrumb[4] = { id: 0, name: (pathName === "associatedJobs" && pathName === "associatedapplicants") ? "View" : pathName , url: `#`, icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`, type: "" }
      // }
      setBreadcrumbChild(updateBreadcrumb);
    } else {
      if (path !== "landing" && path !== "dashboard" && path !== "create" && path !== "jobdetails" &&
        path !== "document" && path !== "questions" && path !== "portals" && isJobposting) {
        setMenuData(jobPostingMenus);
        setMenuType('jobpostings');
        const postingSubpathData = await generateSubpath('jobpostings', comparePath, path, "Job Code");
        setBreadcrumbChild(postingSubpathData);
      } else if (path !== "landing" && path !== "dashboard" && path !== "create" && path !== "create-duplicate" && isApplicants) {
        setMenuData(applicantsMenus);
        setMenuType('applicants');
        const applicantSubpathData = await generateSubpath('applicants', comparePath, path, "Applicant Code");
        setBreadcrumbChild(applicantSubpathData);
      } else if (path !== "landing" && path !== "dashboard" && path !== "create" && isClients) {
        setMenuData(clientsMenus);
        setMenuType('clients');
        const clientsSubpathData = await generateSubpath('clients', comparePath, path, "clients");
        setBreadcrumbChild(clientsSubpathData)
      }
      else if (path !== "landing" && path !== "dashboard" && path !== "create" && isHelpSection) {
        setMenuType('helpsection');
        setMenuData(helpSectionMenus);
        setBreadcrumbChild([])
      }
      else if (path !== "landing" && path !== "dashboard" && path !== "create" && isCustomer) {
        setMenuType('customer');
        let portalData = JSON.parse(atsCustomerDetails[0].portals)[0].portal;
        console.log("portalData ++++++++++++++", portalData);
        let modifyMenuList = JSON.parse(JSON.stringify(customerMenus));
        if(!portalData.includes("2")){
          let vendorIndex = modifyMenuList.findIndex((x)=> x.menuName === "Vendors" );
          if(vendorIndex !== -1){
            modifyMenuList.splice(vendorIndex, 1);
          }
        }
        setMenuData(modifyMenuList);
        let path = url.replace("/ats/layout/", "");
        let filteredData1 = BreadCrumbData.filter(x => x.url === path && x.type === "customer");
        if (filteredData1.length > 0) {
          let dataArray = [];
          let subpathObj = JSON.parse(decrypt("GetUserInfo"));
          
          if (subpathObj && subpathObj.length > 0) {
            let subpath = { id: subpathObj[0].id, name: subpathObj[0].companyName, icon: "", type: "" }
            dataArray.push(subpath);
          }
          let ArryObj = JSON.parse(JSON.stringify(filteredData1[0]));
          ArryObj.url = undefined
          dataArray.push(ArryObj);
          setBreadcrumbChild(dataArray)
        }
      }
      else if (path === "" || path === "layout") {
        navigate("/ats/layout/jobpostings/landing");
      }
      else {
        setMenuType('');
        setMenuData(null);
        setBreadcrumbChild([]);
      }
    }
    setIslandingPage((path === "landing" || path === "create" || path === "jobdetails" || path === "document" || path === "questions" || path === "portals") ? true : false);
  }

  const removelastSlash = (str) => {
    let path = str.replace("/ats/layout/", "");
    return path.slice(0, path.lastIndexOf('/'));
  }

  const generateSubpath = async (BreadCrumbType, comparePath, path, subpathType) => {
    let filteredData = BreadCrumbData.filter(x => x.url === comparePath && x.type === BreadCrumbType);
    if (filteredData.length > 0) {
      let dataArray = [], subpathUrl = '';
      if (filteredData[0].url.includes("jobpostings")) {
        dataArray.push(BreadCrumbObj[0]);
        subpathUrl = "jobpostings/view/";
      } else if (filteredData[0].url.includes("applicants")) {
        dataArray.push(BreadCrumbObj[1])
        subpathUrl = "applicants/view/";
      } else {
        dataArray.push(BreadCrumbObj[2])
        subpathUrl = "clients/view/";
      }
      if (Number.isInteger(parseInt(path))) {
        let subpathObj = JSON.parse(localStorage.getItem("subpathObj"));
        if (subpathObj && subpathObj.length > 0) {
          let filterSubObj = subpathObj.filter(x => x.id === parseInt(path) && x.type === subpathType);
          if (filterSubObj.length > 0) {
            let subpath = { id: filterSubObj[0].id, name: filterSubObj[0].name, icon: "", type: subpathType, url: subpathUrl + filterSubObj[0].id };
            dataArray.push(subpath);
            setSubPathName(subpath.name);
          }
          else {
            let response = await getSubpathFromService(BreadCrumbType, path, subpathType);
            response["url"] = subpathUrl + response.id;
            dataArray.push(response);
            setSubPathName(response.name);
          }
        }
        else {
          let response = await getSubpathFromService(BreadCrumbType, path, subpathType);
          response["url"] = subpathUrl + response.id;
          dataArray.push(response);
          setSubPathName(response.name);
        }
      }
      let ArryObj = JSON.parse(JSON.stringify(filteredData[0]));
      ArryObj.url = undefined
      dataArray.push(ArryObj);
      // setBreadcrumbChild(dataArray)
      console.log(dataArray);
      return dataArray;
    }
  }

  const getSubpathFromService = async (moduleType, path, subpathType) => {
    if (moduleType === "jobpostings") {
      const jobpostingResponse = await Jobpostingsbyid(path);
      if (jobpostingResponse.data.code === 200) {
        let postingBreadCrumb = `${jobpostingResponse.data.data[0].jobTitle} (${jobpostingResponse.data.data[0].jobCode})`
        let subpath = { id: jobpostingResponse.data.data[0].id, name: postingBreadCrumb, icon: "", type: subpathType }
        setBreadCrumb(jobpostingResponse.data.data[0].id, postingBreadCrumb, subpathType);
        return subpath;
      }
    } else if (moduleType === "applicants") {
      const applicantResponse = await ApplicantsById(path);
      if (applicantResponse.data.code === 200) {
        let subpath = { id: applicantResponse.data.data[0].id, name: applicantResponse.data.data[0].fullName, icon: "", type: subpathType }
        setBreadCrumb(applicantResponse.data.data[0].id, applicantResponse.data.data[0].fullName, subpathType);
        return subpath;
      }
    } else if (moduleType === "clients") {
      const clientsResponse = await GetClients(path);
      if (clientsResponse.data.code === 200) {
        let subpath = { id: clientsResponse.data.client[0].id ? clientsResponse.data.client[0].id : "", name: clientsResponse.data.client[0].clientName ? clientsResponse.data.client[0].clientName : "", icon: "", type: subpathType }
        setBreadCrumb(clientsResponse.data.client[0].id ? clientsResponse.data.client[0].id : "", clientsResponse.data.client[0].clientName ? clientsResponse.data.client[0].clientName : "", subpathType);
        return subpath;
      }
    }
  }

  const AssociatedMenuChangeHandler = () => {
    const url = window.location.pathname;
    const path = url.substring(url.lastIndexOf("/") + 1);
    const isAssociatedJobs = url.includes("associatedjobs");
    const isAssociatedApplicants = url.includes("associatedapplicants");
    if (path !== "landing" && path !== "dashboard" && path !== "create" && isAssociatedApplicants) {
      setMenuType('applicants');
      setMenuData(applicantsMenus);
    } else if (path !== "landing" && path !== "dashboard" && path !== "create" && isAssociatedJobs) {
      setMenuType('jobpostings');
      setMenuData(jobPostingMenus);
    }
    setIsAssociated(sessionStorage.getItem("AssociatedId"));
  }

  useEffect(() => {
    const storageEventHandler = (event) => {
      const changeMenu = event.currentTarget.localStorage.AssociatedMenu;
      if (changeMenu && changeMenu !== 'false') {
        AssociatedMenuChangeHandler();
      } else {
        MenuChangeHandler();
      }
    }
    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
    // eslint-disable-next-line
  }, [])

  const onBreadCrumbHandler = (list) => {
    let findIndex = breadcrumbChild.findIndex(x => x.name === list.name);
    if (list.name === "Associated Applicants" || list.name === "Associated Jobs") {
      let actualId = sessionStorage.getItem("actualId");
      if (actualId) {
        setIsAssociated(actualId)
        dispatch(updateNextPath("null"));
      }
    }
    else if (list.url !== undefined) {  //&& findIndex !== 1
      // if (list.name === "Job Postings" || list.name === "Applicants" || list.name === "Clients") {
        sessionStorage.removeItem("AssociatedId");
        sessionStorage.removeItem("actualId");
        setIsAssociated('');
        setMenuType('');
        setMenuData(null);
        setBreadcrumbChild([]);
      // }
      navigate(list.url)
    }
    // else if (findIndex === 1) {
    //   // setIsAssociated(sessionStorage.getItem("actualId"))
    //   // navigate(list.url);
    //   // dispatch(updateNextPath("null"));
    // }
  }

  return (
    <>
      <Header />
      {showMessage !== "" ? <MessageBar message={showMessage} type={showMsgType} /> : ''}
      {((showLoader.value || showUncommonLoader) && showLoader.isGlobalLoading) ? <Loader /> : ''}
      <div id={menuData !== null ? "subLayout" : "mainLayout"}>
        <div style={{ width: "100%", display: menuData !== null ? '' : "none" }}>
          {menuData !== null && (menuType !== "helpsection" ? breadcrumbChild?.length > 0 : true) ? (
            <nav>
              <NavSidebar isAssociat={isAssociated}
                drawerWidth={drawerWidth}
                menuList={menuData}
                menuType={menuType}
                subPathName={subPathName}
              />
            </nav>
          ) : (
            ""
          )}
        </div>
        {location.pathname !== "/ats/layout" ? (
          <div
            style={{
              minWidth:
                menuData !== null ? `calc(100% - ${drawerWidth}px)` : "100%",
              margin: menuData !== null ? 0 : "0 auto",
              boxSizing: "border-box"
            }}
          >
            <div
              style={{
                backgroundColor: (location.pathname === "/ats/layout/jobpostings/landing" || location.pathname === "/ats/layout/applicants/landing" || location.pathname === "/ats/layout/clients/landing") || location.pathname === "/ats/layout/dashboard" || location.pathname === "/ats/layout/customer" ? "" : "#fff",
                padding: (location.pathname === "/ats/layout/jobpostings/landing" || location.pathname === "/ats/layout/applicants/landing" || location.pathname === "/ats/layout/clients/landing") || location.pathname === "/ats/layout/dashboard" || location.pathname === "/ats/layout/customer" ? "" : "100px 2% 50px",
                maxHeight: (menuData !== null) ? "calc(100vh - 150px)" : "",
                minHeight: menuData !== null ? "calc(99.7vh - 149px)" : "auto",
                position: "relative",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              {!islandingPage && breadcrumbChild?.length > 0 ?
                <Breadcrumbs className="breadCrumbs">
                  <div style={{ marginRight: "20px" }}>
                    <img src={breadcrumbChild[breadcrumbChild.length - 1].icon} alt={breadcrumbChild[breadcrumbChild.length - 1].name} /></div>
                  {breadcrumbChild.length > 0 && breadcrumbChild.map((list, i) => (
                    <Typography className={list?.url === undefined && list?.type === '' ? '' : "breadCrumbpointer"}
                      key={i} color="text.primary"
                      onClick={() => {
                        onBreadCrumbHandler(list)
                      }}>{list?.name}</Typography>
                  ))
                  }
                </Breadcrumbs> : ''
              }
              <Outlet />
            </div>
          </div>
        ) : ("")}
        {location.pathname === "/ats/layout" ? (
          <div className="welcomeScreen">
            <img src={process.env.PUBLIC_URL + "/assests/welcomeScreen.svg"} alt="welcome screen" />
          </div>
        ) : ("")}
      </div>
    </>
  );
}

export default Layout;