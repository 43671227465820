import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: '',
}

export const SeletedNextPathSlice = createSlice({
  name: 'SeletedNextPath',
  initialState,
  reducers: {
    updateNextPath: (state, action) => {      
      state.value = action.payload;
    },
  },
})

export const { updateNextPath } = SeletedNextPathSlice.actions

export default SeletedNextPathSlice.reducer;