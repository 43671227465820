import React, { useEffect, useState } from "react";
import { Card, Typography, CardContent, CardActionArea, CardActions } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import RightSideBar from "../../components/Rightsidebar/RightSideBar";
import "./HelpSection.css";

function HelpSectionEmail() {

    const [cardItems, setCardItems] = useState(postingsvideoObj);
    const [isRightSidePannel, setIsRightSidePannel] = useState(false);
    const [isLoadDoc, setIsLoadDoc] = useState(false);
    const [pannelObj, setPannelObj] = useState({ title: "", description: "", bgImage: `` });
    const [selectedlink, setSelectedlink] = useState();

    const handlecardselect = (elem) => {
        setIsRightSidePannel(true);
        setPannelObj({...pannelObj, title : elem.name})
        setSelectedlink(elem);
      };

      const onReceivePropsHandler = (pannel, load, type) => {
        if(type !== "title") {
          setIsRightSidePannel(pannel);
          setIsLoadDoc(load);
        } else if (type === "title") {
          setPannelObj({...pannelObj, title : load.name});
          setSelectedlink(load);
        } 
      };

    return (
        <>
        <div className="innerdivhelp">
        {cardItems.length > 0 ? 
          <>
          {cardItems.map((elem) => {
            return (
              <>
              <div className="videoCards" >
              <Card className={ 'cardPositionVideo'} onClick={() => handlecardselect(elem)}>
              <img className="playicon" src={process.env.PUBLIC_URL + "/assests/img/playicon2.svg"} alt="" />
                    <video width="100%" height="230" className="videotag" poster={process.env.PUBLIC_URL + "/assests/img/videoThumbnail.png"}>   
                    <source src={elem.link} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
              </Card><br/>
              <span >{elem.name}</span>
              </div>
            </>
            );
            
          })}
          </>
           : <h3 style={{color:"#7A7A7A",width:"100%",display:"flex",justifyContent:"center"}}>No Data found</h3> }
        </div>
        {isRightSidePannel ? (
            <div id="helpsection">
              <RightSideBar
                componentData={
                  <ComponentRenderData
                    cardItems={cardItems}
                    selectedlink={selectedlink}
                    onReceivechildProps={onReceivePropsHandler}
                  />
                }
                componentLayout={pannelObj}
                onReceiveProps={onReceivePropsHandler}
              />
            </div>
          ) : (
            ""
          )}
          </>
    );
};

const ComponentRenderData = (props) => {

  const [related, setRelated] = useState([]);
  const [link, setLink] = useState("");
  const [videoDesc, setVideoDesc] = useState("")

  useEffect(()=>{
    setLink(props.selectedlink.link);
    setVideoDesc(props.selectedlink.description);
    let array = props.cardItems
    getRelatedvideos(array);
  },[props])

  const getRelatedvideos = (array) => {
    let newArray = [...array];
    const index = newArray.indexOf(props.selectedlink);
    if (index > -1) { 
      newArray.splice(index, 1); 
    }
    console.log(newArray);
    setRelated(newArray.slice(0,4));
  }

  const handleRelatedSelect = (elem) => {
    setLink(elem.link);
    setVideoDesc(elem.description);
    props.onReceivechildProps(true, elem, "title");
  };

 
    return (
        <>
        <div>
            {/* <embed height="400" width="700" 
            src={link}> 
            </embed>  */}
             {/* width="640" height="480" */}
            <iframe className="videopreview" src={link} width="93%" height="402" allow="autoplay"></iframe> 
        </div>
        <div>
         <p style={{color: "#3B4046", textAlign:"left", marginLeft:"4%"}}>{videoDesc}</p>
        </div>
          <h5 className="relatedText"> Related videos </h5>

        <div className="innerdivhelppreview">
        {related.length > 0 ? 
          <>
          {related.map((elem) => {
            return (
                <>
              <div className="videoCards" >
              <Card className={ 'cardPositionSuggestion'} onClick={() => handleRelatedSelect(elem)}>
              <img className="playiconRelated" src={process.env.PUBLIC_URL + "/assests/img/playicon2.svg"} alt="" />
                    <video width="100%" height="140" className="videotag" poster={process.env.PUBLIC_URL + "/assests/img/videoThumbnail.png"}  >
                        <source src={elem.link} 
                        type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

              </Card><br/>
              <span  >{elem.name}</span>
              </div>
            
            </>
            );
            
          })}
          </>
           : " " }
        </div>
        </>
    )
}

export default HelpSectionEmail;

const postingsvideoObj = [
    // {
    //     id:1,
    //     link:"",
    //     name:"",
    //     description:""
    // },
    // {
    //     id:2,
    //     link:"",
    //     name:"",
    //     description:""
    // },
    // {
    //     id:3,
    //     link:"",
    //     name:"",
    //     description:""
    // },
    // {
    //     id:4,
    //     link:"",
    //     name:"",
    //     description:""
    // },
    // {
    //     id:5,
    //     link:"",
    //     name:""
    // },
]