export const getInternalExternalObj = (values = []) => {
  const firstValue = values[0];
  if (typeof firstValue === "string") {
    return values.map((value) => ({
      id: generateId(value),
      internal: value,
      external: value,
    }));
  }
  return values;
};

export const isDefaultText = (value) => {
  return ["Rejected", "Pending", "Associated"].some((v) => v === value);
};

export const candidateStatusFilter = (customfeilds) => {

  const candidateStatusIndex = customfeilds.findIndex(
    (customfeild) => customfeild.fieldName === "Candidate status"
  );

  if (candidateStatusIndex > -1) {
    const candidateStatusObj = { ...customfeilds[candidateStatusIndex] };

    const customValuesArray =
      typeof candidateStatusObj?.customValues === "string"
        ? JSON.parse(candidateStatusObj?.customValues)
        : candidateStatusObj?.customValues;

    const firstValue = customValuesArray[0] || "";

    let values = customValuesArray;
    if (typeof firstValue === "string") {
      values = customValuesArray.map((value) => ({
        id: generateId(value),
        internal: value,
        external: value,
      }));
    }
    customfeilds[candidateStatusIndex].customValues = JSON.stringify(values);
  }
  return [...customfeilds];
};

export const getCandidateStatusId = (candidateStatus, statusList = []) => {
  return statusList.find((status) => status.internal === candidateStatus)?.id;
};

export const getCandidateInternalStatus = (candidateStatus, statusList = []) => {
  return statusList.find((status) => status?.id?.toLowerCase() === candidateStatus?.toLowerCase())?.internal;
};

export const generateId = (value) => {
  return value.trim()?.split(" ")?.join("_");
}

export const getCandidateStatusCustomValues = (customFieldsData) => {
  if (customFieldsData && customFieldsData.length > 0) {
    const data = JSON.parse(JSON.stringify(customFieldsData));
    let customStatusFieldsData = data.filter(x => x.moduleId.toString() === "2" && x.fieldType === "default" && x.fieldName === "Candidate status");
    if (customStatusFieldsData.length > 0) {
      let index = customStatusFieldsData.length - 1;
      return JSON.parse(customStatusFieldsData[index].customValues)
    }
    return [];
  }
  return [];
}
