import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updateAuth } from "../_redux/AuthSlice";
import { getUserDetails, signOut } from "../_redux/CognitoSlice";
import * as jobPostingServices from "../_redux/_services/JobPostingSlice";
import { getCustDetailsAsync, getCustDetailsStartReducer } from "../_redux/_services/UserManagerSlice";
import { Encrypt, decrypt } from "../_utilities/_encryptDecryptHelper";
import { setBreadCrumb } from "../_utilities/breadCrumb";
import { persistor, resetStore } from '../store';

const pages = [
  {
    id: 1,
    path: "/ats/layout/jobpostings/landing",
    displayName: "Job Postings",
    isActive: false,
  },
  {
    id: 2,
    path: "/ats/layout/applicants/landing",
    displayName: "Applicants",
    isActive: false,
  },
  {
    id: 3,
    path: "/ats/layout/clients/landing",
    displayName: "Clients",
    isActive: false,
  },
];

function Header() {
  // const userDetails = useSelector((state) => state.Auth);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userCountries, setUserCountries] = useState(null);
  const [GetUserInfo, setgetUserInfo] = useState(null);
  const [loggedOut, setLoggedOut] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const location = useLocation();
  const countriesData = useSelector((state) => state.GetCountries.data);
  const atsCustomerDetails = useSelector((state) => state.GetCustDetails.data);

  useEffect(() => {
    async function fetchData() {
      try {
        const GetInfo = await getUserDetails();
        if (GetInfo) {
          if (!atsCustomerDetails) {
            let USERId = { userId: GetInfo.userId };
            dispatch(getCustDetailsAsync(USERId));
          }
          setUser(GetInfo);
        } else {
          // const userData = {
          //   Username: JSON.parse(decrypt("userName")),
          //   Pool: userPool,
          // };
          // const cognitoUser = new CognitoUser(userData);
          // cognitoUser.getUserAttributes((err, attributes) => {
          //   if (err) {
          //     console.error(err);
          //   } else {
          //     console.log(attributes);
          //   }
          // });
        }
      } catch (error) {
        navigate("/ats/login");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!countriesData && !loggedOut) {
      dispatch(jobPostingServices.getCountriesAsync());
    }
  }, [dispatch, countriesData, loggedOut]);

  useEffect(() => {
    if (GetUserInfo === null) {
      setgetUserInfo(JSON.parse(decrypt("GetUserInfo")));
    }
  }, [GetUserInfo])

  useEffect(() => {
    if (GetUserInfo && GetUserInfo.length > 0) {
      let assignedCountries = JSON.parse(GetUserInfo[0].selectedcountries);
      if (assignedCountries.length > 0) {
        let selCountry = decrypt('selectCountry') ? decrypt('selectCountry') : Cookies.get('selectCountry');
        if (selCountry) {
          let countryObj = JSON.parse(selCountry);
          setUserCountries(countryObj);
          Cookies.set('selectCountry', JSON.stringify(countryObj), { expires: 3 });
          Cookies.set('selectCountryId', JSON.stringify(countryObj.id), { expires: 3 });
          Encrypt(JSON.stringify(countryObj), "selectCountry");
          Encrypt(JSON.stringify(countryObj.id), "selectCountryId");
        } else {
          setUserCountries(assignedCountries[0]);
          Cookies.set('selectCountry', JSON.stringify(assignedCountries[0]), { expires: 3 });
          Cookies.set('selectCountryId', JSON.stringify(assignedCountries[0].id), { expires: 3 });
          Encrypt(JSON.stringify(assignedCountries[0]), "selectCountry");
          Encrypt(JSON.stringify(assignedCountries[0].id), "selectCountryId");
        }
        setSelectedCountry(assignedCountries);
      }
    }
  }, [GetUserInfo]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onActiveMenuHandler = (page, boolean) => {
    let obj = pages;
    obj.forEach((e) => (e.isActive = false));
    let findIndex = obj.findIndex((x) => x.id === page.id);
    if (findIndex !== -1) {
      obj[findIndex].isActive = true;
    }
    if (boolean) {
      sessionStorage.removeItem("AssociatedId");
      // navigate(page.path);
    }
  };

  useEffect(() => {
    const url = location.pathname;
    const isJobposting = url.includes("jobpostings");
    const isApplicants = url.includes("applicants");
    const isClients = url.includes("clients");
    let isAssociated = localStorage.getItem("AssociatedMenu");
    // if(isAssociated === "false"){
    if (isJobposting) {
      onActiveMenuHandler(pages[0], false);
    } else if (isApplicants) {
      onActiveMenuHandler(pages[1], false);
    } else if (isClients) {
      onActiveMenuHandler(pages[2], false);
    }
    else {
      pages.forEach((e) => (e.isActive = false));
    }
    // }
  }, [location]);

  // useEffect(() => {
  //   if (user !== null) {
  //     dispatch(refreshToken());
  //     // return "Loading...";
  //   }
  // }, [location]);

  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, obj) => {
    setUserCountries(obj);
    Encrypt(JSON.stringify(obj), "selectCountry");
    Encrypt(JSON.stringify(obj.id), "selectCountryId");
    Cookies.set('selectCountry', JSON.stringify(obj), { expires: 3 });
    Cookies.set('selectCountryId', JSON.stringify(obj.id), { expires: 3 });
    // dispatch(updateCountry(JSON.stringify(obj)));
    const url = location.pathname;
    const isJobposting = url.includes("jobpostings");
    const isApplicants = url.includes("applicants");
    const isClients = url.includes("clients");

    if (isJobposting) {
      navigate("/ats/layout/jobpostings/landing");
    } else if (isApplicants) {
      navigate("/ats/layout/applicants/landing");
    } else if (isClients) {
      navigate("/ats/layout/clients/landing");
    }
    window.location.reload();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelp = (type) => {
    if (type === "help") {
      navigate("/ats/layout/helpsection/postings");
    } else {
      navigate(`/ats/layout/customer/view`); // ${GetUserInfo[0].id}
      if (GetUserInfo) {
        setBreadCrumb(GetUserInfo[0].id, GetUserInfo[0].companyName, "Customer")
      }
    }
    handleCloseUserMenu();
  }

  return (
    <AppBar position="fixed" className="headerBar">
      <Toolbar>
        <Link to="/ats/layout/dashboard">
          <img src={process.env.PUBLIC_URL + "/assests/HireWing.png"} alt="brand logo" className="hirewingImg" onClick={() => {
            // window.location.reload();
            pages.map((x) => (x.isActive = false));
            // navigate("/ats/layout/jobpostings/landing");
          }}
          />
        </Link>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            gap: "30px",
            margin: "0px 0 0 30px",
          }}
        >
          {pages.map((page) => (
            <Button
              component={Link}
              to={page.path}
              key={page.id}
              rel="tag"
              style={{
                margin: "2px 10px 2px 0",
                color: "white",
                display: "block",
                padding: "6px 8px",
                fontSize: "18px",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
              className={page.isActive === true ? "activebtn" : "nonactivebtn"}
              onClick={(e) => onActiveMenuHandler(page, true)}
            >
              {page.displayName}
            </Button>
          ))}
        </div>

        <div >
          <List
            component="nav"
            aria-label="Device settings"
          >
            <ListItem
              className="countrydisplay"
              id="lock-button"
              // style={{ marginTop: "-4px" }}
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-expanded="true"
              onClick={handleClickListItem}
            >
              <ListItemText className="flagname"
                style={{ display: "flex", gap: "15px", justifyContent: "left", width: "120px", color: "white", cursor: "pointer", alignItems: "center" }}
                primary={<img src={userCountries ? `${process.env.PUBLIC_URL}/assests/img/${userCountries.logo}` : ''} alt="" />}
                secondary={userCountries ? userCountries.name : ''}
              />
            </ListItem>
          </List>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClick={handleClose}
            onMouseLeave={handleClose}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
          >
            {selectedCountry && selectedCountry.map((option, index) => (
              <MenuItem
                style={{ display: "flex", gap: "15px", justifyContent: "left", height: "35px", padding: "0 12px", width: "135px", }}
                // className="menucountrydropdown"
                key={option.id}
                selected={option.id === userCountries ? userCountries.id : 0}
                onClick={(event) => handleMenuItemClick(event, index, option)}
              >
                <img src={`${process.env.PUBLIC_URL}/assests/img/${option.logo}`} alt=""
                  // className="countryflagsimg"
                  style={{ width: option.logo === "004-malaysia.svg" ? "30px" : "30px" }}

                />
                <p className="countryflagname">{option.name}</p>
              </MenuItem>
            ))}
          </Menu>

          {/* select flag section end here */}
        </div>
        <div style={{ display: "flex", color: "#fff" }}>
          <h4 style={{ paddingLeft: "10px", fontWeight: "400" }}>
            {GetUserInfo !== null ? GetUserInfo[0].companyName : ""}
          </h4>
          <IconButton
            onClick={handleOpenUserMenu}
            style={{ padding: 0, marginRight: "20px", marginLeft: "10px" }}
          >
            <Avatar alt="Remy Sharp" style={{ backgroundColor: "#FFD800" }}>
              <PersonIcon style={{ color: "#454545" }} />
            </Avatar>
          </IconButton>
          {/* userdetails start here */}
          <Menu
            style={{ marginTop: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
          >
            <div className="profileMenu">
              <div
                style={{ alignItems: "center", justifyContent: "flex-start" }}
              >
                <img src={process.env.PUBLIC_URL + "/assests/img/profileIcon.svg"} alt="profileIcon" />
                <h3 style={{ paddingLeft: "10px" }}>
                  {GetUserInfo !== null
                    ? GetUserInfo[0].companyName
                    : ""}
                </h3>
              </div>
              {user !== null && (
                <>
                  <h5>
                    {user.firstName} {user.lastName}
                  </h5>
                  <p>{user.email}</p>
                </>
              )}
              <div >
                <button className="help" onClick={() => { handleHelp("help") }}>
                  Help
                </button>
              </div>
              <div>
                {GetUserInfo !== null && (GetUserInfo[0].systemroles === "Admin" || GetUserInfo[0].systemroles === "Super Admin") ?
                  <p className="settingstext" onClick={() => { handleHelp("settings") }}>Settings</p>
                  : ("")}
              </div>
              <div id="signout" style={{ justifyContent: "end" }}>
                <Button
                  onClick={async () => {
                    setLoggedOut(true);
                    dispatch(updateAuth(null));
                    dispatch(getCustDetailsStartReducer({}));
                    Cookies.remove("userName");
                    Cookies.remove("userPassword");

                    dispatch(resetStore());
                    await persistor.flush();  // Ensures all pending state is written to storage
                    await persistor.purge();  // Purges the persisted state
                    localStorage.clear();
                    sessionStorage.clear();
                    signOut();
                    navigate("/ats/login");
                    // window.location.reload();
                    
                  }}
                  className="signout"
                >
                  Sign Out
                </Button>
              </div>
            </div>
          </Menu>
          {/* userdetails end here */}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;