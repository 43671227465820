import { createSlice } from "@reduxjs/toolkit";
import * as _ser from '../_apiHelpers/CloudSourcingApihelper';
import { updateLoader } from "../../_redux/LoaderSlice";
import { updateSnackbar } from "../../_redux/SnackbarSlice";

const initalState = { data: null, message: '', code: null }

export const getQuestionsAnswersSlice = createSlice({
    name: "GetQuestionsAnswers",
    initialState: initalState,
    reducers: {
        getQuestionsAnswersStartReducer: (state, action) => {
            return { ...state, data: null }
        },
        getQuestionsAnswersReducer: (state, action) => {
            if (action.payload.status === 200) {
                return { ...state, data: action.payload.data?.Answers, code: action.payload.data?.code, message: action.payload.data?.message };
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getQuestionsAnswersReducer, getQuestionsAnswersStartReducer } = getQuestionsAnswersSlice.actions;
export const getQuestionsAnswersReducerAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    const res = await _ser.GetQuestionsAnswers(id);
    dispatch(getQuestionsAnswersReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}