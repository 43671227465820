import React from 'react'
import './PageNotFound.css'
import { Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { signOut } from '../../_redux/CognitoSlice';
import Cookies from 'js-cookie';

const PageNotFound = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  console.log(error);
  return (
    <div className='pageNotfound'>
      <div className="pageNotfoundContent">
        <img src={process.env.PUBLIC_URL + "/assests/img/pagenotfound.png"} width="800px" />
        <h1>Page Not Found</h1>
        <p>{error?.message ? error.message : ''}</p>
        <Button
          variant="contained"
          className="saveerror"
          onClick={() => {
            // navigate('/ats/layout/jobpostings/landing');
            // window.location.reload();
            Cookies.remove("userName");
            Cookies.remove("userPassword");
            signOut();
            navigate("/ats/login");
            window.location.reload();
          }} >
          Home
        </Button>
      </div>
      <div className="corners"></div>
    </div>
  )
}

export default PageNotFound