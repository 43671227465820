import { createSlice } from "@reduxjs/toolkit";
import * as _ser from '../_apiHelpers/JobPostingApihelper';
import * as _msg from "../../_utilities/message";
import { updateLoader, updateGlobalLoading } from "../../_redux/LoaderSlice";
import { updateSnackbar } from "../../_redux/SnackbarSlice";

const initalState = { data: null, insertId: null, teamLeads: null, manager: null,recruiter: null, message: '', isloader: true }
const initialSnackbar = { type: "", message: "" };

export const listjobpostingsSlice = createSlice({
    name: "Listjobpostings",
    initialState: initalState,
    reducers: {
        listjobpostingsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        listjobpostingsReducer: (state, action) => {
            if (action.payload.status === 200) {
                // if (action.payload.data?.data) {
                    return { ...state, data: action.payload.data?.data, message: action.payload.data?.message, isloader: false };
                // }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { listjobpostingsReducer, listjobpostingsStartReducer } = listjobpostingsSlice.actions;

export const listjobpostingsAsync = (data, type) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    let res = null;
    if (type === "list") {
        res = await _ser.Listjobpostings(data);
    } else if (type === "searchWithoutPaging") {
        res = await _ser.Filterjobpostings(data);
    } else if (type === "searchWithPaging") {
        res = await _ser.FilterjobpostingsWithPaging(data);
    }
    if (res !== null) {
        // if (res.data.data && res.data.data.length === 0) {
        //     res.data.data = 
        // }
        dispatch(listjobpostingsReducer(res));
        dispatch(updateLoader(false));
    }
}


export const deletejobpostingsSlice = createSlice({
    name: "Deletejobpostings",
    initialState: initalState,
    reducers: {
        deletejobpostingsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        deletejobpostingsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { deletejobpostingsReducer, deletejobpostingsStartReducer } = deletejobpostingsSlice.actions;
export const deletejobpostingsAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.Deletejobpostings(data);
    dispatch(deletejobpostingsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.status === 200 ? _msg.DELETEJOB_SUCCESS_MSG : _msg.DELETEJOB_ERROR_MSG }));
    }, 0);
}


export const jobpostingsbyidSlice = createSlice({
    name: "Jobpostingsbyid",
    initialState: initalState,
    reducers: {
        jobpostingsbyidStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        jobpostingsbyidReducer: (state, action) => {
            if (action.payload?.status === 200) {
                if (action.payload?.data?.data) {
                    return { ...state, data: action.payload.data?.data[0],code: action.payload?.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload?.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { jobpostingsbyidReducer, jobpostingsbyidStartReducer } = jobpostingsbyidSlice.actions;
export const jobpostingsbyidAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.Jobpostingsbyid(data);
    dispatch(jobpostingsbyidReducer(res));
    dispatch(updateLoader(false));
    // setTimeout(() => {

    //     dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
    // }, 1500);
}


export const getCountriesSlice = createSlice({
    name: "GetCountries",
    initialState: initalState,
    reducers: {
        getCountriesStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getCountriesReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { getCountriesReducer, getCountriesStartReducer } = getCountriesSlice.actions;
export const getCountriesAsync = () => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetCountries();
    dispatch(getCountriesReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const getStatebyCountrySlice = createSlice({
    name: "GetStatebyCountry",
    initialState: initalState,
    reducers: {
        getStatebyCountryStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getStatebyCountryReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { getStatebyCountryReducer, getStatebyCountryStartReducer } = getStatebyCountrySlice.actions;
export const getStatebyCountryAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetStatebyCountry(data);
    dispatch(getStatebyCountryReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const editjobpostingsSlice = createSlice({
    name: "Editjobpostings",
    initialState: initalState,
    reducers: {
        editjobpostingsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        editjobpostingsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { editjobpostingsReducer, editjobpostingsStartReducer } = editjobpostingsSlice.actions;
export const editjobpostingsAsync = (id, data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.Editjobpostings(id, data);
    dispatch(editjobpostingsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {

        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.status === 200 ? _msg.UPDATEJOBPOSTING_SUCCESS_MSG : _msg.UPDATEJOBPOSTING_ERROR_MSG }));
    }, 1000); 
}

export const getdocumentsSlice = createSlice({
    name: "Getdocuments",
    initialState: initalState,
    reducers: {
        getdocumentsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getdocumentsReducer: (state, action) => {
            console.log(action);
            if (action.payload?.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data,code: action.payload?.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload?.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { getdocumentsReducer, getdocumentsStartReducer } = getdocumentsSlice.actions;
export const getdocumentsAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.Getdocuments(id);
    dispatch(getdocumentsReducer(res));
    dispatch(updateLoader(false));
    // setTimeout(() => {
    //     dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
    // }, 1500);
}


export const deletedocumentsSlice = createSlice({
    name: "Deletedocuments",
    initialState: initalState,
    reducers: {
        deletedocumentsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        deletedocumentsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data.code, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, isloader: false }
            }
        }
    }
});
export const { deletedocumentsReducer, deletedocumentsStartReducer } = deletedocumentsSlice.actions;
export const deletedocumentsAsync = (id, data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.Deletedocuments(id, data);
    dispatch(deletedocumentsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {

        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.status === 200 ? _msg.DELETEDOCUMENT_SUCCESS_MSG : _msg.DELETEDOCUMENT_ERROR_MSG }));
    }, 0);
}


export const downloadFileFromS3Slice = createSlice({
    name: "DownloadFileFromS3",
    initialState: initalState,
    reducers: {
        downloadFileFromS3StartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        downloadFileFromS3Reducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.path, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { downloadFileFromS3Reducer, downloadFileFromS3StartReducer } = downloadFileFromS3Slice.actions;
export const downloadFileFromS3Async = (id, docUrl, type) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.downloadFileFromS3(id, docUrl, type);
    dispatch(downloadFileFromS3Reducer(res));
    dispatch(updateLoader(false));
    if (type === "download") {
        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.DOWNLOADDOCUMENT_SUCCESS_MSG : _msg.DOWNLOADDOCUMENT_ERROR_MSG
        }));
    }
}


export const uploadDocumentsSlice = createSlice({
    name: "UploadDocuments",
    initialState: initalState,
    reducers: {
        uploadDocumentsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        uploadDocumentsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { uploadDocumentsReducer, uploadDocumentsStartReducer } = uploadDocumentsSlice.actions;
export const uploadDocumentsAsync = (id, formdata, isFormData) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.UploadDocuments(id, formdata, isFormData);
    dispatch(uploadDocumentsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {

        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.UPLOADDOCUMENT_SUCCESS_MSG : _msg.UPLOADDOCUMENT_ERROR_MSG
        }));
    }, 2000);
}


export const getAssociatedApplicantsSlice = createSlice({
    name: "GetAssociatedApplicants",
    initialState: initalState,
    reducers: {
        getAssociatedApplicantsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getAssociatedApplicantsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.associatedjobs,code: action.payload?.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { getAssociatedApplicantsReducer, getAssociatedApplicantsStartReducer } = getAssociatedApplicantsSlice.actions;
export const getAssociatedApplicantsAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetAssociatedApplicants(id);
    dispatch(getAssociatedApplicantsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 && res.data?.associatedjobs ? "" : res.data.code ? "success" : "error",
            message: res.data?.associatedjobs?.length > 0 ? "" : ""  // _msg.NOASSOCIATEDAPPLICANTMSG
        }));
    }, 100);
}


export const deleteAssociatedJobseekerSlice = createSlice({
    name: "DeleteAssociatedJobseeker",
    initialState: initalState,
    reducers: {
        deleteAssociatedJobseekerStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        deleteAssociatedJobseekerReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { deleteAssociatedJobseekerReducer, deleteAssociatedJobseekerStartReducer } = deleteAssociatedJobseekerSlice.actions;
export const deleteAssociatedJobseekerAsync = (id,applicantID, data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.DeleteAssociatedJobseeker(id,applicantID, data);
    dispatch(deleteAssociatedJobseekerReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.ASSOCIATEAPPLICANTREMOVE_SUCCESS_MSG : res.data.message
        }));
    }, 1000);
}


export const searchAffindaSlice = createSlice({
    name: "SearchAffinda",
    initialState: initalState,
    reducers: {
        searchAffindaStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        searchAffindaReducer: (state, action) => {
            if (action.payload?.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.applicants, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload?.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { searchAffindaReducer, searchAffindaStartReducer } = searchAffindaSlice.actions;
export const searchAffindaAsync = (obj, keyword) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.SearchAffinda(obj, keyword);
    dispatch(searchAffindaReducer(res));
    dispatch(updateLoader(false));
    dispatch(updateGlobalLoading(true));
    // dispatch(updateSnackbar({
    //     type: res.status === 200 ? "success" : "error", message: res.data.message
    // }));
}


export const deleteAssociatedJobSlice = createSlice({
    name: "DeleteAssociatedJob",
    initialState: initalState,
    reducers: {
        deleteAssociatedStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        deleteAssociatedJobReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { deleteAssociatedJobReducer, deleteAssociatedStartReducer } = deleteAssociatedJobSlice.actions;
export const deleteAssociatedJobAsync = (id, obj, data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.DeleteAssociatedJob(id, obj, data);
    dispatch(deleteAssociatedJobReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.ASSOCIATEAPPLICANTDESELECT_SUCCESS_MSG : res.data.message 
        }));
    }, 1000);

}


export const addAssociatedjobsSlice = createSlice({
    name: "AddAssociatedjobs",
    initialState: initalState,
    reducers: {
        addAssociatedjobsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        addAssociatedjobsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { addAssociatedjobsReducer, addAssociatedjobsStartReducer } = addAssociatedjobsSlice.actions;
export const addAssociatedjobsAsync = (jobId, obj, type) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.AddAssociatedjobs(jobId, obj);
    dispatch(addAssociatedjobsReducer(res));
    dispatch(updateLoader(false));
    if (type === "applicant") {
        setTimeout(() => {
            dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", 
            message: res.status === 200 ? _msg.ASSOCIATEFINDAPPLICANTADD_SUCCESS_MSG : res.data.message }));
        }, 1000);
    }
}

export const updateCandidateStatusSlice = createSlice({
    name: "UpdateCandidateStatus",
    initialState: initalState,
    reducers: {
        updateCandidateStatusStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        updateCandidateStatusReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { updateCandidateStatusReducer, updateCandidateStatusStartReducer } = updateCandidateStatusSlice.actions;
export const updateCandidateStatusAsync = (id,data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.UpdateCandidateStatus(id,data);
    dispatch(updateCandidateStatusReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
    }, 1000);
}

export const getnotesSlice = createSlice({
    name: "Getnotes",
    initialState: initalState,
    reducers: {
        getnotesStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getnotesReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data,code: action.payload?.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { getnotesReducer, getnotesStartReducer } = getnotesSlice.actions;
export const getnotesAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.Getnotes(id);
    dispatch(getnotesReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const addNotesSlice = createSlice({
    name: "AddNotes",
    initialState: initalState,
    reducers: {
        addNotesStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        addNotesReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { addNotesReducer, addNotesStartReducer } = addNotesSlice.actions;
export const addNotesAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.AddNotes(data);
    dispatch(addNotesReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
    }, 0);
}

// edit notes
export const editNotesSlice = createSlice({
    name: "EditNotes",
    initialState: initalState,
    reducers: {
        editNotesStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        editNotesReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { editNotesReducer, editNotesStartReducer } = editNotesSlice.actions;
export const editNotesAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.EditNotes(data);
    dispatch(editNotesReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {
        dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
    }, 0);
}

export const getQuestionsListSlice = createSlice({
    name: "GetQuestionsList",
    initialState: initalState,
    reducers: {
        getQuestionsListStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getQuestionsListReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.Questions, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { getQuestionsListReducer, getQuestionsListStartReducer } = getQuestionsListSlice.actions;
export const getQuestionsListAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetQuestionsList(data);
    dispatch(getQuestionsListReducer(res));
    dispatch(updateLoader(false));
    dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const removeQuestionsSlice = createSlice({
    name: "RemoveQuestions",
    initialState: initalState,
    reducers: {
        removeQuestionsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        removeQuestionsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { removeQuestionsReducer, removeQuestionsStartReducer } = removeQuestionsSlice.actions;
export const removeQuestionsAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.RemoveQuestions(data);
    dispatch(removeQuestionsReducer(res));
    dispatch(updateLoader(false));
    dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const updateQuestionsSlice = createSlice({
    name: "UpdateQuestions",
    initialState: initalState,
    reducers: {
        updateQuestionsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        updateQuestionsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { updateQuestionsReducer, updateQuestionsStartReducer } = updateQuestionsSlice.actions;
export const updateQuestionsAsync = (id, data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.UpdateQuestions(id, data);
    dispatch(updateQuestionsReducer(res));
    dispatch(updateLoader(false));
    dispatch(updateSnackbar({
        type: res.status === 200 ? "success" : "error",
        message: res.status === 200 ? "Question Updated Successfully" : "Data is not updated"
    }));
}


export const addQuestionsSlice = createSlice({
    name: "AddQuestions",
    initialState: initalState,
    reducers: {
        addQuestionsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        addQuestionsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.data, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, isloader: false }
            }
        }
    }
});
export const { addQuestionsReducer, addQuestionsStartReducer } = addQuestionsSlice.actions;
export const addQuestionsAsync = (id, data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.AddQuestions(id, data);
    dispatch(addQuestionsReducer(res));
    dispatch(updateLoader(false));
    dispatch(updateSnackbar({
        type: res.status === 200 ? "success" : "error",
        message: res.data.message
    }));
}


export const addjobpostingsSlice = createSlice({
    name: "Addjobpostings",
    initialState: initalState,
    reducers: {
        addjobpostingsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        addjobpostingsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, insertId: action.payload.data?.insertId, message: action.payload.data?.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data?.message, data: null, insertId: null, isloader: false }
            }
        }
    }
});
export const { addjobpostingsReducer, addjobpostingsStartReducer } = addjobpostingsSlice.actions;
export const addjobpostingsAsync = (id, data, isFormData) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.Addjobpostings(id, data, isFormData);
    dispatch(addjobpostingsReducer(res));
    dispatch(updateLoader(false));
    setTimeout(() => {

        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.data.message
        }));
    }, 500);
}


export const getManagerTeamleadSlice = createSlice({
    name: "GetManagerTeamlead",
    initialState: initalState,
    reducers: {
        getManagerTeamleadStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getManagerReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, manager: action.payload.data.user, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, teamLeads: null, manager: null,recruiter: null, isloader: false }
            }
        },
        getTeamleadReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, teamLeads: action.payload.data.user, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, teamLeads: null, manager: null,recruiter: null, isloader: false }
            }
        },
        getRecruiterReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, recruiter: action.payload.data.user, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, teamLeads: null, manager: null,recruiter: null, isloader: false }
            }
        }
    }
});
export const { getManagerReducer, getManagerTeamleadStartReducer, getTeamleadReducer ,getRecruiterReducer} = getManagerTeamleadSlice.actions;
export const getManagerTeamleadAsync = (keyword, id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetManagerTeamlead(keyword, id);
    if (keyword === "team Lead") {
        dispatch(getTeamleadReducer(res));
    } else if (keyword === "manager") {
        dispatch(getManagerReducer(res));
    } else if (keyword === " ") {
        dispatch(getRecruiterReducer(res));
    } 
    dispatch(updateLoader(false));
    // setTimeout(() => {
    //     dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
    // }, 1000);
}



export const getPostingsCommentsSlice = createSlice({
    name: "GetPostingsComments",
    initialState: initalState,
    reducers: {
        getPostingsCommentsStartReducer: (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getPostingsCommentsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload?.data?.auditlogs,code: action.payload?.data?.code, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, isloader: false }
            }
        }
    }
});
export const { getPostingsCommentsReducer, getPostingsCommentsStartReducer } = getPostingsCommentsSlice.actions;
export const getPostingsCommentsAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetPostingsComments(data);
    dispatch(getPostingsCommentsReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}