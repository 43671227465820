export const setBreadCrumb = (skipId, subpathName, type) => {
    let newobj = { id: skipId, name: subpathName, type: type };
    let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
    if (subPathObj && subPathObj.length > 0) {
        let index = subPathObj.findIndex(x => x.id === newobj.id);
        if (index !== -1) {
            subPathObj[index] = newobj;
        } else {
            subPathObj.push(newobj);
        }
    } else {
        subPathObj = [newobj];
    }
    localStorage.setItem("subpathObj", JSON.stringify(subPathObj));
}