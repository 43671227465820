import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as _props from "../../../_redux/_services/JobPostingSlice";
import GetJobId from "../../../_utilities/GetJobId";
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import PostingsPortals from "../PostingsPortals/PostingsPortals";

const initalState = { data: null, message: "", isloader: true };

const PostingsPortalsView = () => {
  const [listOfPF, setListOfPF] = useState(JSON.parse(JSON.stringify(listOfPlatform)));
  const [flatFee, setFlatFee] = useState("");
  const [flatFeeCountryId, setFlatFeeCountryId] = useState("");
  const [defaultflatFee, setDefaultFlatFee] = useState("");
  const [defaultflatFeeCountryId, setDefaultFlatFeeCountryId] = useState("");
  const [jobId, setJobId] = useState(null);
  const [isValidUser,setIsValidUser ] = useState(false);

  const getDetails = useSelector((state) => state.Jobpostingsbyid.data);
  const getDetailsCode = useSelector((state) => state.Jobpostingsbyid.code);

  useEffect(() => {
    if(getDetailsCode === 404){
      setIsValidUser(true)
    }
  }, [getDetailsCode]);

  const [GetUserInfo, setGetUserInfo] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getJobCode = GetJobId();

  const updateHandler = (val) => {
    setFlatFee(val);
  };

  useEffect(() => {
    if (GetUserInfo && GetUserInfo.length > 0) {
      let userDetails = JSON.parse(JSON.stringify(GetUserInfo))[0];
      let val = userDetails.flatFee !== null && userDetails.flatFee !== 0 ? userDetails.flatFee : "";
      let CountryIdVal = userDetails.flatFeeCountryId !== null && userDetails.flatFeeCountryId !== 0 ? userDetails.flatFeeCountryId : "";
      setDefaultFlatFee(val);
      setDefaultFlatFeeCountryId(CountryIdVal)
    } else {
      setGetUserInfo(JSON.parse(decrypt("GetUserInfo")))
    }
  }, [GetUserInfo]);

  const onChangePlatformHandler = (e, item) => {
    let data = JSON.parse(JSON.stringify(listOfPF));
    let Index = data.findIndex((x) => x.id === item.id);
    if (Index !== -1) {
      data[Index].checked = e.target.checked;
      data[Index].value = e.target.checked ? 1 : 0;
      setListOfPF(data);
    }
  };

  // getData
  useEffect(() => {
    if (getJobCode) {
      dispatch(_props.jobpostingsbyidAsync(getJobCode));
    }
  }, [getJobCode]);

  useEffect(() => {
    if (getDetails !== null && getDetails !== undefined) {
      let postingData = JSON.parse(JSON.stringify(getDetails));
      let portals = JSON.parse(JSON.stringify(listOfPF));
      portals[0].value = postingData.CSPortal;
      portals[0].checked = (parseInt(postingData.CSPortal) === 1) ? true : false;
      portals[1].value = postingData.VSPortal;
      portals[1].checked = (parseInt(postingData.VSPortal) === 1) ? true : false;
      portals[2].value = postingData.EPortal;
      portals[2].checked = (parseInt(postingData.EPortal) === 1) ? true : false;
      portals[3].value = postingData.JBPortal;
      portals[3].checked = (parseInt(postingData.JBPortal) === 1) ? true : false;
      setJobId(postingData.id);
      setFlatFee(postingData?.flatFee && (parseInt(postingData.CSPortal) === 1) ? postingData.flatFee : defaultflatFee);
      setFlatFeeCountryId(postingData?.flatFeeCountryId && (parseInt(postingData.CSPortal) === 1) ? postingData.flatFeeCountryId : defaultflatFeeCountryId);
      setListOfPF(portals);
      dispatch(_props.jobpostingsbyidStartReducer(initalState));
    }
  }, [getDetails]);

  const onGetValidData = (data) => {
    console.log(data);
  };

  return (
    <>
    { !isValidUser &&

    <div className="actionBtnRtl1">
      <Button
        variant="contained"
        size="small"
        className="buttonHover1"
        style={{ backgroundColor: "#ebc700 ", color: "black", borderRadius: "10px", width: "96px", textTransform: "capitalize" }}
        onClick={() => {navigate(`/ats/layout/jobpostings/portals/edit/${jobId}`)}}
      >
        Edit
      </Button>
      </div>
    }
      <PostingsPortals
        componentType="View"
        onReceiveHandler={onChangePlatformHandler}
        data={listOfPF}
        flatFee={flatFee}
        flatFeeCountryId={flatFeeCountryId}
        updateHandler={updateHandler}
      />
    </>
  );
};

export default PostingsPortalsView;

const listOfPlatform = [
  {
    id: 3,
    name: "CSPortal",
    title: "Cloud Sourcing Platform",
    checked: false,
    value: 0,
  },
  {
    id: 2,
    name: "VSPortal",
    title: "Vendor Sourcing Platform",
    checked: false,
    value: 0,
  },
  {
    id: 4,
    name: "EPortal",
    title: "Employee Platform",
    checked: false,
    value: 0,
  },
  {
    id: 5,
    name: "JBPortal",
    title: "Job Board Platform",
    checked: false,
    value: 0,
  },
];

/// list of portals in DB  ///
//  1  ATS
//  2  Vendor Sourcing
//  3  Cloud Sourcing
//  4  Employee Portal
//  5  JobBoard Portal
