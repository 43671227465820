import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
  isGlobalLoading: true,
};

export const LoaderSlice = createSlice({
  name: "Loader",
  initialState,
  reducers: {
    updateLoader: (state, action) => {
      state.value = action.payload;
    },
    updateGlobalLoading: (state, action) => {
      state.isGlobalLoading = action.payload;
    },
  },
});

export const { updateLoader, updateGlobalLoading } = LoaderSlice.actions;

// export default LoaderSlice.reducer;

export const UncommonLoaderSlice = createSlice({
  name: "UncommonLoader",
  initialState,
  reducers: {
    uncommonUpdateLoader: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { uncommonUpdateLoader } = UncommonLoaderSlice.actions;

  export default {
      LoaderSlice,
      UncommonLoaderSlice
  }