import { memo, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

import {
  getInternalExternalObj,
  isDefaultText,
} from "../../_utilities/internalexternalhelper";

import "./InternalExternalTableStyle.css";

const InternalExternalTable = (props) => {
  const { customValues, isEdit = false } = props;
  const [ieObjectList, setIEObjList] = useState([]);

  useEffect(() => {
    const internalExternalObj = getInternalExternalObj(customValues);
    setIEObjList(internalExternalObj);
  }, [customValues]);

  const onChangeInternal = (value, index) => {
    const list = [...ieObjectList];
    list[index].internal = value;
    setIEObjList(list);
  };

  const onChangeExternal = (value, index) => {
    const list = [...ieObjectList];
    list[index].external = value;
    setIEObjList(list);
  };

  const onkeyhandlerhandler = (e) => {
    props.updateCustomValues(ieObjectList);
  };

  const addMore = () => {
    const list = [...ieObjectList];
    list.push({ internal: "", external: "" });
    setIEObjList(list);
    props.updateCustomValues(list);
  };

  const deleteIEObj = (index) => {
    const list = [...ieObjectList];
    list.splice(index, 1);
    setIEObjList(list);
    props.updateCustomValues(list);
  };

  const internalComp = (internalObj, index) => {
    if (isEdit && !isDefaultText(internalObj.internal)) {
      return (
        <TextField
          id="internal-input"
          error={internalObj?.internal?.trim().length === 0}
          value={internalObj.internal}
          onChange={(e) => onChangeInternal(e.target.value, index)}
          onBlur={(e) => onkeyhandlerhandler(e)}
        />
      );
    }
    return internalObj.internal;
  };

  const externalComp = (externalObj, index) => {
    if (isEdit) {
      return (
        <TextField
          id="external-input"
          error={externalObj?.external?.trim().length === 0}
          value={externalObj.external}
          onChange={(e) => onChangeExternal(e.target.value, index)}
          onBlur={(e) => onkeyhandlerhandler(e)}
        />
      );
    }
    return externalObj.external;
  };

  return (
    <div className="internal-external-wrapper">
      <div className="internal-external-wrapper_headers">
        <div className="internal-external-wrapper_headers_internal">
          Internal
        </div>
        <div className="internal-external-wrapper_headers_relation"></div>
        <div className="internal-external-wrapper_headers_external">
          External
        </div>
        {isEdit && (
          <div className="internal-external-wrapper_headers_actions"></div>
        )}
      </div>
      {ieObjectList.map((ieObj, index) => (
        <div
          className="internal-external-wrapper_body"
          key={`internal-external-${index}`}
        >
          <div className="internal-external-wrapper_body_internal">
            {internalComp(ieObj, index)}
          </div>
          <div className="internal-external-wrapper_body_relation">
            <img
              src={process.env.PUBLIC_URL + "/assests/img/link.svg"}
              alt="link"
              className="internal-external-wrapper_body_relation_svg"
            />
          </div>
          <div className="internal-external-wrapper_body_external">
            {externalComp(ieObj, index)}
          </div>
          {isEdit && (
            <div className="internal-external-wrapper_body_actions">
              {!isDefaultText(ieObj.internal) && (
                <DeleteIcon onClick={() => deleteIEObj(index)} />
              )}
            </div>
          )}
        </div>
      ))}
      {isEdit && (
        <div className="internal-external-wrapper_add_more">
          <Button variant="text" onClick={() => addMore()}>
            + Add more
          </Button>
        </div>
      )}
    </div>
  );
};

export default memo(InternalExternalTable);
